import React, { useEffect, useContext, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { postResourceToMine, fetchBestUnlearned } from '@linko/shared_utils';
import { HomePageContext } from '../../Context/HomePageContext';
import SlimResourceCard from '../../Components/ResourceCard/SlimResourceCard';
import { useToast } from '../../Components/ToastNotification';
import { SideBarContext } from '../../Context/SideBarContext';

const RecentlyAdded = (
    {
        subjectId,
        userRecentResources,
        userCommonResources,
        userName
    }
) => {

    const { safeNavigate } = useContext(NavigationPromptContext);
    const location = useLocation();
    const { setPersistentMessage } = useToast();
    const { addResourceToLibrary } = useContext(HomePageContext);
    const { inProgressResources, finishedResources, topUnlearnedResources } = useContext(SideBarContext);
    const [ topSubjectResources, setTopSubjectResources ] = useState([]);
    const isSubjectPage = location.pathname.includes('/s/');
    const fetchControllerRef = useRef(null);

    useEffect(() => {
        let isMounted = true;
        
        // Cancel any previous fetch request
        if (fetchControllerRef.current) {
            fetchControllerRef.current.abort();
        }
        
        // Only create a new fetch request if we need subject-specific resources
        if (isSubjectPage) {
            fetchControllerRef.current = new AbortController();
            const signal = fetchControllerRef.current.signal;

            const fetchSubjectResources = async () => {
                try {
                    const response = await fetchBestUnlearned(subjectId ? [subjectId] : [], signal);
                    if (isMounted && !signal.aborted) {
                        if (response.data.detail === "No unlearned resources found for the given subject.") {
                            setTopSubjectResources([]);
                        } else {
                            setTopSubjectResources(response.data.slice(0, 3));
                        }
                    }
                } catch (error) {
                    if (!signal.aborted) {
                        console.error('Error fetching subject resources:', error);
                    }
                }
            };

            fetchSubjectResources();
        }

        return () => {
            isMounted = false;
            if (fetchControllerRef.current) {
                fetchControllerRef.current.abort();
            }
        };
    }, [location.pathname, subjectId, isSubjectPage]);

    const handleAddToLibrary = async (resourceId) => {
        const response = await postResourceToMine(resourceId);
        if (response.status === 201) {
            setPersistentMessage('Resource added to your library!');
            addResourceToLibrary(response.data.resource);
            safeNavigate(`/r/${response.data.resource.resource.id}`);
        } else {
            window.alert('Failed to add resource to your library, please try again.');
        }
    }

    const renderResourceSection = (resources, title) => (
        resources?.length > 0 && (
            <>
                <div className={`subject-rel-title ${title === 'Completed' ? 'mt-20' : ''}`}>{title}</div>
                <div className='recently-added-resources mt-10'>
                    {resources.length > 0 && resources.map((resource) => {
                        return (
                            <SlimResourceCard   
                                key={resource.id}
                                id={resource.resource ? resource.resource.id : resource.id}
                                resource={resource.resource ? resource.resource : resource}
                                learnHistory={resource.resource ? resource : null}
                                onAddToLibrary={title === 'Trending' ? () => handleAddToLibrary(resource.resource ? resource.resource.id : resource.id, resource) : null}
                            />
                        );
                    })}
                </div>
            </>
        )
    );

    return (
        <div className='recently-added'>
            {/* Home page sections */}
            {!isSubjectPage && !userRecentResources && (
                <>
                    {renderResourceSection(inProgressResources, 'In progress')}
                    {renderResourceSection(finishedResources, 'Recent')}
                    {renderResourceSection(topUnlearnedResources, 'Trending')}
                </>
            )}

            {/* Subject page sections */}
            {isSubjectPage && (
                <>
                    {renderResourceSection(topSubjectResources, 'Trending')}
                </>
            )}

            {/* Profile page sections */}
            {userRecentResources && (
                <>
                    {renderResourceSection(userRecentResources, `On ${userName}'s shelf`)}
                    {renderResourceSection(userCommonResources, 'We both read')}
                </>
            )}
        </div>
    );
};

export default RecentlyAdded;
import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import LinkResourceModal from './LinkResourceModal';

const LinkedResource = ({noteResource, setNoteResource, noteId, updateSingleNote}) => {

    const [hasLinkedResource, setHasLinkedResource] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const location = useLocation();
    const resourcePage = location.pathname.startsWith('/r/');

    useEffect(() => {
        if (noteResource && noteResource.id) {
            setHasLinkedResource(true);
        }
    }, [noteResource]);

    const closeModal = () => setIsModalOpen(false);

    return (
        <div className='linked-resource'>
            <button className='link-resource-button linko-button linko-button--tertiary' onClick={() => !resourcePage ? setIsModalOpen(true) : null}>
                {hasLinkedResource ? 
                    <span className='note-edit-resource-title'>{noteResource?.title}</span>
                : 
                    <span>Link resource</span>
                }
            </button>
            {isModalOpen && (
                <LinkResourceModal 
                    onClose={closeModal} 
                    setNoteResource={setNoteResource} 
                    noteId={noteId}
                    updateSingleNote={updateSingleNote}
                />
            )}
        </div>
    );
};

export default LinkedResource;
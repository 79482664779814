import { ArrowRight, CheckCircle2, Star, Search, Brain } from "lucide-react"
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import "./V0LandingPage.scss"
import Header from "./components/Header/Header"
import Footer from "./components/Footer/Footer"
import Button from "./components/Button/Button"
import Accordion from "./components/Accordion/Accordion"
import { FaPencilAlt, FaTags, FaUsers, FaLightbulb, FaBook, FaPodcast, FaVideo, FaNewspaper } from "react-icons/fa"
import SteveJobs from '../../static/images/steve-jobs-demo.png';
import PsychologyPage from '../../static/images/psychology_page.png';
import EmptyNote from '../../static/images/empty_note.png';
import SmartRevisit from '../../static/images/smart-revisit-demo.png';
import FollowingTab from '../../static/images/following_tab.png';
import SampleNote from '../../static/images/sample_note.png';
import BookResourcePage from '../../static/images/resource_page_bo_01.png';
import PodcastResourcePage from '../../static/images/resource_page_po_01.png';
import VideoResourcePage from '../../static/images/resource_page_vi_01.png';
import ArticleResourcePage from '../../static/images/resource_page_ar_02.png';
import SJPortrait from '../../static/portraits/sj_portrait.svg';
import MCPortrait from '../../static/portraits/mc_portrait.svg';
import APPortrait from '../../static/portraits/ap_portrait.svg';
import JLPortrait from '../../static/portraits/sj_portrait.svg';
import RKPortrait from '../../static/portraits/mc_portrait.svg';
import DLPortrait from '../../static/portraits/ap_portrait.svg';
import { WaitlistModal } from "../../Pages/AccountSettingPage/InputModals"

function V0LandingPage() {
  const navigate = useNavigate()
  const [isWaitlistModalOpen, setIsWaitlistModalOpen] = useState(false);
  const [selectedResourceType, setSelectedResourceType] = useState('book'); // Default resource type

  const texts = ['Lifelong Learners', 'Active Thinkers', 'Deep Explorers', 'Knowledge Connectors', 'Curious Minds'];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [displayText, setDisplayText] = useState(texts[0]);

  useEffect(() => {
    const interval = setInterval(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 4000);

    return () => clearInterval(interval);
}, []);

useEffect(() => {
  setDisplayText(texts[currentTextIndex]);
}, [currentTextIndex]);

  return (
    <div className="V0_LandingPage">
      <Header />
      <main>
        {/* Hero Section */}
        <section className="hero">
          <div className="grid-pattern"></div>
          <div className="container">
            <div className="hero__content">
              <div className="hero__text">
                <div className="badge">Introducing Linko Beta</div>
                <h1>
                  For <span className="animated-text">{displayText}</span>
                </h1>
                <p>
                  Your AI-Powered Knowledge Ecosystem for Effortless Organization and Discovery
                </p>
                <div className="hero__buttons">
                  <Button primary onClick={() => setIsWaitlistModalOpen(true)}>
                    <span>Join waitlist</span>
                    {/* <ArrowRight className="icon" /> */}
                  </Button>
                  <Button outline onClick={() => navigate('/register')}>Sign up with invitation code</Button>
                </div>
              </div>
              <div className="hero__image-container">
                <div className="hero__image-wrapper">
                  <div className="hero__image-glow main-glow"></div>
                  <img src={SteveJobs} alt="Linko Dashboard" className="main-image" />
                  <div className="hero__image-glow sample-glow"></div>
                  <img src={SampleNote} alt="Sample Note" className="sample-note-image" />
                </div>
                <div className="hero__image-blob"></div>
              </div>
            </div>
          </div>
        </section>

        {/* Short Quotes Section */}
        <section className="short-quotes">
          <div className="container">
            <div className="short-quotes__content">
              <p className="short-quote" style={{color: 'var(--primary-color)', fontWeight: 'bold'}}>"Finally, a zettelkasten that builds itself"</p>
              <p className="short-quote" style={{color: 'var(--tertiary-font-color)'}}>"AI organizes everything—no more manual tagging!"</p>
              <p className="short-quote" style={{color: 'var(--tertiary-font-color)'}}>"All my books, podcasts and videos in one ecosystem"</p>
              <p className="short-quote" style={{color: 'var(--primary-color)', fontWeight: 'bold'}}>"Discovering what friends are learning changed how I read"</p>
              <p className="short-quote" style={{color: 'var(--primary-color)', fontWeight: 'bold'}}>"My learning resurfaces right when I need it"</p>
              <p className="short-quote" style={{color: 'var(--tertiary-font-color)'}}>"The knowledge connections are genuinely insightful"</p>
              <p className="short-quote" style={{color: 'var(--tertiary-font-color)'}}>"From scattered notes to an intelligent knowledge network"</p>
              <p className="short-quote" style={{color: 'var(--primary-color)', fontWeight: 'bold'}}>"The smartest knowledge system with the simplest interface"</p>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section id="features" className="features">
          <div className="container">
            <div className="section-header">
              <div className="badge">Features</div>
              <h2>Organizing Knowledge has Never been Easier</h2>
              <p>
                Capture, connect, and retrieve your ideas and resources effortlessly
              </p>
            </div>

            <div className="features__grid">

              {/* Feature 1 */}
              <div className="feature">
                <div className="feature__header">
                  <div className="feature__icon">
                    <FaTags />
                  </div>
                  <h3>Hands-Free Labeling with a Tree Structure</h3>
                </div>
                <p>
                  Like a personal librarian, Linko automatically organizes your ideas and resources into an intuitive knowledge tree 
                  that grows with the community. Discover essential books and curated recommendations for each subject, making it easy to 
                  expand your knowledge in any direction.
                </p>
                <div className="feature__image">
                  <div className="feature__image-overlay"></div>
                  <img src={PsychologyPage} alt="Hierarchical Labeling Feature" />
                </div>
              </div>

              {/* Feature 2 */}
              <div className="feature">
                <div className="feature__header">
                  <div className="feature__icon">
                    <FaTags />
                  </div>
                  <h3>All Types of Resources</h3>
                </div>
                <p>
                  Capture and organize books, podcasts, videos, articles, online courses, and more. Linko automatically recognizes and organizes them, 
                  with distraction-free reading mode and tailored recommendations to dive deeper into your interests.
                </p>
                <div className="feature__resource-switcher">
                  <div 
                    className={`resource-icon ${selectedResourceType === 'book' ? 'active' : ''}`}
                    onClick={() => setSelectedResourceType('book')}
                  >
                    <FaBook />
                    <span>Books</span>
                  </div>
                  <div 
                    className={`resource-icon ${selectedResourceType === 'article' ? 'active' : ''}`}
                    onClick={() => setSelectedResourceType('article')}
                  >
                    <FaNewspaper />
                    <span>Articles</span>
                  </div>
                  <div 
                    className={`resource-icon ${selectedResourceType === 'video' ? 'active' : ''}`}
                    onClick={() => setSelectedResourceType('video')}
                  >
                    <FaVideo />
                    <span>Videos</span>
                  </div>
                  <div 
                    className={`resource-icon ${selectedResourceType === 'podcast' ? 'active' : ''}`}
                    onClick={() => setSelectedResourceType('podcast')}
                  >
                    <FaPodcast />
                    <span>Podcasts</span>
                  </div>
                </div>
                <div className="feature__image">
                  <div className="feature__image-overlay"></div>
                  {selectedResourceType === 'book' && <img src={BookResourcePage} alt="Book Resource" />}
                  {selectedResourceType === 'podcast' && <img src={PodcastResourcePage} alt="Podcast Resource" />}
                  {selectedResourceType === 'video' && <img src={VideoResourcePage} alt="Video Resource" />}
                  {selectedResourceType === 'article' && <img src={ArticleResourcePage} alt="Article Resource" />}
                </div>
              </div>

              {/* Feature 3 */}
              <div className="feature">
                <div className="feature__header">
                  <div className="feature__icon">
                    <FaPencilAlt />
                  </div>
                  <h3>Simple Note-Making</h3>
                </div>
                <p>
                  Capture ideas effortlessly with our clean, intuitive interface, and connect your thoughts to resources seamlessly.
                  Focus on what truly matters—your thoughts and insights—with full support for Markdown formatting and image embedding.
                </p>
                <div className="feature__image small">
                  <div className="feature__image-overlay"></div>
                  <img src={EmptyNote} alt="Simple Note-Making Feature" />
                </div>
              </div>

              {/* Feature 4 */}
              <div className="feature">
                <div className="feature__header">
                  <div className="feature__icon">
                    <FaLightbulb />
                  </div>
                  <h3>Smart Revisit and Connect</h3>
                  
                </div>
                <p>
                  Linko intelligently resurfaces your past insights through thoughtful prompts and reveals 
                  hidden connections across your knowledge network, making your learning journey more cohesive and meaningful.
                </p>
                <div className="feature__image small">
                  <div className="feature__image-overlay"></div>
                  <img src={SmartRevisit} alt="Community Learning Feature" />
                </div>
              </div>

              {/* Feature 5 */}
              <div className="feature">
                <div className="feature__header">
                  <div className="feature__icon">
                    <FaUsers />
                  </div>
                  <h3>Learn With Friends</h3>
                </div>
                <p>
                  Connect, get inspired, and grow together! Join a vibrant community of curious minds who share
                  your passion for learning.
                </p>
                <div className="feature__image">
                  <div className="feature__image-overlay"></div>
                  <img src={FollowingTab} alt="Smart Revisit Feature" />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* What's More Section */}
        <section className="whats-more">
          <div className="container">
            <div className="section-header">
              <div className="badge">What's More</div>
              <h2>Powerful Tools for Your Knowledge Workflow</h2>
              <p>
                Beyond the core features, Linko offers a suite of specialized tools to enhance your knowledge
                management experience.
              </p>
            </div>

            <div className="whats-more__grid">
              {/* Sub-feature 1 - Markdown Support */}
              <div className="sub-feature">
                <div className="sub-feature__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon"
                  >
                    <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path>
                    <polyline points="14 2 14 8 20 8"></polyline>
                  </svg>
                </div>
                <div className="sub-feature__content">
                  <h3>Markdown Support</h3>
                  <p>Format your notes with simple, powerful Markdown syntax for beautiful, structured content.</p>
                </div>
              </div>

              {/* Sub-feature 2 - Multiple Resource Types */}
              <div className="sub-feature">
                <div className="sub-feature__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon"
                  >
                    <path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20"></path>
                  </svg>
                </div>
                <div className="sub-feature__content">
                  <h3>Multiple Resource Types</h3>
                  <p>Automatically recognizes and organizes books, podcasts, videos, articles, online courses, and more.</p>
                </div>
              </div>

              {/* Sub-feature 3 - Cross-Platform Access */}
              <div className="sub-feature">
                <div className="sub-feature__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon"
                  >
                    <rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect>
                    <path d="M12 18h.01"></path>
                  </svg>
                </div>
                <div className="sub-feature__content">
                  <h3>Mobile & Web</h3>
                  <p>Access your knowledge base anytime with our iOS and Android apps, and save web content with our Chrome extension.</p>
                </div>
              </div>

              {/* Sub-feature 4 - Customized Recommendations */}
              <div className="sub-feature">
                <div className="sub-feature__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <path d="m16 12-4-4-4 4"></path>
                    <path d="M12 16V8"></path>
                  </svg>
                </div>
                <div className="sub-feature__content">
                  <h3>Customized Recommendations</h3>
                  <p>Discover new learning resources tailored to your interests and knowledge gaps.</p>
                </div>
              </div>

              {/* Sub-feature 5 - Smart AI Search */}
              <div className="sub-feature">
                <div className="sub-feature__icon">
                  <Search className="icon" />
                </div>
                <div className="sub-feature__content">
                  <h3>Smart AI Search</h3>
                  <p>Find exactly what you need with our intelligent search that understands context and meaning, not just keywords.</p>
                </div>
              </div>

              {/* Sub-feature 6 - AI Summaries */}
              <div className="sub-feature">
                <div className="sub-feature__icon">
                  <Brain className="icon" />
                </div>
                <div className="sub-feature__content">
                  <h3>AI Summaries</h3>
                  <p>Generate concise summaries of your notes and subjects to quickly refresh your memory or extract key insights.</p>
                </div>
              </div>

              {/* Sub-feature 7 - Encrypted Private Notes */}
              <div className="sub-feature">
                <div className="sub-feature__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon"
                  >
                    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                  </svg>
                </div>
                <div className="sub-feature__content">
                  <h3>Encrypted Private Notes</h3>
                  <p>Keep your ideas secure with full control over privacy settings and AES-128 encryption for sensitive content.</p>
                </div>
              </div>

              {/* Sub-feature 8 - Custom Tags */}
              <div className="sub-feature">
                <div className="sub-feature__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon"
                  >
                    <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
                    <line x1="7" y1="7" x2="7.01" y2="7"></line>
                  </svg>
                </div>
                <div className="sub-feature__content">
                  <h3>Custom Tags</h3>
                  <p>Create your own tagging system to organize and filter your knowledge in ways that make sense to your unique thinking.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Testimonials Section */}
        <section id="testimonials" className="testimonials">
          <div className="grid-pattern"></div>
          <div className="container">
            <div className="section-header">
              <div className="badge">Testimonials</div>
              <h2>Loved by Knowledge Enthusiasts Worldwide</h2>
              <p>See what our diverse community says about Linko's impact on their learning journey.</p>
            </div>

            <div className="testimonials__grid">
              {/* Testimonial 1 */}
              <div className="testimonial">
                <div className="testimonial__glow"></div>
                <div className="testimonial__stars">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="star filled" />
                  ))}
                </div>
                <blockquote>
                  <span className="quote-mark">"</span>
                  <p>
                    Before Linko, my research notes were a chaotic mess of half-forgotten ideas and buried links. The AI-powered hierarchical labeling is a game-changer.
                  </p>
                </blockquote>
                <div className="testimonial__author">
                  <div className="testimonial__avatar">
                    <img src={SJPortrait} alt="S.J." />
                  </div>
                  <div className="testimonial__info">
                    <p className="testimonial__name">S.J.</p>
                    <p className="testimonial__title">Academic Researcher</p>
                  </div>
                </div>
              </div>

              {/* Testimonial 2 */}
              <div className="testimonial">
                <div className="testimonial__glow"></div>
                <div className="testimonial__stars">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="star filled" />
                  ))}
                </div>
                <blockquote>
                  <span className="quote-mark">"</span>
                  <p>
                    The Smart Revisit feature is brilliant. Feels like having a personal learning coach that helps me review my best ideas at the perfect time.
                  </p>
                </blockquote>
                <div className="testimonial__author">
                  <div className="testimonial__avatar">
                    <img src={MCPortrait} alt="M.C." />
                  </div>
                  <div className="testimonial__info">
                    <p className="testimonial__name">M.C.</p>
                    <p className="testimonial__title">Creative Director</p>
                  </div>
                </div>
              </div>

              {/* Testimonial 3 */}
              <div className="testimonial">
                <div className="testimonial__glow"></div>
                <div className="testimonial__stars">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="star filled" />
                  ))}
                </div>
                <blockquote>
                  <span className="quote-mark">"</span>
                  <p>
                    Being able to connect with like-minded learners is amazing. The community aspect of Linko transformed how I discover new ideas.
                  </p>
                </blockquote>
                <div className="testimonial__author">
                  <div className="testimonial__avatar">
                    <img src={APPortrait} alt="A.P." />
                  </div>
                  <div className="testimonial__info">
                    <p className="testimonial__name">A.P.</p>
                    <p className="testimonial__title">Entrepreneur</p>
                  </div>
                </div>
              </div>
              
              {/* Testimonial 4 */}
              <div className="testimonial">
                <div className="testimonial__glow"></div>
                <div className="testimonial__stars">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="star filled" />
                  ))}
                </div>
                <blockquote>
                  <span className="quote-mark">"</span>
                  <p>
                    As a medical student, I'm drowning in information. Linko's ability to automatically organize resources across different subjects saved me countless hours.
                  </p>
                </blockquote>
                <div className="testimonial__author">
                  <div className="testimonial__avatar">
                    <img src={JLPortrait} alt="J.L." />
                  </div>
                  <div className="testimonial__info">
                    <p className="testimonial__name">J.L.</p>
                    <p className="testimonial__title">Medical Student</p>
                  </div>
                </div>
              </div>
              
              {/* Testimonial 5 */}
              <div className="testimonial">
                <div className="testimonial__glow"></div>
                <div className="testimonial__stars">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="star filled" />
                  ))}
                </div>
                <blockquote>
                  <span className="quote-mark">"</span>
                  <p>
                    The connections Linko finds between seemingly unrelated notes are mind-blowing. I've had several breakthrough moments thanks to these unexpected insights.
                  </p>
                </blockquote>
                <div className="testimonial__author">
                  <div className="testimonial__avatar">
                    <img src={RKPortrait} alt="R.K." />
                  </div>
                  <div className="testimonial__info">
                    <p className="testimonial__name">R.K.</p>
                    <p className="testimonial__title">Author & Researcher</p>
                  </div>
                </div>
              </div>
              
              {/* Testimonial 6 */}
              <div className="testimonial">
                <div className="testimonial__glow"></div>
                <div className="testimonial__stars">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="star filled" />
                  ))}
                </div>
                <blockquote>
                  <span className="quote-mark">"</span>
                  <p>
                    I love how Linko handles all my different resource types. Books, podcasts, articles—all in one place with seamless connections between them.
                  </p>
                </blockquote>
                <div className="testimonial__author">
                  <div className="testimonial__avatar">
                    <img src={DLPortrait} alt="D.L." />
                  </div>
                  <div className="testimonial__info">
                    <p className="testimonial__name">D.L.</p>
                    <p className="testimonial__title">Content Creator</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <section id="pricing" className="pricing">
          <div className="container">
            <div className="section-header">
              <div className="badge">Pricing</div>
              <h2>Simple, Transparent Pricing</h2>
              <p>Start organizing your knowledge today with our free plan.</p>
            </div>

            <div className="pricing__card-container">
              {/* Free Plan */}
              <div className="pricing__card">
                <div className="pricing__card-glow"></div>
                <div className="pricing__card-content">
                  <div className="pricing__card-header">
                    <div>
                      <h3>Free</h3>
                      <p>Everything you need to get started</p>
                    </div>
                    <div className="pricing__badge">For Beta</div>
                  </div>

                  <div className="pricing__price">
                    <span className="pricing__amount">$0</span>
                    <span className="pricing__period">/beta</span>
                  </div>

                  <div className="pricing__features">
                    {[
                      "AI search functionality",
                      "AI summaries",
                      "Unlimited notes and resources",
                      "Unlimited custom tags",
                      "Unlimited smart revisits",
                      "Full access to community",
                    ].map((feature, i) => (
                      <div key={i} className="pricing__feature">
                        <CheckCircle2 className="pricing__check" />
                        <span>{feature}</span>
                      </div>
                    ))}
                  </div>

                  <Button primary fullWidth onClick={() => setIsWaitlistModalOpen(true)}>
                    Join waitlist
                  </Button>

                </div>
              </div>

              {/* Premium Plan - Coming Soon */}
              {/* <div className="pricing__card premium">
                <div className="pricing__card-glow"></div>
                <div className="pricing__card-content premium">
                  <div className="pricing__card-header">
                    <div>
                      <h3>Premium</h3>
                      <p>Advanced features for power users</p>
                    </div>
                    <div className="pricing__badge">Coming Soon</div>
                  </div> */}

                  {/* <div className="pricing__price">
                    <span className="pricing__amount">?</span>
                    <span className="pricing__period">/month</span>
                  </div> */}

                  {/* <div className="pricing__features">
                    {[
                      "AI search functionality",
                      "AI summaries",
                      "Unlimited notes and resources",
                      "Unlimited custom tags",
                      "Unlimited smart revisits",
                      "Full access to community",
                    ].map((feature, i) => (
                      <div key={i} className="pricing__feature">
                        <CheckCircle2 className="pricing__check" />
                        <span>{feature}</span>
                      </div>
                    ))}
                  </div> */}

                  {/* <Button disabled fullWidth>
                    Coming Soon
                  </Button> */}
                {/* </div> */}
              {/* </div> */}
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section id="faq" className="faq">
          <div className="grid-pattern"></div>
          <div className="container">
            <div className="section-header">
              <div className="badge">FAQ</div>
              <h2>Frequently Asked Questions</h2>
              <p>Everything you need to know about Linko.</p>
            </div>

            <div className="faq__container">
              <Accordion
                items={[
                  {
                    question: "How does Linko differ from other note-taking or PKM tools?",
                    answer:
                      "Unlike traditional apps that require manual organization, Linko uses AI to automatically structure your knowledge. Our system creates meaningful connections between notes and resources, building an intelligent knowledge network that grows with you. The Smart Revisit feature ensures you retain knowledge long-term, and our community aspects transform solitary learning into a collaborative journey. Plus, it's really simple to use."
                  },
                  {
                    question: "How does Linko's automatic organization work?",
                    answer:
                      "Linko's AI, powered by GPT-4o, analyzes the content of your notes and resources to identify subjects, concepts, and relationships between ideas. It then automatically organizes them into an intuitive hierarchical structure—like having a personal librarian who understands the meaning behind your content, not just keywords. This eliminates the need for manual tagging and folder management that becomes overwhelming in traditional systems."
                  },
                  {
                    question: "Can I use Linko with AI models?",
                    answer:
                      "Yes! Linko offers a Linko MCP (Modle Context Protocol) https://pypi.org/project/linko-mcp/0.2.0/#description that can connect with leading AI models, allowing you to leverage the best LLMs for your knowledge management needs. You can even setup a Linko account exclusively for your AI assistant."
                  },
                  {
                    question: "Is my data private and secure?",
                    answer:
                      "Absolutely! Your data is encrypted and securely stored with AES-128 encryption for sensitive content. You have complete control over what you share with the community and what remains private. Private notes remain completely confidential, while our permission system gives you granular control over shared content. See our Privacy Policy for more details."
                  },
                  {
                    question: "How does the community learning feature work?",
                    answer:
                      "Follow your friends and other learners with similar interests to discover what they're reading and thinking about. Unlike social media, Linko focuses exclusively on knowledge exchange—no ads, no distractions, just meaningful intellectual connections with like-minded individuals who share your passion for learning. If you do not want to share your learning with the community, you can opt to keep them private."
                  },
                  {
                    question: "How does the Smart Revisit feature improve learning retention?",
                    answer:
                      "Our AI-powered system analyzes your notes and intelligently resurfaces important concepts at optimal intervals based on scientifically-proven spaced repetition principles. It identifies connections between new information and your existing knowledge, prompting you to revisit ideas right when they're at risk of being forgotten. This transforms passive note-taking into an active learning system that significantly improves long-term retention."
                  },
                  {
                    question: "What types of resources does Linko support?",
                    answer:
                      "Linko supports books, articles, podcasts, videos, online courses, and links. Each resource type has a tailored interface for optimal viewing and note-taking. Our system automatically recognizes resource types from links and provides rich metadata and previews, creating a comprehensive library of your learning materials all in one place."
                  },
                  {
                    question: "What platforms does Linko support?",
                    answer:
                      "Linko is available on web, iOS, Android, and as a Chrome extension. Your notes sync seamlessly across all your devices, so you can capture ideas wherever you are. Our mobile apps are optimized for quick capture on the go, while the web app provides a comprehensive environment for deep work and knowledge exploration."
                  },
                ]}
              />
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="cta">
          <div className="cta__gradient"></div>
          <div className="container">
            <div className="cta__content">
              <h2>Transform Your Learning Journey Today</h2>
              <p>Join thousands of knowledge enthusiasts who've upgraded from scattered notes to an intelligent knowledge ecosystem.</p>
              <div className="cta__buttons">
                <Button primary onClick={() => setIsWaitlistModalOpen(true)}>Join Waitlist</Button>
              </div>
              <p className="cta__footnote" style={{marginTop: "0.5rem", opacity: 0.8, fontSize: "0.8rem"}}>Limited beta access available—secure your spot now</p>
            </div>
          </div>
          <div className="cta__fade"></div>
        </section>
      </main>
      <Footer />
      <WaitlistModal isOpen={isWaitlistModalOpen} onClose={() => setIsWaitlistModalOpen(false)} />
    </div>
  )
}

export default V0LandingPage


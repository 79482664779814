import React, { useState, useEffect, useContext } from 'react';
import { fetchEssentialBooks, postResourceToMine } from '@linko/shared_utils';
import { TbBook } from "react-icons/tb";
import SlimResourceCard from '../../Components/ResourceCard/SlimResourceCard';
import { useToast } from '../../Components/ToastNotification';
import { HomePageContext } from '../../Context/HomePageContext';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';

const EssentialBooks = ({ subjectId }) => {
    
    const [essentialBooks, setEssentialBooks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { setPersistentMessage } = useToast();
    const { addResourceToLibrary } = useContext(HomePageContext);
    const { safeNavigate } = useContext(NavigationPromptContext);

    useEffect(() => {
        
        const getEssentialBooks = async () => {
            if (!subjectId) {
                return;
            }
            
            try {
                setLoading(true);
                setError(null);
                const data = await fetchEssentialBooks(subjectId);
                
                if (data && data.length > 0) {
                    setEssentialBooks(data);
                } else {
                    setEssentialBooks([]);
                }
            } catch (err) {
                console.error('Error fetching essential books:', err);
                setError('Failed to load essential books');
                setEssentialBooks([]);
            } finally {
                setLoading(false);
            }
        };

        getEssentialBooks();
    }, [subjectId]);

    const handleAddToLibrary = async (resourceId) => {
        const response = await postResourceToMine(resourceId);
        if (response.status === 201) {
            setPersistentMessage('Resource added to your library!');
            addResourceToLibrary(response.data.resource);
            safeNavigate(`/r/${response.data.resource.resource.id}`);
        } else {
            window.alert('Failed to add resource to your library, please try again.');
        }
    };

    // Don't render anything while loading
    if (loading) {
        return null;
    }

    // Don't render anything if there's an error or no books
    if (error || essentialBooks.length === 0) {
        return null;
    }

    return (
        <div className="subject-rel">
            <div className='subject-rel-title'>
                {/* <TbBook style={{ marginRight: '8px' }} /> */}
                Essential books
            </div>
            <div className="recently-added-resources">
                {essentialBooks.map((book) => (
                    <SlimResourceCard
                        key={book.id}
                        id={book.id}
                        resource={book}
                        learnHistory={null}
                        onAddToLibrary={() => handleAddToLibrary(book.id)}
                        showAddButton={true}
                    />
                ))}
            </div>
        </div>
    );
};

export default EssentialBooks; 
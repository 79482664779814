import React, { useState, useContext } from 'react';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { UserContext } from '../../Context/AppContext';
import { GoPeople } from "react-icons/go";
import { IoClose } from 'react-icons/io5';
import CustomAvatar from '../CustomAvatar';
import { sendFollowRequest } from '@linko/shared_utils';
import { useToast } from "../ToastNotification";

const LearnedUsers = ({ learnedUserData }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { safeNavigate } = useContext(NavigationPromptContext);
    const { userInfo } = useContext(UserContext);

    const friends = learnedUserData?.following_user_learned || [];
    const publicUsers = learnedUserData?.public_user_learned || [];
    const privateUsers = learnedUserData?.private_user_learned_count || 0;
    const totalCount = friends.length + publicUsers.length + privateUsers;

    const goToProfile = (id) => {
        safeNavigate(`/profile/${id}`);
    };

    if (totalCount === 0) {
        return null;
    }

    return (
        <>
            <button 
                className='learned-users-button'
                onClick={(e) => {
                    e.stopPropagation();
                    setIsModalOpen(true);
                }}
            >
                <GoPeople color='#999999' size={16}/>
                <span style={{color:'#999999', fontSize:'12px', fontWeight:500}}>{totalCount}</span>
            </button>
            <LearnedUsersModal 
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                data={learnedUserData}
                onUserClick={goToProfile}
                isPrivateMode={userInfo?.private_account}
            />
        </>
    );
};

const LearnedUsersModal = ({ isOpen, onClose, data, onUserClick, isPrivateMode }) => {
    const { addToast } = useToast();

    if (!isOpen) return null;

    const handleOverlayClick = (e) => {
        if (e.target.className === 'modal-overlay') {
            onClose();
        }
    };

    const handleFollow = async (userId) => {
        if (isPrivateMode) {
            addToast('You cannot follow users while in private mode', 'error', 3000);
            return;
        }
        await sendFollowRequest(userId);
        addToast('Follow request sent');
    };

    const friends = data?.following_user_learned || [];
    const publicUsers = data?.public_user_learned || [];
    const privateUsers = data?.private_user_learned_count || 0;

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="info-modal-content learned-users-modal">
                <div className="modal-header">
                    <h2>Others who enjoyed this resource</h2>
                </div>
                <div className="modal-body">
                    {friends.length > 0 && (
                        <div className="user-section">
                            <div className="user-list">
                                {friends.map((user) => (
                                    <div className='user-item' key={user.id}>
                                        <CustomAvatar 
                                            size={28}
                                            firstName={user.first_name}
                                            lastName={user.last_name}
                                        />
                                        <span 
                                            key={user.id}
                                            onClick={() => onUserClick(user.id)}
                                            className="user-name"
                                        >
                                            {user.first_name} {user.last_name}
                                        </span>
                                        <button className='linko-button linko-button--tertiary linko-button--tertiary--disabled' disabled>
                                            Following
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {publicUsers.length > 0 && (
                        <div className="user-section">
                            <div className="user-list">
                                {publicUsers.map((user) => (
                                    <div className='user-item' key={user.id}>
                                        <CustomAvatar 
                                            size={28}
                                            firstName={user.first_name}
                                            lastName={user.last_name}
                                        />
                                        <span 
                                            key={user.id}
                                            onClick={() => onUserClick(user.id)}
                                            className="user-name"
                                        >
                                            {user.first_name} {user.last_name}
                                        </span>
                                        <button 
                                            className='linko-button linko-button--secondary' 
                                            onClick={() => handleFollow(user.id)}
                                            disabled={isPrivateMode}
                                            style={isPrivateMode ? { cursor: 'not-allowed' } : {}}
                                        >
                                            Follow
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {privateUsers > 0 && (
                        <div className="user-section">
                            <p style={{fontWeight: 600, color: '#999999'}}>{privateUsers} private user{privateUsers > 1 ? 's' : ''}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LearnedUsers;
import React from 'react';

const CookiePolicy = () => {
  return (
    <div className='privacy-policy'>
      <h1>Cookie Policy for Linko</h1>
      <p>
        <span>Last updated: 03/18/2025</span> 
      </p>

      <p>This Cookie Policy explains how Linko ("we", "us", or "our") uses cookies and similar technologies to recognize you when you visit our website at <a href="https://www.linko.com">https://www.linko.com</a>
      , ("Website"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.</p>

      <h2>What are cookies?</h2>
      <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.</p>
      <p>Cookies set by the website owner (in this case, Linko) are called "first party cookies". Cookies set by parties other than the website owner are called "third party cookies". Third party cookies enable third party features or functionality to be provided on or through the website (e.g., advertising, interactive content, and analytics). The parties that set these third party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.</p>

      <h2>Why do we use cookies?</h2>
      <p>We use first party and third party cookies for several reasons. Some cookies are required for technical reasons in order for our Website to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Website. Third parties serve cookies through our Website for advertising, analytics, and other purposes. This is described in more detail below.</p>

      <h2>How can I control cookies?</h2>
      <p>You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.</p>
      <p>The Cookie Consent Manager can be found in the notification banner and on our website. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted. You may also set or amend your web browser controls to accept or refuse cookies. As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser's help menu for more information.</p>

      <h2>How often will you update this Cookie Policy?</h2>
      <p>We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal, or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</p>
      <p>The date at the top of this Cookie Policy indicates when it was last updated.</p>

      <h2>Where can I get further information?</h2>
      <p>If you have any questions about our use of cookies or other technologies, please email us at <a href='mailto:linko.assistant@gmail.com'>linko.assistant@gmail.com</a>.</p>
    </div>
  );
};

export default CookiePolicy;

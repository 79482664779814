import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className='privacy-policy'>
            <h1>Privacy Policy for Linko</h1>
            <p>
                <span>Last updated: 03/18/2025</span> 
            </p>
            
            <div className="policy-table-of-contents">
                <h2>Table of Contents</h2>
                <ul>
                    <li><a href="#introduction">Introduction</a></li>
                    <li><a href="#information-we-collect">Information We Collect</a></li>
                    <li><a href="#encryption">Data Encryption and Security</a></li>
                    <li><a href="#analytics">Analytics and User Tracking</a></li>
                    <li><a href="#how-we-use">How We Use Your Information</a></li>
                    <li><a href="#sharing">Sharing Your Information</a></li>
                    <li><a href="#retention">Retention of Your Information</a></li>
                    <li><a href="#security">Security of Your Information</a></li>
                    <li><a href="#rights">Your Privacy Rights</a></li>
                    <li><a href="#changes">Changes to This Privacy Policy</a></li>
                    <li><a href="#contact">Contact Us</a></li>
                </ul>
            </div>
            
            <div id="introduction">
                <p>
                    Welcome to Linko! We are committed to protecting your personal information and your right to privacy. 
                    If you have any questions or concerns about this privacy policy or our practices with regard to your personal information, 
                    please contact us at <a href='mailto:linko.assistant@gmail.com'>linko.assistant@gmail.com</a>.
                </p>
                <p>
                    This privacy policy describes how we might use, manage, 
                    and protect your information when you use our website and services (collectively, "Services").  
                </p>
            </div>
            
            <div id="information-we-collect">
                <h2>Information We Collect</h2>
                <p>
                    We collect personal information that you voluntarily provide to us when registering for the Services, 
                    expressing an interest in obtaining information about us or our products and services, 
                    or otherwise contacting us. The personal information we collect depends on the context of your interactions with us and the Services, 
                    the choices you make, and the products and features you use.
                </p>
                <h3>Types of Information Collected:</h3>
                <ul>
                    <li><span>Personal Information:</span> This includes names, email addresses, usernames, and any other information that you provide to us.</li>
                    <li><span>Usage Data:</span> Information on how the Services are accessed and used, including your interaction with the Services.</li>
                    <li><span>Technical Data:</span> Includes browser type, operating system, and other technology on the devices you use to access the Services.</li>
                    <li><span>Payment Data:</span> We collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument.</li>
                </ul>
            </div>
            
            <div id="encryption">
                <h2>Data Encryption and Security</h2>
                <p>
                    We take the security of your data very seriously and employ advanced encryption technologies to protect your information.
                </p>
                
                <h3>Server-Side Encryption for Private Notes</h3>
                <p>
                    When you mark a note as private, we automatically apply encryption to protect your content:
                </p>
                <ul>
                    <li><span>Symmetric Encryption:</span> We use Fernet symmetric encryption (based on AES-128 in CBC mode with PKCS7 padding) to encrypt both note titles and content.</li>
                    <li><span>Server-Side Encryption:</span> The encryption occurs on our secure servers before being stored in our database.</li>
                    <li><span>Encrypted Storage:</span> Both the title and content of private notes are stored in encrypted form in our database, making them unreadable even if the database were compromised.</li>
                    <li><span>Secure Decryption:</span> Notes are only decrypted when you access them through your authenticated account.</li>
                </ul>
                <p>
                    <strong>Note:</strong> This is not end-to-end encryption, as the encryption and decryption happen on our servers. However, it provides strong protection for your private note data at rest.
                </p>
                
                <h3>ID Obfuscation</h3>
                <p>
                    To further protect your privacy and prevent unauthorized access to your data, we implement ID obfuscation:
                </p>
                <ul>
                    <li><span>URL Protection:</span> Database IDs are never exposed directly in URLs or API responses.</li>
                    <li><span>Cryptographic Hashing:</span> We use HMAC-SHA256 cryptographic hashing to create secure, tamper-resistant identifiers for URLs and external references.</li>
                    <li><span>ID Verification:</span> Every encoded ID includes a cryptographic signature to verify its authenticity, preventing ID manipulation attempts.</li>
                </ul>
                
                <h3>Key Management</h3>
                <p>
                    Encryption keys are securely managed:
                </p>
                <ul>
                    <li>Encryption keys are never stored in the same database as the encrypted data</li>
                    <li>Keys are managed through secure environment variables or configuration settings</li>
                    <li>Access to encryption keys is strictly limited and monitored</li>
                </ul>
                
                <p>
                    These encryption measures provide an additional layer of protection beyond our standard security practices, particularly for your most sensitive information in private notes.
                </p>
            </div>
                
            <div id="analytics">
                <h2>Analytics and User Tracking</h2>
                <p>
                    We use Amplitude, a third-party analytics service, to help us understand how users interact with our application. 
                    This analytics service enables us to improve our product, diagnose technical issues, and provide a better user experience.
                </p>
                <h3>What We Track:</h3>
                <ul>
                    <li><span>User Interactions:</span> We collect information about how you navigate and interact with our application, including page views, button clicks, and feature usage.</li>
                    <li><span>User Properties:</span> We may associate your usage data with your user profile, including non-sensitive account information such as username, note count, and resource count.</li>
                    <li><span>Session Information:</span> We use session replay technology to record user sessions, which helps us understand usability issues and improve the application.</li>
                    <li><span>Device Information:</span> We collect information about the devices you use to access our service, such as device type, operating system, and browser type.</li>
                </ul>
                
                <h3>Privacy Protections:</h3>
                <p>
                    We have implemented several measures to protect your privacy while collecting analytics data:
                </p>
                <ul>
                    <li><span>Content Masking:</span> Note content, text inputs, and other potentially sensitive information are automatically masked and never recorded in our analytics system.</li>
                    <li><span>IP Address Protection:</span> We do not collect or store your IP address as part of our analytics data.</li>
                    <li><span>Data Minimization:</span> We only collect the minimum amount of information necessary to understand how our application is used.</li>
                    <li><span>Limited Data Retention:</span> Analytics data is retained only for the period necessary for analysis purposes.</li>
                </ul>
                
                <h3>Data Elements Specifically Protected:</h3>
                <p>
                    The following elements are specifically masked or blocked from tracking:
                </p>
                <ul>
                    <li>All note content and editor areas</li>
                    <li>Text input fields and search queries</li>
                    <li>File uploads and personal documents</li>
                    <li>Password fields and sensitive form inputs</li>
                </ul>
                
                <p>
                    For registered users, you can view additional details about our analytics practices in your <a href="/settings/your-username/#data-privacy">account settings</a> under the "Data & Privacy" section.
                </p>
            </div>
            
            <div id="how-we-use">
                <h2>How We Use Your Information</h2>
                <p>
                    We use personal information collected via our Services for a variety of business purposes, including:
                </p>
                <ul>
                    <li>To provide, operate, and maintain our Services</li>
                    <li>To improve, personalize, and expand our Services</li>
                    <li>To understand and analyze how you use our Services</li>
                    <li>To develop new products, services, features, and functionality</li>
                    <li>To communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                    <li>To send you emails</li>
                    <li>To find and prevent fraud</li>
                </ul>
            </div>
            
            <div id="sharing">
                <h2>Sharing Your Information</h2>
                <p>
                    We only share information with your consent, to comply with laws, to protect your rights, or to fulfill business obligations.
                </p>
                <ul>
                    <li><span>Third-Party Service Providers:</span> We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</li>
                    <li><span>Business Partners:</span> We may share your information with our business partners to offer you certain products, services, or promotions.</li>
                    <li><span>Affiliates:</span> We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy policy.</li>
                    <li><span>Other Users:</span> When you share personal information or otherwise interact with public areas of the Services, such information may be viewed by all users and may be publicly distributed outside the Services in perpetuity.</li>
                    <li><span>Analytics Providers:</span> We share anonymized and aggregated data with Amplitude for analytics purposes. This data does not contain your note content or other sensitive information.</li>
                </ul>
                <p>
                    <strong>Important:</strong> We never share the content of your private notes with third parties. Private notes are stored in encrypted form and are only accessible to you through your authenticated account.
                </p>
            </div>
            
            <div id="retention">
                <h2>Retention of Your Information</h2>
                <p>
                    We will retain your personal information only for as long as is necessary for the purposes set out in this privacy policy, 
                    and to the extent necessary to comply with our legal obligations, resolve disputes, 
                    and enforce our legal agreements and policies.
                </p>
                <p>
                    For analytics data specifically, we retain this information for a limited time period necessary for analysis and product improvement purposes, after which it is aggregated or deleted.
                </p>
            </div>
            
            <div id="security">
                <h2>Security of Your Information</h2>
                <p>
                    We take reasonable measures to protect your information from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction. 
                    These measures include:
                </p>
                <ul>
                    <li>Encrypted data transmission using HTTPS/TLS</li>
                    <li>Regular security assessments</li>
                    <li>Access controls for our staff</li>
                    <li>Data pseudonymization and anonymization where appropriate</li>
                    <li>Automatic encryption of private notes</li>
                    <li>Secure ID obfuscation for all database references</li>
                </ul>
                <p>
                    However, no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
                </p>
            </div>
            
            <div id="rights">
                <h2>Your Privacy Rights</h2>
                <p>
                    Depending on your location, you may have rights under applicable data protection laws to request access to, 
                    correct, or delete the personal information we have about you.
                </p>
                <p>
                    These rights may include:
                </p>
                <ul>
                    <li><span>Right to Access:</span> You may have the right to request copies of your personal information.</li>
                    <li><span>Right to Rectification:</span> You may have the right to request that we correct any information you believe is inaccurate or incomplete.</li>
                    <li><span>Right to Erasure:</span> You may have the right to request that we erase your personal information, under certain conditions.</li>
                    <li><span>Right to Restrict Processing:</span> You may have the right to request that we restrict the processing of your personal information, under certain conditions.</li>
                    <li><span>Right to Data Portability:</span> You may have the right to request that we transfer the data we have collected to another organization, or directly to you, under certain conditions.</li>
                </ul>
                <p>
                    To exercise any of these rights, please contact us using the information provided in the Contact section below.
                </p>
            </div>
            
            <div id="changes">
                <h2>Changes to This Privacy Policy</h2>
                <p>
                    We reserve the right to make changes to this privacy policy at any time. 
                    If we make changes, we will post the updated policy and update the effective date at the top of this page.
                </p>
                <p>
                    We encourage you to review this privacy policy periodically to stay informed about our information practices.
                </p>
            </div>
            
            <div id="contact">
                <h2>Contact Us</h2>
                <p>
                    If you have questions or comments about this privacy policy, please contact us at: <a href='mailto:linko.assistant@gmail.com'>linko.assistant@gmail.com</a>
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
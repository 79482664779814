import { IoLibraryOutline } from "react-icons/io5";
import { GoPeople } from "react-icons/go";
import { PiNotePencilBold } from "react-icons/pi";
import { LiaShareAltSolid } from "react-icons/lia";
import { IoSparklesOutline } from "react-icons/io5";

export const setupTabs = (location, setTabNames, activeTab) => {
    const getTabContent = (icon, text, isActive) => (
        <span className="button-span">
            {text}
        </span>
    );

    if (location.pathname.startsWith('/my_linko')) {
      setTabNames({
        first: getTabContent(<IoLibraryOutline/>, 'Mine', activeTab === 'library'),
        second: getTabContent(<IoSparklesOutline/>, 'Ignite', activeTab === 'revisit'),
        third: getTabContent(<LiaShareAltSolid style={{strokeWidth:'0.75px', marginTop:'3px'}}/>, 'Following', activeTab === 'explore'),
      });
    } else if (location.pathname.startsWith('/s/')) {
      setTabNames({
        first: getTabContent(<IoLibraryOutline/>, 'Mine', activeTab === 'library'),
        second: getTabContent(<LiaShareAltSolid style={{strokeWidth:'0.75px', marginTop:'3px'}}/>, 'Following', activeTab === 'explore'),
      });
    } else if (location.pathname.startsWith('/r/')) {
      setTabNames({
        first: getTabContent(<IoLibraryOutline/>, 'Mine', activeTab === 'library'),
        second: getTabContent(<LiaShareAltSolid size={22} style={{strokeWidth:'0.75px', marginTop:'3px'}}/>, 'Following', activeTab === 'explore'),
      });
    } else if (location.pathname.startsWith('/n/')) {
      setTabNames({
        first: getTabContent(<PiNotePencilBold/>, 'Related Notes', activeTab === 'library'),
      });
    }
  };

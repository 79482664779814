import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../Context/AppContext';
import { useLocation } from 'react-router-dom';
import NoteCard from '../NoteCard/NoteCard';
import ResourceCard from '../ResourceCard/ResourceCard';
import Loader from '../Loader';
import NoContentPlaceholder from './NoContentPlaceholder';
import { getSingleNote } from '@linko/shared_utils';
import AddNoteButton from '../NoteCard/AddNoteButton';

const TabLibrary = ({
    library = [],
    setLibrary,
    isFetchingMoreLibrary,
    hasMoreLibrary,
    noteHint,
    showTextArea,
    setShowTextArea,
    resource,
    subjectId,
    }) => { 

    const location = useLocation();
    const profilePage = location.pathname.startsWith('/profile/');
    const homePage = location.pathname.startsWith('/my_linko');
    const subjectPage = location.pathname.startsWith('/s/');
    const resourcePage = location.pathname.startsWith('/r/');
    const shareView = profilePage ? true : false;
    const { userResourceCount, userNoteCount } = useContext(UserContext);

    const deleteNote = (noteId) => {
        setLibrary(library.filter(item => item.id !== noteId));
    };

    const updateSingleNote = (noteId) => {
        getSingleNote(noteId)
        .then((response) => {
            const updatedNote = response.data.note;
            setLibrary(library.map(item => item.id === noteId ? updatedNote : item));
        })
        .catch((error) => {
            console.error('Error fetching note:', error.response ? error.response.data : error.message);
        });
    }

    const deleteResource = (id) => {
        setLibrary(library.filter(item => item.resource.id !== id));
    };

    const updateResource = (updatedResource) => {
        if (!homePage) {
            setLibrary(library.map(item => item.id === updatedResource.id ? updatedResource : item));
        }
    };

    if ( homePage && (userResourceCount + userNoteCount === 0) && !isFetchingMoreLibrary ) {
        return (
            <div>
                <div style={{paddingBottom:'20px'}}>
                    <div style={{paddingBottom:'10px'}}>
                        <NoContentPlaceholder activeTab={'library'}/>
                    </div>
                    <AddNoteButton 
                        showTextArea={showTextArea}
                        setShowTextArea={setShowTextArea}
                        noteHint={noteHint}
                        resource={resource}
                        subjectId={subjectId}
                        setLibrary={setLibrary}
                    />
                </div>
            </div>
        );
    }

    if ( !homePage && !profilePage && library.length === 0 && !isFetchingMoreLibrary) {
        return (
            <div>
                <div style={{paddingBottom:'10px'}}>
                    <NoContentPlaceholder activeTab={'library'}/>
                </div>
                <AddNoteButton 
                    showTextArea={showTextArea}
                    setShowTextArea={setShowTextArea}
                    noteHint={noteHint}
                    resource={resource}
                    subjectId={subjectId}
                    setLibrary={setLibrary}
                />
            </div>
        );
    }
    
    return (
        <div>
            {!profilePage && (
                <AddNoteButton 
                    showTextArea={showTextArea}
                    setShowTextArea={setShowTextArea}
                    noteHint={noteHint}
                    resource={resource}
                    subjectId={subjectId}
                    setLibrary={setLibrary}
                />
            )}
            <div className='cards-container'>
                {library && library.map((item, index) => {
                    const isNote = item.hasOwnProperty('note');
                    const isFirstResource = !isNote && library.slice(0, index).every(i => i.hasOwnProperty('note'));
                    return (
                        <React.Fragment key={`${item.id}-fragment`}>
                            {isFirstResource && resourcePage && (
                                <div className="feed-resource-divider">Related resources</div>
                            )}
                            <div style={{width:'100%'}}>
                                {isNote ? 
                                    <NoteCard 
                                        key={`N${item.id}`}
                                        note={item} 
                                        onDelete={deleteNote} 
                                        // onUpdate={updateNoteContent} 
                                        shareView={shareView}
                                        updateSingleNote={updateSingleNote}
                                    />
                                    :
                                    <ResourceCard 
                                        key={`R${item.id}`}
                                        resource={item} 
                                        onDelete={deleteResource}
                                        onUpdate={updateResource}
                                        shareView={shareView}
                                    />
                                }
                            </div>
                        </React.Fragment>
                    );
                })}
            </div>
            {profilePage && library.length > 0 && (
                <div style={{paddingBottom:'20px', marginTop:'20px'}}>
                    <NoContentPlaceholder activeTab={'library'} limited={true}/>
                </div>
            )}
            {hasMoreLibrary && isFetchingMoreLibrary ? 
                <div className='tab-content-loader'>
                    <Loader/>
                </div>
                :
                <div style={{height:'10px'}}></div>
            }
            {!hasMoreLibrary && library.length >= 10 && !profilePage ? 
                <p style={{paddingLeft:'24px', fontStyle:'italic', margin:'30px 0'}}>You've reached the end!</p>
                :
                <div style={{height:'30px'}}></div>
            }
        </div>
    );
};

export default TabLibrary;
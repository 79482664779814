import React from 'react';

class TermOfService extends React.Component {
    render() {
        return (
            <div className='privacy-policy'>
                <h1>Terms of Service for Linko</h1>
                <p><span>Last Updated: 03/18/2025</span></p>

                <p>Welcome to Linko! These Terms of Service ("Terms") govern your use of the Linko website, mobile application, and services (collectively, the "Service"), provided by Linko ("we," "us," or "our"). By accessing or using our Service, you agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms, you may not access or use the Service.</p>

                <h2>1. Using Linko</h2>
                <p><span>a. Eligibility:</span> You must be at least 13 years old to use the Service. By agreeing to these Terms, you represent and warrant that you are of legal age to form a binding contract with us.</p>
                <p><span>b. Account Registration:</span> To access certain features of the Service, you must register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
                <p><span>c. User Content:</span> You are responsible for the content that you post to the Service, including its legality, reliability, and appropriateness. By posting content to the Service, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such content on and through the Service.</p>

                <h2>2. Prohibited Conduct</h2>
                <p>You agree not to use the Service:</p>
                <ul>
                    <li>For any unlawful purpose or in violation of any local, state, national, or international law.</li>
                    <li>To infringe upon or violate our intellectual property rights or the intellectual property rights of others.</li>
                    <li>To harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability.</li>
                    <li>To submit false or misleading information.</li>
                    <li>To upload or transmit viruses or any other type of malicious code.</li>
                </ul>

                <h2>3. Termination</h2>
                <p>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>

                <h2>4. Disclaimer of Warranties</h2>
                <p>Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. We disclaim all warranties, express or implied, in connection with the Service to the fullest extent permitted by law.</p>

                <h2>5. Limitation of Liability</h2>
                <p>To the maximum extent permitted by applicable law, in no event shall Linko, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.</p>

                <h2>6. Changes to Terms</h2>
                <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms.</p>

                <h2>7. Contact Us</h2>
                <p>If you have any questions about these Terms, please contact us at <a href='mailto:linko.assistant@gmail.com'>linko.assistant@gmail.com</a>.</p>
            </div>
        );
    }
}

export default TermOfService;
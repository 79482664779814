import React, { useEffect, useState } from 'react';
import { FaSearch, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import '../../Stylesheets/PagesStyles/HelpCenter.scss';

const HelpCenter = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Help Center | Linko";
    }, []);

    const [searchQuery, setSearchQuery] = useState('');
    const [expandedFaq, setExpandedFaq] = useState(null);

    const faqs = [
        {
            id: 1,
            question: "What is Linko?",
            answer: "Linko is a knowledge management platform that helps you organize, connect, and share your knowledge. It allows you to create a personal knowledge network where all your notes, resources, and ideas can be linked together in meaningful ways."
        },
        {
            id: 2,
            question: "How do I get started with Linko?",
            answer: "To get started, create an account, then add your first note or resource. From there, you can start adding notes and connecting them to build your knowledge network. Check out our Getting Started guide in the Guides section below for more details."
        },
        {
            id: 3,
            question: "Is Linko free to use?",
            answer: "Linko offers both free and premium plans. The free plan includes basic features that allow you to organize and connect your knowledge, while premium plans offer additional features with a subscription."
        },
        {
            id: 4,
            question: "How secure is my data on Linko?",
            answer: "At Linko, we take data security seriously. All your data is encrypted, and we follow industry-standard security practices. You have full control over what you share and with whom."
        }
    ];

    const guides = [
        {
            id: 'getting-started',
            title: 'Getting Started with Linko',
            description: 'Learn the basics of Linko and set up your knowledge system.',
            link: '#getting-started'
        },
        {
            id: 'creating-subjects',
            title: 'Creating and Managing Subjects',
            description: 'Organize your knowledge into meaningful categories.',
            link: '#creating-subjects'
        },
        {
            id: 'adding-resources',
            title: 'Adding Resources and Notes',
            description: 'Learn how to add and manage different types of content.',
            link: '#adding-resources'
        },
        {
            id: 'knowledge-graph',
            title: 'Using the Knowledge Graph',
            description: 'Visualize and navigate through your connected knowledge.',
            link: '#knowledge-graph'
        }
    ];

    const toggleFaq = (id) => {
        setExpandedFaq(expandedFaq === id ? null : id);
    };

    const filteredFaqs = faqs.filter(faq => 
        faq.question.toLowerCase().includes(searchQuery.toLowerCase()) || 
        faq.answer.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="help-center-page">
            <section className="hero-section">
                <div className="container">
                    <h1>Help Center</h1>
                    <p className="subtitle">Find answers to common questions and learn how to get the most out of Linko.</p>
                    <div className="search-container">
                        <div className="search-input">
                            <FaSearch className="search-icon" />
                            <input 
                                type="text" 
                                placeholder="Search for help..." 
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="faq-section">
                <div className="container">
                    <h2>Frequently Asked Questions</h2>
                    <div className="faqs">
                        {filteredFaqs.length > 0 ? (
                            filteredFaqs.map(faq => (
                                <div className="faq-item" key={faq.id}>
                                    <div 
                                        className="faq-question"
                                        onClick={() => toggleFaq(faq.id)}
                                    >
                                        <h3>{faq.question}</h3>
                                        <span className="toggle-icon">
                                            {expandedFaq === faq.id ? <FaChevronUp /> : <FaChevronDown />}
                                        </span>
                                    </div>
                                    {expandedFaq === faq.id && (
                                        <div className="faq-answer">
                                            <p>{faq.answer}</p>
                                        </div>
                                    )}
                                </div>
                            ))
                        ) : (
                            <div className="no-results">
                                <p>No results found for "{searchQuery}". Please try another search term.</p>
                            </div>
                        )}
                    </div>
                </div>
            </section>

            <section className="guides-section">
                <div className="container">
                    <h2>Guides & Tutorials</h2>
                    <div className="guides-grid">
                        {guides.map(guide => (
                            <div className="guide-card" key={guide.id}>
                                <h3>{guide.title}</h3>
                                <p>{guide.description}</p>
                                <a href={guide.link} className="guide-link">Read Guide</a>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="support-section">
                <div className="container">
                    <h2>Still Need Help?</h2>
                    <p>Our support team is here to assist you with any questions or issues you may have.</p>
                    <div className="support-options">
                        <div className="support-card">
                            <h3>Email Support</h3>
                            <p>Send us an email and we'll get back to you as soon as possible.</p>
                            <a href="mailto:linko.assistant@gmail.com" className="support-button">Contact Support</a>
                        </div>
                        <div className="support-card">
                            <h3>Community Forum</h3>
                            <p>Join our community forum to get help from other Linko users.</p>
                            <a href="#" className="support-button">Visit Forum</a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HelpCenter; 
import React, { useRef, useEffect } from 'react';
import Loader from '../Loader';
import { subjectPage } from '@linko/shared_utils';
import CustomLink from '../CustomLink';
import SlimResourceCard from '../ResourceCard/SlimResourceCard';
import SlimNoteCard from '../NoteCard/SlimNoteCard';


const SearchResults = ({
    isLoading,
    resources,
    notes,
    subject,
    isFocused,
    searchTerm,
    setCloseAllowed,
}) => {
    const searchResultsRef = useRef(null);
    const sectionTitleRefs = useRef([]);
    sectionTitleRefs.current = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

    const handleScroll = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        const container = searchResultsRef.current;
        if (container) {
            container.scrollTop += e.deltaY;
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const id = entry.target.getAttribute('id');
                    if (entry.isIntersecting) {
                        entry.target.classList.add('sticky');
                    } else {
                        entry.target.classList.remove('sticky');
                    }
                });
            },
            { threshold: 0.1 }
        );

        sectionTitleRefs.current.forEach((ref) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => {
            sectionTitleRefs.current.forEach((ref) => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, []);

    useEffect(() => {
        const container = searchResultsRef.current;
        if (container && isFocused) {
            container.addEventListener('wheel', handleScroll, { passive: false });
        }

        return () => {
            if (container) {
                container.removeEventListener('wheel', handleScroll);
            }
        };
    }, [isFocused]);

    if (!isFocused || searchTerm === '') {
        return null;
    }

    return (
        <div 
            className="full-search-results" 
            ref={searchResultsRef}
        >
            {isLoading && <Loader />}
            {resources && resources.length > 0 && (
                <div ref={sectionTitleRefs.current[1]} style={{ width: '100%' }}>
                    <h2 ref={sectionTitleRefs.current[2]} className='full-search-results-title'>My resources</h2>
                    <div className='full-search-results-subjects'>
                        {resources.length > 0 ? (
                            resources.map(resource => (
                                <SlimResourceCard
                                    key={resource.id}
                                    id={resource.id}
                                    resource={resource}
                                    learnHistory={null}
                                    onResourceClick={() => {setCloseAllowed(false); safeNavigate(`/r/${resource.id}`)}}
                                />
                            ))
                        ) : <div>No matching result</div>}
                    </div>
                </div>
            )}

            {/* Note */}
            {notes && notes.length > 0 && (
                <div ref={sectionTitleRefs.current[2]} style={{ width: '100%' }}>
                    <h2 className='full-search-results-title'>My notes</h2>
                    <div className='full-search-results-subjects'>
                        {notes.length > 0 ? (
                            notes.map(note => (
                                <CustomLink
                                    key={note.id}
                                    to={`/n/${note.id}`}
                                    onClick={() => setCloseAllowed(false)}
                                >
                                    <SlimNoteCard
                                        title={note.title}
                                        content={note.note}
                                    />
                                </CustomLink>
                            ))
                        ) : <div>No matching result</div>}
                    </div>
                </div>
            )}

            {/* Subject */}
            {subject?.myTag && subject.myTag.length > 0 && (
                <div ref={sectionTitleRefs.current[2]} style={{ width: '100%' }}>
                    <h2 className='full-search-results-title'>My Tags</h2>
                    <div className='full-search-results-subjects'>
                        {subject.myTag.length > 0 ? (
                            subject.myTag.map(subject => (
                                <div className="subject-tag cus-label" key={subject.id}>
                                    <CustomLink
                                        to={subjectPage(subject.name, true)}
                                        onClick={() => setCloseAllowed(false)}
                                    >
                                        {subject.name}
                                    </CustomLink>
                                </div>
                            ))
                        ) : <div>No matching result</div>}
                    </div>
                </div>
            )}

            {subject?.studied && subject.studied.length > 0 && (
                <div ref={sectionTitleRefs.current[2]} style={{ width: '100%' }}>
                    <h2 className='full-search-results-title'>Fields Explored</h2>
                    <div className='full-search-results-subjects'>
                        {subject.studied.length > 0 ? (
                            subject.studied.map(subject => (
                                <div className="subject-tag studied" key={subject.id}>
                                    <CustomLink
                                        to={subjectPage(subject.name, false)}
                                        onClick={() => setCloseAllowed(false)}
                                >
                                    {subject.name}
                                    </CustomLink>
                                </div>
                            ))
                        ) : <div>No matching result</div>}
                    </div>
                </div>
            )}

            {subject?.notStudied && subject.notStudied.length > 0 && (
                <div ref={sectionTitleRefs.current[3]} style={{ width: '100%' }}>
                    <h2 className='full-search-results-title'>All Fields</h2>
                    <div className='full-search-results-subjects'>
                        {subject.notStudied.length > 0 ? (
                            subject.notStudied.map(subject => (
                                <div className="subject-tag not-studied" key={subject.id}>
                                    <CustomLink
                                        to={subjectPage(subject.name, false)}
                                        onClick={() => setCloseAllowed(false)}
                                    >
                                        {subject.name}
                                    </CustomLink>
                                </div>
                            ))
                        ) : <div>No matching result</div>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SearchResults;

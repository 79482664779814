import React, { useState, useContext } from 'react';
import { UserContext } from '../../Context/AppContext';
import { FaChevronRight, FaExternalLinkAlt, FaRegCopy, FaChartBar, FaShieldAlt } from 'react-icons/fa';
import { useToast } from '../../Components/ToastNotification';
import { NameChangeModal, ResetPasswordModal } from './InputModals';
import { putUserData, profilePage } from '@linko/shared_utils';
import { Link } from 'react-router-dom';

const SettingPage = () => {
    const { userInfo, setUserInfo, fetchUserInfo } = useContext(UserContext);
    const { addToast } = useToast();
    const [isNameModalOpen, setIsNameModalOpen] = useState(false);
    const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);

    const handleNameChange = () => {
        setIsNameModalOpen(true);
    };

    const handlePasswordReset = () => {
        setIsResetPasswordModalOpen(true);
    };

    const handlePrivacyChange = async (newValue) => {
        try {
            const response = await putUserData({ private_account: newValue });
            if (response.status === 200) {
                setUserInfo(prevUserInfo => ({
                    ...prevUserInfo,
                    private_account: newValue
                }));
                fetchUserInfo();
                addToast('Privacy settings updated successfully', { appearance: 'success' });
            } else {
                window.alert('Failed to update privacy settings');
            }
        } catch (error) {
            console.error('Failed to update privacy settings:', error);
            window.alert('Failed to update privacy settings');
        }
    };

    const handleNotePrivacyChange = async (newValue) => {
        try {
            const response = await putUserData({ private_note: newValue });
            if (response.status === 200) {
                setUserInfo(prevUserInfo => ({
                    ...prevUserInfo,
                    private_note: newValue
                }));
                fetchUserInfo();
                addToast('Privacy settings updated successfully', { appearance: 'success' });
            } else {
                window.alert('Failed to update privacy settings');
            }
        } catch (error) {
            console.error('Failed to update privacy settings:', error);
            window.alert('Failed to update privacy settings');
        }
    };

    const copyProfileLink = () => {
        if (navigator.clipboard) {
          const profileLink = `https://www.linko.study${profilePage(userInfo.id)}`;
          navigator.clipboard.writeText(profileLink)
            .then(() => {
                addToast('Profile link copied!', {appearance: 'success'});
            })
            .catch(err => {
              console.error('Error writing to clipboard', err);
              addToast('Failed to copy profile link', {appearance: 'error'});
            });
        } else {
          console.error('Clipboard API not supported');
            addToast('Failed to copy profile link', {appearance: 'error'});
        }
    }

    return (
        <div className="profile-page setting-page">
            <h1 style={{paddingLeft:'0px'}}>Settings</h1>
            
            <div className="setting-item">
                <div>
                    <h3>Email address</h3>
                    <p className="description">{userInfo?.email}</p>
                </div>
            </div>
            
            <div className="setting-item clickable" onClick={handleNameChange}>
                <div>
                    <h3>Name</h3>
                    <p className="description">{userInfo?.first_name} {userInfo?.last_name}</p>
                </div>
                <span className="secondary-text">
                    Edit name
                    <FaChevronRight className="chevron-icon"/>
                </span>
            </div>
            
            <div className="setting-item clickable" onClick={handlePasswordReset}>
                <div>
                    <h3>Password</h3>
                </div>
                <span className="secondary-text">
                    Reset password 
                    <FaChevronRight className="chevron-icon"/>
                </span>
            </div>
            
            <div className="toggle-container">
                <div>
                    <h3>Private mode</h3>
                    <p className="description">You are currently in <span style={{fontWeight: 'bold'}}>{userInfo?.private_account ? 'Private mode' : 'Public mode'}</span></p>
                    <ul className="description">
                        <li>Private mode: All your content will always be private. You will not be able to follow other users or be followed by others.</li>
                        <li>Public mode: Your selected public notes and resources will be visible to the public on your profile page. You will be able to follow other users and be followed by others. All following relationships require the approval of the followee.</li>
                    </ul>
                </div>
                <label className="toggle-switch">
                    <input 
                        type="checkbox" 
                        checked={userInfo?.private_account || false} 
                        onChange={() => handlePrivacyChange(!userInfo?.private_account)}
                    />
                    <span className="toggle-slider"></span>
                </label>
            </div>

            {/* Public account settings */}
            {!userInfo?.private_account && (
                <>
                    <div className="setting-item profile-link">
                        <div>
                            <h3>Profile link</h3>
                            <div className="link-container">
                                <span className="link" onClick={() => window.open(`https://www.linko.study${profilePage(userInfo?.id)}`, '_blank')}>
                                    https://www.linko.study{profilePage(userInfo?.id)}
                                </span>
                                <span className="copy-icon" style={{marginLeft: '10px'}} onClick={copyProfileLink}>
                                    <FaRegCopy size={18}/>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="toggle-container">
                        <div>
                            <h3>Default note privacy</h3>
                            <p className="description">When this toggle is ON, new notes will be set to private by default. When OFF, new notes will be public by default.</p>
                        </div>
                        <label className="toggle-switch">
                            <input 
                                type="checkbox" 
                                checked={userInfo?.private_note || false} 
                                onChange={() => handleNotePrivacyChange(!userInfo?.private_note)}
                            />
                            <span className="toggle-slider"></span>
                        </label>
                    </div>
                </>
            )}

            {/* Analytics Information Section */}
            <div className="setting-section" id="data-privacy">
                <h2>Data & Privacy</h2>
                
                <div className="setting-item">
                    <div className="analytics-info">
                        <div className="analytics-header">
                            <FaChartBar className="analytics-icon" />
                            <h3>Usage Analytics</h3>
                        </div>
                        <p className="description">
                            We use Amplitude to collect anonymized usage data that helps us improve Linko. 
                            This includes information about how you interact with our app's features.
                        </p>
                        <p className="description">
                            <strong>Important privacy protections:</strong>
                        </p>
                        <ul className="description">
                            <li>Your note content is never tracked or recorded</li>
                            <li>Text inputs and search queries are automatically masked</li>
                            <li>File uploads and personal documents are not tracked</li>
                            <li>We do not collect or store your IP address</li>
                        </ul>
                        <p className="description">
                            <Link to="/privacy_policy#analytics" className="privacy-link">
                                Read more about our analytics <FaExternalLinkAlt size={12} />
                            </Link>
                        </p>
                    </div>
                </div>
                
                <div className="setting-item">
                    <div className="analytics-info">
                        <div className="analytics-header">
                            <FaShieldAlt className="analytics-icon" />
                            <h3>Private Note Server-Side Encryption</h3>
                        </div>
                        <p className="description">
                            Your private notes are automatically encrypted using strong encryption methods.
                            Here's how we protect your data:
                        </p>
                        <ul className="description">
                            <li>Encrypted storage of your private note data</li>
                            <li>Decryption only happens when you access your notes</li>
                            <li>Secure ID obfuscation to prevent unauthorized access</li>
                        </ul>
                        <p className="description">
                            <Link to="/privacy_policy#encryption" className="privacy-link">
                                Learn more about our encryption methods <FaExternalLinkAlt size={12} />
                            </Link>
                        </p>
                    </div>
                </div>
            </div>

            <NameChangeModal 
                isOpen={isNameModalOpen} 
                onClose={() => setIsNameModalOpen(false)} 
            />
            <ResetPasswordModal 
                isOpen={isResetPasswordModalOpen} 
                onClose={() => setIsResetPasswordModalOpen(false)} 
            />
        </div>
    );
};

export default SettingPage;

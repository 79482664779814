import axios from "axios";
import storageUtils from "../storageUtils.js";

// Create axios instance that will be exported after configuration is loaded
const axiosInstance = axios.create();

// Default configuration - will be overridden once env.js is loaded
let envConfig = {
  API_BASE_URL: window.location.origin + '/' // Use current domain as fallback
};

// Load configuration before exporting
const initializeAxios = async () => {
  try {
    // Dynamic import of environment configuration
    const module = await import("./env.js");
    envConfig = module.default || module;
  } catch (error) {
    console.warn("Could not load environment config, using defaults", error);
  }
  
  // Set base URL once configuration is loaded
  axiosInstance.defaults.baseURL = envConfig.API_BASE_URL;
};

// Variables for handling token refresh
let isRefreshing = false;
let failedQueue = [];
let isHandlingSession = false;

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const handleSessionExpiration = async () => {
  if (isHandlingSession) return;
  isHandlingSession = true;

  try {
    // Clear all tokens and auth headers
    await storageUtils.removeItem("access_token");
    await storageUtils.removeItem("refresh_token");
    delete axiosInstance.defaults.headers.common["Authorization"];
    
    // Store session expired message
    await storageUtils.setItem("session_expired", "Session expired, please login again.");
    
    // Clear any pending requests
    processQueue(new Error('Session expired - please log in again'), null);
    failedQueue = [];
    
    // Emit event
    if (typeof window !== 'undefined') {
      const event = new CustomEvent('session-expired', {
        detail: { message: 'Your session has expired. Please log in again.' }
      });
      window.dispatchEvent(event);
    }
  } catch (error) {
    console.error('Error during session expiration handling:', error);
  } finally {
    isHandlingSession = false;
    isRefreshing = false;
  }
};

// Configure interceptors
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    
    // If there's no response or no config, reject immediately
    if (!error.response || !originalRequest) {
      return Promise.reject(error);
    }
    
    // If we're already handling session expiration, reject immediately
    if (isHandlingSession) {
      return Promise.reject(new Error('Session is being refreshed. Please wait...'));
    }

    const status = error.response?.status;
    const errorData = error.response?.data;
    
    // Handle refresh token request failure
    if (originalRequest?.url === "/api/auth/refresh/") {
      await handleSessionExpiration();
      return Promise.reject(new Error('Unable to refresh session. Please log in again.'));
    }

    const publicRoutes = [
      "/api/auth/register/",
      "/api/auth/activate/",
      "/api/auth/reset_password/",
      "/api/auth/login/",
      "/api/auth/logout/",
    ];

    // If it's a public route, don't try to refresh
    if (publicRoutes.includes(originalRequest?.url)) {
      return Promise.reject(error);
    }

    const isInvalidTokenError = 
      status === 401 && 
      (errorData?.code === "token_not_valid" ||
       errorData?.messages?.[0]?.message === "Token is invalid or expired" ||
       errorData?.detail === "Given token not valid for any token type");

    // Handle 401 errors for non-public routes
    if (status === 401 || isInvalidTokenError) {
      if (isRefreshing) {
        try {
          // Queue the request if we're already refreshing
          const token = await new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          });
          originalRequest.headers["Authorization"] = "Bearer " + token;
          return axiosInstance(originalRequest);
        } catch (queueError) {
          await handleSessionExpiration();
          return Promise.reject(new Error('Authentication failed. Please log in again.'));
        }
      }

      isRefreshing = true;

      try {
        const refreshToken = await storageUtils.getItem("refresh_token");
        if (!refreshToken) {
          throw new Error("No refresh token available");
        }

        const refreshAxios = axios.create();
        const response = await refreshAxios.post("/api/auth/refresh/", 
          { refresh: refreshToken },
          { 
            headers: { 
              "Content-Type": "application/json",
              "Authorization": null
            },
            baseURL: envConfig.API_BASE_URL // Use the correct base URL for refresh
          }
        );

        const { access, refresh } = response.data;
        await storageUtils.setItem("access_token", access);
        await storageUtils.setItem("refresh_token", refresh);
        axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${access}`;
        
        processQueue(null, access);
        originalRequest.headers["Authorization"] = "Bearer " + access;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        await handleSessionExpiration();
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    // For all other errors, reject with the original error
    return Promise.reject(error);
  }
);

// Initialize axios with the correct configuration
await initializeAxios();

// Export the configured axios instance
export default axiosInstance;
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { NavigationPromptContext } from './NavigationPromptContext';

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [canGoBack, setCanGoBack] = useState(window.history.length > 1);
  const navigationPromptContext = useContext(NavigationPromptContext);

  const goBack = () => {
    if (navigationPromptContext) {
      navigationPromptContext.safeNavigate(-1);
    } else {
      navigate(-1);
    }
  };

  // Update canGoBack whenever location changes
  useEffect(() => {
    setCanGoBack(window.history.length > 1);
  }, [location]);

  return (
    <NavigationContext.Provider value={{ 
      goBack, 
      canGoBack
    }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  const navigationPromptContext = useContext(NavigationPromptContext);
  
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  
  return {
    ...context,
    hasUnsavedChanges: navigationPromptContext?.hasUnsavedChanges || false,
    registerEditorChanges: navigationPromptContext?.registerEditorChanges,
    safeNavigate: navigationPromptContext?.safeNavigate
  };
};

import axios from '../Middleware/axios';
import storageUtils from '../storageUtils';

export const fetchEssentialBooks = async (subjectId) => {
  try {
    const accessToken = await storageUtils.getItem('access_token');
    const headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
    const response = await axios.get(`/api/subject/essential_books/?subject_id=${subjectId}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching essential books:', error);
    throw error;
  }
}; 
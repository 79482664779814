import React from 'react';

// CEO Avatar - Tianqi Jiang
export const CEOAvatar = () => (
  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
    {/* Background circle */}
    <circle cx="100" cy="100" r="100" fill="#e0f7ea" />
    
    {/* Body shape */}
    <ellipse cx="100" cy="175" rx="50" ry="15" fill="#4abe80" />
    
    {/* Neck */}
    <rect x="95" y="125" width="10" height="10" fill="#4abe80" />
    
    {/* Head */}
    <circle cx="100" cy="100" r="40" fill="#4abe80" />
    
    {/* Business hairstyle */}
    <path d="M65 85 C70 65 85 55 100 55 C115 55 130 65 135 85" fill="#257953" />
    
    {/* Eyes */}
    <circle cx="85" cy="90" r="3" fill="white" />
    <circle cx="115" cy="90" r="3" fill="white" />
    <circle cx="85" cy="90" r="1" fill="#257953" />
    <circle cx="115" cy="90" r="1" fill="#257953" />
    
    {/* Smile */}
    <path d="M85 110 Q100 120 115 110" stroke="#257953" strokeWidth="2" fill="none" />
    
    {/* CEO tie */}
    <path d="M100 130 L95 145 L100 160 L105 145 Z" fill="#257953" />
    
    {/* Arms */}
    <line x1="75" y1="110" x2="65" y2="90" stroke="#257953" strokeWidth="2" />
    <line x1="125" y1="110" x2="135" y2="90" stroke="#257953" strokeWidth="2" />
  </svg>
);

// Developer Avatar - Silvia
export const DeveloperAvatar = () => (
  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
    {/* Background circle */}
    <circle cx="100" cy="100" r="100" fill="#e0f7ea" />
    
    {/* Body shape */}
    <ellipse cx="100" cy="175" rx="50" ry="15" fill="#4abe80" />
    
    {/* Neck */}
    <rect x="95" y="125" width="10" height="10" fill="#4abe80" />
    
    {/* Head */}
    <circle cx="100" cy="100" r="40" fill="#4abe80" />
    
    {/* Hair outline */}
    <path d="M70 85 C70 60 130 60 130 85" stroke="#257953" strokeWidth="3" fill="none" />
    
    {/* Eyes */}
    <circle cx="85" cy="90" r="3" fill="white" />
    <circle cx="115" cy="90" r="3" fill="white" />
    <circle cx="85" cy="90" r="1" fill="#257953" />
    <circle cx="115" cy="90" r="1" fill="#257953" />
    
    {/* Smile */}
    <path d="M90 105 Q100 110 110 105" stroke="#257953" strokeWidth="2" fill="none" />
    
    {/* Digital device/tablet */}
    <rect x="85" y="145" width="30" height="20" rx="2" fill="#257953" />
    <rect x="88" y="148" width="24" height="14" rx="1" fill="white" />
    <line x1="90" y1="152" x2="105" y2="152" stroke="#257953" strokeWidth="1" />
    <line x1="90" y1="155" x2="100" y2="155" stroke="#257953" strokeWidth="1" />
    <line x1="90" y1="158" x2="97" y2="158" stroke="#257953" strokeWidth="1" />
  </svg>
);

// Designer Avatar - Gemma
export const DesignerAvatar = () => (
  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
    {/* Background circle */}
    <circle cx="100" cy="100" r="100" fill="#e0f7ea" />
    
    {/* Body shape */}
    <ellipse cx="100" cy="175" rx="50" ry="15" fill="#4abe80" />
    
    {/* Neck */}
    <rect x="95" y="125" width="10" height="10" fill="#4abe80" />
    
    {/* Head */}
    <circle cx="100" cy="100" r="40" fill="#4abe80" />
    
    {/* Designer hat/beret */}
    <path d="M70 75 L83 60 L100 55 L117 60 L130 75" fill="#257953" />
    
    {/* Eyes */}
    <circle cx="85" cy="90" r="3" fill="white" />
    <circle cx="115" cy="90" r="3" fill="white" />
    <circle cx="85" cy="90" r="1" fill="#257953" />
    <circle cx="115" cy="90" r="1" fill="#257953" />
    
    {/* Smile */}
    <path d="M90 105 Q100 110 110 105" stroke="#257953" strokeWidth="2" fill="none" />
    
    {/* Paintbrush */}
    <line x1="130" y1="90" x2="140" y2="115" stroke="#257953" strokeWidth="2" />
    <path d="M138 112 L142 118 L140 120 L136 114 Z" fill="#4abe80" />
  </svg>
);

// Engineer Avatar - D.J.
export const EngineerAvatar = () => (
  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
    {/* Background circle */}
    <circle cx="100" cy="100" r="100" fill="#e0f7ea" />
    
    {/* Body shape */}
    <ellipse cx="100" cy="175" rx="50" ry="15" fill="#4abe80" />
    
    {/* Neck */}
    <rect x="95" y="125" width="10" height="10" fill="#4abe80" />
    
    {/* Head */}
    <circle cx="100" cy="100" r="40" fill="#4abe80" />
    
    {/* Developer hairstyle */}
    <rect x="75" y="65" width="50" height="10" fill="#257953" />
    
    {/* Glasses */}
    <rect x="80" y="85" width="15" height="10" rx="2" fill="white" stroke="#257953" strokeWidth="1" />
    <rect x="105" y="85" width="15" height="10" rx="2" fill="white" stroke="#257953" strokeWidth="1" />
    <line x1="95" y1="90" x2="105" y2="90" stroke="#257953" strokeWidth="1" />
    
    {/* Smile */}
    <path d="M90 105 Q100 110 110 105" stroke="#257953" strokeWidth="2" fill="none" />
    
    {/* Code lines */}
    <line x1="75" y1="140" x2="85" y2="140" stroke="#257953" strokeWidth="1" />
    <line x1="75" y1="144" x2="90" y2="144" stroke="#257953" strokeWidth="1" />
    <line x1="75" y1="148" x2="82" y2="148" stroke="#257953" strokeWidth="1" />
    
    <line x1="115" y1="140" x2="125" y2="140" stroke="#257953" strokeWidth="1" />
    <line x1="110" y1="144" x2="125" y2="144" stroke="#257953" strokeWidth="1" />
    <line x1="118" y1="148" x2="125" y2="148" stroke="#257953" strokeWidth="1" />
    
    {/* Keyboard */}
    <rect x="80" y="155" width="40" height="8" rx="2" fill="#257953" />
    <line x1="85" y1="159" x2="90" y2="159" stroke="white" strokeWidth="1" />
    <line x1="95" y1="159" x2="100" y2="159" stroke="white" strokeWidth="1" />
    <line x1="105" y1="159" x2="110" y2="159" stroke="white" strokeWidth="1" />
  </svg>
); 
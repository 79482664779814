/**
 * Analytics service for Linko that integrates with Amplitude.
 * This service provides methods for tracking user events and identifying users.
 * Includes privacy controls appropriate for a note-taking application.
 */

// Constants for event types to maintain consistency
export const EVENT_TYPES = {
  // User actions
  PAGE_VIEW: 'Page View',
  CLICK: 'Click',
  SIGNUP: 'Sign Up',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  
  // Content interactions
  NOTE_CREATE: 'Note Created',
  NOTE_EDIT: 'Note Edited',
  NOTE_DELETE: 'Note Deleted',
  NOTE_SHARE: 'Note Shared',
  
  // Resource interactions
  RESOURCE_ADD: 'Resource Added',
  RESOURCE_OPEN: 'Resource Opened',
  RESOURCE_SHARE: 'Resource Shared',
  
  // Social interactions
  FOLLOW_USER: 'Follow User',
  UNFOLLOW_USER: 'Unfollow User',
  
  // Search
  SEARCH_QUERY: 'Search Query',
  
  // Settings
  SETTINGS_CHANGE: 'Settings Changed',
  
  // Errors
  ERROR: 'Error Occurred',
  
  // Privacy related
  CONSENT_UPDATED: 'Consent Updated',
};

/**
 * Privacy level settings for analytics
 */
export const PRIVACY_LEVELS = {
  MINIMAL: 'minimal',   // Only essential analytics
  MODERATE: 'moderate', // Standard analytics without session replay
  FULL: 'full',         // All analytics including session replay
};

// Default privacy settings - start with FULL tracking
let currentPrivacyLevel = PRIVACY_LEVELS.FULL;

/**
 * Check if we're running in production environment
 * @returns {boolean} Whether we're in production
 */
const isProductionEnvironment = () => {
  // Check for production environment in different ways
  // 1. Check NODE_ENV if available
  if (process.env.NODE_ENV === 'production') return true;
  
  // 2. Check hostname
  if (typeof window !== 'undefined') {
    const hostname = window.location.hostname;
    return hostname === 'linko.study' || 
           hostname === 'www.linko.study' || 
           hostname.endsWith('.linko.study');
  }
  
  return false;
};

/**
 * Check if Amplitude is loaded and we're in production
 * @returns {boolean} Whether Amplitude is loaded and we're in production
 */
const isAmplitudeLoaded = () => {
  // Only return true if we're in production AND Amplitude is loaded
  return typeof window !== 'undefined' && 
         window.amplitude && 
         isProductionEnvironment();
};

/**
 * Check if user has given consent for the specified privacy level
 * @param {string} level - Privacy level to check
 * @returns {boolean} Whether user has consented to this level
 */
const hasConsent = (level) => {
  if (!isAmplitudeLoaded()) return false;
  
  // Always return true since we're using FULL level by default
  return true;
};

/**
 * Set user privacy consent level
 * @param {string} level - Privacy level from PRIVACY_LEVELS
 */
export const setPrivacyConsent = (level) => {
  if (!isAmplitudeLoaded()) {
    console.debug(`[DEV] Analytics - Privacy level set to: ${level} (disabled in development)`);
    return;
  }
  
  try {
    currentPrivacyLevel = level;
    
    // Update Amplitude's consent
    switch (level) {
      case PRIVACY_LEVELS.MINIMAL:
        window.amplitude.setUserId(null); // Anonymous tracking only
        // Disable session replay
        if (window.amplitude.options && window.amplitude.options.plugins) {
          const replayPlugin = window.amplitude.options.plugins.find(p => p.name === 'sessionReplay');
          if (replayPlugin) replayPlugin.enabled = false;
        }
        // Disable most tracking except page views
        window.amplitude.setConfig({
          trackingOptions: {
            deviceManufacturer: false,
            deviceModel: false,
            ipAddress: false,
            language: true,
            osName: true,
            osVersion: false,
            platform: true,
            userId: false,
          }
        });
        break;
        
      case PRIVACY_LEVELS.MODERATE:
        // Enable standard tracking but not session replay
        if (window.amplitude.options && window.amplitude.options.plugins) {
          const replayPlugin = window.amplitude.options.plugins.find(p => p.name === 'sessionReplay');
          if (replayPlugin) replayPlugin.enabled = false;
        }
        // Standard tracking settings
        window.amplitude.setConfig({
          trackingOptions: {
            deviceManufacturer: true,
            deviceModel: true,
            ipAddress: false, // Still protect IP
            language: true,
            osName: true,
            osVersion: true,
            platform: true,
            userId: true,
          }
        });
        break;
        
      case PRIVACY_LEVELS.FULL:
      default:
        // Enable all tracking including session replay
        if (window.amplitude.options && window.amplitude.options.plugins) {
          const replayPlugin = window.amplitude.options.plugins.find(p => p.name === 'sessionReplay');
          if (replayPlugin) replayPlugin.enabled = true;
        }
        // Full tracking settings
        window.amplitude.setConfig({
          trackingOptions: {
            deviceManufacturer: true,
            deviceModel: true,
            ipAddress: false, // Still protect IP for compliance
            language: true,
            osName: true,
            osVersion: true,
            platform: true,
            userId: true,
          }
        });
        break;
    }
    
    // Track the consent change event
    window.amplitude.track(EVENT_TYPES.CONSENT_UPDATED, {
      privacyLevel: level,
      timestamp: new Date().toISOString()
    });
    
    console.debug(`Analytics - Privacy level set to: ${level}`);
  } catch (error) {
    console.error('Error setting privacy consent:', error);
  }
};

/**
 * Track an event with Amplitude
 * @param {string} eventType - The type of event to track
 * @param {object} [eventProperties={}] - Additional properties for the event
 * @param {string} [privacyLevel=PRIVACY_LEVELS.MINIMAL] - Minimum privacy level required to track this event
 */
export const trackEvent = (eventType, eventProperties = {}, privacyLevel = PRIVACY_LEVELS.MINIMAL) => {
  // Skip tracking in development mode
  if (!isProductionEnvironment()) {
    console.debug(`[DEV] Analytics - Event tracked: ${eventType} (disabled in development)`, eventProperties);
    return;
  }
  
  try {
    // Always track since we're using FULL level
    if (isAmplitudeLoaded()) {
      // Never include full note content in event properties
      const sanitizedProperties = { ...eventProperties };
      
      // Remove or mask potentially sensitive data
      if (sanitizedProperties.noteContent) {
        sanitizedProperties.noteContent = '[CONTENT MASKED]';
      }
      
      if (sanitizedProperties.query) {
        // Only keep length of search queries
        sanitizedProperties.queryLength = sanitizedProperties.query.length;
        delete sanitizedProperties.query;
      }
      
      // Add standard properties
      sanitizedProperties.privacyLevel = currentPrivacyLevel;
      
      window.amplitude.track(eventType, sanitizedProperties);
      console.debug(`Analytics - Event tracked: ${eventType}`, sanitizedProperties);
    } else {
      console.warn('Amplitude not loaded, event not tracked:', eventType);
    }
  } catch (error) {
    console.error('Error tracking event:', error);
  }
};

/**
 * Track a page view event
 * @param {string} pageName - The name of the page
 * @param {object} [additionalProperties={}] - Additional properties for the event
 */
export const trackPageView = (pageName, additionalProperties = {}) => {
  // Page views are tracked at MINIMAL level for basic analytics
  trackEvent(EVENT_TYPES.PAGE_VIEW, {
    page: pageName,
    url: window.location.href,
    referrer: document.referrer,
    ...additionalProperties,
  }, PRIVACY_LEVELS.MINIMAL);
};

/**
 * Identify a user in Amplitude
 * @param {string} userId - The user's ID
 * @param {object} [userProperties={}] - Additional properties about the user
 */
export const identifyUser = (userId, userProperties = {}) => {
  // Skip user identification in development mode
  if (!isProductionEnvironment()) {
    console.debug(`[DEV] Analytics - User identified: ${userId} (disabled in development)`);
    return;
  }
  
  try {
    if (isAmplitudeLoaded()) {
      // Set the user ID
      window.amplitude.setUserId(userId);
      
      // Sanitize user properties to remove sensitive data
      const sanitizedProperties = { ...userProperties };
      
      // Only include safe properties
      const safeProps = [
        'name', 'username', 'note_count', 'resource_count', 
        'following_count', 'follower_count', 'subject_count',
        'private_account', 'lastLogin'
      ];
      
      // Filter to only include safe properties
      const sanitizedProps = {};
      Object.keys(sanitizedProperties).forEach(key => {
        if (safeProps.includes(key)) {
          sanitizedProps[key] = sanitizedProperties[key];
        }
      });
      
      // Set user properties
      if (Object.keys(sanitizedProps).length > 0) {
        const identify = new window.amplitude.Identify();
        
        // Add each property to the identify object
        Object.entries(sanitizedProps).forEach(([key, value]) => {
          identify.set(key, value);
        });
        
        window.amplitude.identify(identify);
      }
      
      console.debug(`Analytics - User identified: ${userId}`);
    } else {
      console.warn('Amplitude not loaded, user not identified:', userId);
    }
  } catch (error) {
    console.error('Error identifying user:', error);
  }
};

/**
 * Reset the user identification (for logout)
 */
export const resetUser = () => {
  // Skip in development mode
  if (!isProductionEnvironment()) {
    console.debug(`[DEV] Analytics - User reset (disabled in development)`);
    return;
  }
  
  try {
    if (isAmplitudeLoaded()) {
      window.amplitude.reset();
      console.debug('Analytics - User reset');
    } else {
      console.warn('Amplitude not loaded, user not reset');
    }
  } catch (error) {
    console.error('Error resetting user:', error);
  }
};

/**
 * Set a group for the current user
 * @param {string} groupType - The type of group
 * @param {string|string[]} groupName - The name or names of the group
 */
export const setGroup = (groupType, groupName) => {
  // Skip in development mode
  if (!isProductionEnvironment()) {
    console.debug(`[DEV] Analytics - Group set: ${groupType} (disabled in development)`, groupName);
    return;
  }
  
  try {
    if (isAmplitudeLoaded()) {
      window.amplitude.setGroup(groupType, groupName);
      console.debug(`Analytics - Group set: ${groupType}`, groupName);
    } else {
      console.warn('Amplitude not loaded, group not set:', groupType);
    }
  } catch (error) {
    console.error('Error setting group:', error);
  }
};

// Initialize with FULL level
export const initializeAnalytics = () => {
  // Check if we're in production before initializing
  if (isProductionEnvironment()) {
    if (isAmplitudeLoaded()) {
      // Always set to FULL level
      setPrivacyConsent(PRIVACY_LEVELS.FULL);
      localStorage.setItem('linko_analytics_consent', PRIVACY_LEVELS.FULL);
      console.debug('Analytics initialized in production mode');
    }
  } else {
    console.debug('Analytics disabled in development mode');
  }
};

export default {
  trackEvent,
  trackPageView,
  identifyUser,
  resetUser,
  setGroup,
  setPrivacyConsent,
  PRIVACY_LEVELS,
  EVENT_TYPES,
  initializeAnalytics,
  isProductionEnvironment
}; 
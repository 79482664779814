import React, { useState, useEffect, useRef, useContext } from "react";
import { NavigationPromptContext } from "../../Context/NavigationPromptContext";
import { IoClose } from "react-icons/io5";
import Loader from "../Loader";
import { useToast } from '../ToastNotification';
import { searchBooks, postResourceAndLearn, getTrendingBooks, postResourceToMine } from '@linko/shared_utils';
import { HomePageContext } from '../../Context/HomePageContext';
import SlimResourceCard from '../ResourceCard/SlimResourceCard';

const AddBook = ({ setSelectedResource, onClose }) => {
    const [submissionLoading, setSubmissionLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [selectedBook, setSelectedBook] = useState(null);
    const [isExistingBook, setIsExistingBook] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isTrendingLoading, setIsTrendingLoading] = useState(false);
    const [trendingBooks, setTrendingBooks] = useState([]);
    const { setPersistentMessage } = useToast(); 
    const { safeNavigate } = useContext(NavigationPromptContext);
    const { addResourceToLibrary } = useContext(HomePageContext);
    const timeoutId = useRef();
    const inputRef = useRef();
    const modalRef = useRef();

    useEffect(() => {
        const fetchTrendingBooks = async () => {
            setIsTrendingLoading(true);
            try {
                const books = await getTrendingBooks();
                setTrendingBooks(books);
            } catch (error) {
                console.error("Error fetching trending books:", error);
                setPersistentMessage('Failed to load trending books', 'error');
            } finally {
                setIsTrendingLoading(false);
            }
        };
        fetchTrendingBooks();
    }, [setPersistentMessage]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                onClose();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [modalRef, onClose]);

    useEffect(() => {
        inputRef.current && inputRef.current.focus(); 
    }, []);

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
    };

    const handleSubmit = async () => {
        setSubmissionLoading(true);
        if (!selectedBook) {
            return;
        }
        try {
            let response;
            if (isExistingBook) {
                console.log("isExistingBook", isExistingBook);
                response = await postResourceToMine(selectedBook);
            } else {
                console.log("isExistingBook", isExistingBook);
                response = await postResourceAndLearn(selectedBook);
            }
            
            if (response.status === 201) {
                const newResource = response.data.resource;
                setSubmissionLoading(false);
                onClose();
                if (setSelectedResource) {
                    setSelectedResource(newResource.resource);
                } else {
                    safeNavigate(`/r/${newResource.resource.id}`);
                }
                setPersistentMessage('Book added successfully!');
                addResourceToLibrary(newResource);
            }
        } catch (error) {
            console.error("Error submitting book:", error);
            setSubmissionLoading(false);
            setPersistentMessage('Failed to add book. Please try again.');
        }
    };

    useEffect(() => {
        if (selectedBook) {
            handleSubmit();
        }
    }, [selectedBook]);

    useEffect(() => {
        if (searchTerm === "") {
            setIsLoading(false);
        }
        if (searchTerm !== "") {
            setIsLoading(true);
            timeoutId.current && clearTimeout(timeoutId.current);
            timeoutId.current = setTimeout(async () => {
                try {
                    const results = await searchBooks(searchTerm);
                    setSearchResults(results);
                    setIsLoading(false);
                } catch (error) {
                    console.error(
                        "Error fetching books:",
                        error.response ? error.response.data : error.message
                    );
                    setIsLoading(false);
                }
            }, 2000);
        } else {
            setSearchResults([]);
        }

        return () => clearTimeout(timeoutId.current);
    }, [searchTerm]);

    return (
        <div className="add-modal linko-modal" ref={modalRef}>
        {submissionLoading ? <Loader /> : (
            <>
            <div className="modal-header">
                <h2>Add Book</h2>
                <button className="close-button" onClick={onClose}><IoClose /></button>
            </div>
            <form className="add-book-form">
                <label style={{ width: "100%" }}>
                    <input
                        className="search-books"
                        type="text"
                        placeholder="Search book"
                        ref={inputRef}
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                        onKeyDown={handleEnterKey} 
                        required
                    />
                </label>
            </form>
            <div className="book-search-results">
                {isLoading ? (
                    <div className="search-loader">
                        <Loader/>
                    </div>
                ) : (
                    <>
                    {searchTerm === "" ? (
                        <>
                            <div className="trending-books-header">
                                <p style={{fontSize: "16px", fontWeight: "500", color: "var(--primary-color)", marginBottom: "10px"}}>Trending books on Linko</p>
                            </div>
                            {isTrendingLoading ? (
                                <div className="search-loader">
                                    <Loader/>
                                </div>
                            ) : trendingBooks.length > 0 ? (
                                trendingBooks.map((book) => (
                                    <SlimResourceCard
                                        key={book.id}
                                        id={book.id}
                                        resource={book}
                                        learnHistory={null}
                                        onResourceClick={() => {
                                            setIsExistingBook(true);
                                            setSelectedBook(book.id);
                                        }}
                                    />
                                ))
                            ) : (
                                <div className="no-trending-books">
                                    <p>No trending books found</p>
                                </div>
                            )}
                        </>
                    ) : (
                        searchResults.map((book) =>
                            book.id ? (
                                <SlimResourceCard
                                    key={book.id}
                                    id={book.id}
                                    resource={book}
                                    learnHistory={null}
                                    onResourceClick={() => {
                                        setIsExistingBook(false);
                                        setSelectedBook(book.id);
                                    }}
                                />
                            ) : null
                        )
                    )}
                    </>
                )}
            </div>
            </>
        )}
        </div>
    );
};

export default AddBook;

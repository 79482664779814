import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchMyData, getNotifCount, fetchUserSubject, useWindowSize } from '@linko/shared_utils';
import analytics, { EVENT_TYPES } from '../services/analytics';

export const UserContext = createContext();
export const AuthContext = createContext();
export const AddNoteCardContext = createContext();
export const OnboardingContext = createContext();
export const LoadingContext = createContext();
export const SidebarContext = createContext();

export function AppContextProvider({ children }) {

  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [addNoteCard, setAddNoteCard] = useState(false);
  const [newNoteContent, setNewNoteContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [notifCount, setNotifCount] = useState(0);
  const [newFeedCount, setNewFeedCount] = useState(0);
  const [newRequestCount, setNewRequestCount] = useState(0);
  const [userNoteCount, setUserNoteCount] = useState(null);
  const [userResourceCount, setUserResourceCount] = useState(0);
  const [userSubject, setUserSubject] = useState([]);

  // Authenticated
  const checkAuthStatus = () => {
    const token = localStorage.getItem('access_token');
    return token !== null && token !== '';
  };
  const [isAuthenticated, setIsAuthenticated] = useState(checkAuthStatus());

  const handleWebsiteLogout = () => {
    // Track logout event before clearing user data
    if (userInfo) {
      analytics.trackEvent(EVENT_TYPES.LOGOUT, {
        userId: userInfo.id,
        username: userInfo.username
      });
      
      // Reset user in Amplitude
      analytics.resetUser();
    }
    
    setIsAuthenticated(false);
    navigate('/login');
  };

  useEffect(() => {
    const handleTokenExpired = () => {
      handleWebsiteLogout();
    };

    window.addEventListener('session-expired', handleTokenExpired);
    return () => {
      window.removeEventListener('session-expired', handleTokenExpired);
    };
  }, [handleWebsiteLogout]);

  useEffect(() => {
    const protectedRoutes = ['/login', '/register', '/forget_password', '/'];
    if (isAuthenticated && protectedRoutes.includes(location.pathname)) {
      navigate('/my_linko');
    }
  }, [location, isAuthenticated, navigate]);

  const fetchUserInfo = async () => {
    const data = await fetchMyData();
    setUserInfo(data);
    setUserNoteCount(data.note_count);
    setUserResourceCount(data.resource_count);
    
    // Identify user in Amplitude with their data
    if (data && data.id) {
      analytics.identifyUser(data.id, {
        name: data.name,
        username: data.username,
        email: data.email,
        note_count: data.note_count,
        resource_count: data.resource_count,
        profile_picture: data.profile_picture,
        private_account: data.private_account,
        lastLogin: new Date().toISOString()
      });
    }
  };

  const fetchNotifCount = async () => {
    try {
      const data = await getNotifCount();
      setNotifCount(data.new_likes + data.new_follow_requests);
      setNewRequestCount(data.new_follow_requests);
      setNewFeedCount(data.new_feed);
    } catch (error) {
      console.error('Error fetching notification count:', error);
      
      // Track error in Amplitude
      analytics.trackEvent(EVENT_TYPES.ERROR, {
        errorMessage: 'Error fetching notification count',
        errorDetails: error.message
      });
    }
  };

  const fetchUserSubjectData = async () => {
    const data = await fetchUserSubject();
    setUserSubject(data.userSubject);
    
    // Track user subjects in Amplitude user properties
    if (userInfo && userInfo.id && data.userSubject) {
      analytics.identifyUser(userInfo.id, {
        subject_count: data.userSubject.length,
        subjects: data.userSubject.map(subject => subject.name).join(', ')
      });
    }
  };

  const fetchUserData = async () => {
    setIsLoading(true);
    try {
      await Promise.all([
        fetchUserInfo(),
        fetchUserSubjectData(),
      ]);
    } catch (error) {
      console.error('Error fetching user data:', error);
      
      // Track error in Amplitude
      analytics.trackEvent(EVENT_TYPES.ERROR, {
        errorMessage: 'Error fetching user data',
        errorDetails: error.message
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Track login/authentication state
  useEffect(() => {
    if (isAuthenticated) {
      // This will track login events (when authentication state changes to true)
      analytics.trackEvent(EVENT_TYPES.LOGIN, {
        method: 'session_token',
        timestamp: new Date().toISOString()
      });
      
      fetchUserData();
    } else {
      setIsLoading(false);
    }
  }, [isAuthenticated]);

  const userContextValue = {
    userInfo, 
    setUserInfo, 
    fetchUserInfo, 
    notifCount, 
    setNotifCount, 
    fetchNotifCount, 
    newFeedCount, 
    setNewFeedCount, 
    newRequestCount,
    userSubject,
    setUserSubject,
    userNoteCount,
    setUserNoteCount,
    userResourceCount,
    setUserResourceCount,
    fetchUserSubjectData
  };
  
  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      <AddNoteCardContext.Provider value={{ addNoteCard, setAddNoteCard, newNoteContent, setNewNoteContent }}>
        <UserContext.Provider value={userContextValue}>
          <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, checkAuthStatus, handleWebsiteLogout}}>
            <SidebarProvider>
              {children}
            </SidebarProvider>
          </AuthContext.Provider>
        </UserContext.Provider>
      </AddNoteCardContext.Provider>
    </LoadingContext.Provider>
  );
}

export const SidebarProvider = ({ children }) => {
  const { userInfo } = useContext(UserContext);
  const [showSidebar, setShowSidebar] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width < 1089) {
      setShowSidebar(false);
    } else {
      setShowSidebar(true);
    }
  }, [width]);

  return (
    <SidebarContext.Provider value={{ showSidebar, setShowSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

export default AppContextProvider;
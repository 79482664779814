import React, { createContext, useState, useEffect, useContext } from 'react';
import { fetchResources, fetchBestUnlearned } from '@linko/shared_utils';
import { AuthContext } from './AppContext';

export const SideBarContext = createContext();

export const SideBarContextProvider = ({ children }) => {
    const [inProgressResources, setInProgressResources] = useState([]);
    const [finishedResources, setFinishedResources] = useState([]);
    const [topUnlearnedResources, setTopUnlearnedResources] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { isAuthenticated } = useContext(AuthContext);
    
    const fetchSidebarResources = async (abortController) => {
        if (isLoading) return;

        try {
            setIsLoading(true);
            setFinishedResources([]);
            setInProgressResources([]);
            setTopUnlearnedResources([]);
            const signal = abortController?.signal;
            const fetchPromises = [];

            fetchPromises.push(
                fetchResources(
                    3,
                    0,
                    [],
                    'in_progress',
                    [],
                    [],
                    setInProgressResources,
                    () => {},
                    () => {},
                    signal,
                ),
                fetchResources(
                    3,
                    0,
                    [],
                    'recent',
                    [],
                    [],
                    setFinishedResources,
                    () => {},
                    () => {},
                    signal,
                )
            );

            fetchPromises.push(
                fetchBestUnlearned([], signal).then(response => {
                    if (!abortController?.signal?.aborted) {
                        setTopUnlearnedResources(response.data.slice(0, 3));
                    }
                })
            );

            await Promise.all(fetchPromises);
        } catch (error) {
            if (!abortController?.signal?.aborted) {
                console.error('Error fetching sidebar resources:', error);
            }
        } finally {
            if (!abortController?.signal?.aborted) {
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            const abortController = new AbortController();
            fetchSidebarResources(abortController);
            return () => abortController.abort();
        }
    }, [isAuthenticated]);

    const refreshSidebarResources = async () => {
        const abortController = new AbortController();
        await fetchSidebarResources(abortController);
        return () => abortController.abort();
    };

    const clearSidebarData = () => {
        setInProgressResources([]);
        setFinishedResources([]);
        setTopUnlearnedResources([]);
    };

    return (
        <SideBarContext.Provider value={{
            inProgressResources,
            finishedResources,
            topUnlearnedResources,
            isLoading,
            refreshSidebarResources,
            clearSidebarData
        }}>
            {children}
        </SideBarContext.Provider>
    );
};

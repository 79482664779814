import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { handleLogout } from '@linko/shared_utils';
import { AuthContext, UserContext } from '../../Context/AppContext';
import { SideBarContext } from '../../Context/SideBarContext';
import { FaRegBell, FaRegCommentDots } from "react-icons/fa6";
import { TbSettings, TbUsers, TbBrandChrome } from "react-icons/tb";
import { FiLogOut } from "react-icons/fi";
import { IoLibraryOutline } from "react-icons/io5";
import { FaChrome } from "react-icons/fa";
const NameDropdown = ({ userInfo, notificationCount }) => {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { safeNavigate } = useContext(NavigationPromptContext);
    const location = useLocation();
    const { setIsAuthenticated } = useContext(AuthContext);
    const { setUserInfo } = useContext(UserContext);
    const { clearSidebarData } = useContext(SideBarContext);

    const handleLogoutPress = async () => {
        try {
            await handleLogout();
            setIsAuthenticated(false);
            setUserInfo(null);
            clearSidebarData();
            safeNavigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    useEffect(() => {
        setIsDropdownOpen(false);
    }, [location]);


    return (
        <div 
        className="username-dropdown" 
        onMouseEnter={() => setIsDropdownOpen(true)}
        onMouseLeave={() => setIsDropdownOpen(false)} 
        >
            <button className="navbar-user-name" >
                {userInfo && <span>{userInfo.first_name} {userInfo.last_name}</span>}
            </button>
            {notificationCount > 0 && <div className="notification-badge"></div>}
            {isDropdownOpen && (
                <div className="name-dropdown-menu">
                    {!location.pathname.includes('my_library') && (
                        <div className='dropdown-item setting' onClick={() => safeNavigate('/my_library')}>
                            <IoLibraryOutline/>
                            <p>Library view</p>
                        </div>
                    )}
                    {!userInfo?.private_account && (
                        <div className='dropdown-item setting' onClick={() => safeNavigate(`/notifications/${userInfo.first_name}${userInfo.last_name}`)}>
                            <FaRegBell  />
                            <p>Notifications</p>
                            {notificationCount > 0 && <div className="notification-count">{notificationCount}</div>}
                        </div>
                    )}
                    {!userInfo?.private_account && (
                        <div className='dropdown-item setting' onClick={() => safeNavigate(`/my_friends/${userInfo.first_name}${userInfo.last_name}`)}>
                            <TbUsers  />
                            <p>Friends</p>
                        </div>
                    )}
                    <div className='dropdown-item setting' onClick={() => safeNavigate(`/settings/${userInfo.first_name}${userInfo.last_name}`)}>
                        <TbSettings  />
                        <p>Settings</p>
                    </div>
                    <div className='rx-divider-vertical'></div>
                    <div className='dropdown-item setting' onClick={() => window.open('https://chromewebstore.google.com/detail/hmhpgepblaamekhfocgamfkmdonmnpnj?utm_source=item-share-cb', '_blank')}>
                        <TbBrandChrome  />
                        <p>Web extension</p>
                    </div>
                    <a className='dropdown-item setting' href='https://linko.featurebase.app/' target='_blank'>
                        <FaRegCommentDots  />
                        <p>Feedback</p>
                    </a>
                    <div className="logout" onClick={handleLogoutPress}>
                        <FiLogOut />
                        <p>Logout </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NameDropdown;
import React, { createContext, useContext, useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { IoClose } from "react-icons/io5";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { BsInfoCircle, BsExclamationTriangle } from "react-icons/bs";
import { RiErrorWarningLine } from "react-icons/ri";
import 'react-toastify/dist/ReactToastify.css';

const ToastContext = createContext(null);

export const useToast = () => {
    return useContext(ToastContext);
};

export const ToastProvider = ({ children }) => {
    const [persistentMessage, setPersistentMessage] = useState('');

    const CloseButton = ({ closeToast }) => {
        return (
            <IoClose 
                onClick={closeToast} 
                size={18} 
                style={{ 
                    position: 'absolute', 
                    right: '12px', 
                    top: '50%', 
                    transform: 'translateY(-50%)', 
                    color: 'var(--tertiary-font-color)', 
                    fontWeight: '600',
                    cursor: 'pointer',
                    opacity: 0.7,
                    transition: 'opacity 0.2s ease',
                }}
                onMouseOver={(e) => e.currentTarget.style.opacity = 1}
                onMouseOut={(e) => e.currentTarget.style.opacity = 0.7}
            />
        );
    };
    
    // Default toast with success style
    const addToast = (message, options = {}) => {
        toast(
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IoMdCheckmarkCircleOutline 
                    size={22} 
                    style={{ marginRight: '10px', minWidth: '22px', color: 'var(--secondary-color)' }}
                />
                <span>{message}</span>
            </div>,
            {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                closeButton: <CloseButton />,
                style: {
                    borderRadius: '6px',
                    padding: '12px 16px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
                    marginBottom: '12px',
                    fontFamily: 'inherit',
                    backgroundColor: 'white',
                    fontSize: '14px',
                    fontWeight: '500',
                    color: 'var(--primary-font-color)',
                    width: 'auto',
                    maxWidth: '400px',
                },
                progressStyle: {
                    background: 'linear-gradient(to right, var(--secondary-color), var(--primary-color))',
                },
                ...options
            }
        );
    };
    
    // Info toast variant
    const addInfoToast = (message, options = {}) => {
        toast(
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <BsInfoCircle 
                    size={20} 
                    style={{ marginRight: '10px', minWidth: '20px', color: '#3273dc' }}
                />
                <span>{message}</span>
            </div>,
            {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                closeButton: <CloseButton />,
                style: {
                    borderRadius: '6px',
                    padding: '12px 16px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
                    borderLeft: '4px solid var(--info-color)',
                    marginBottom: '12px',
                    fontFamily: 'inherit',
                    backgroundColor: 'white',
                    fontSize: '14px',
                    fontWeight: '500',
                    color: 'var(--primary-font-color)',
                    width: 'auto',
                    maxWidth: '400px',
                },
                progressStyle: {
                    background: 'linear-gradient(to right, var(--secondary-color), var(--primary-color))',
                },
                ...options
            }
        );
    };
    
    // Warning toast variant
    const addWarningToast = (message, options = {}) => {
        toast(
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <BsExclamationTriangle 
                    size={20} 
                    style={{ marginRight: '10px', minWidth: '20px', color: '#FFD43B' }}
                />
                <span>{message}</span>
            </div>,
            {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                closeButton: <CloseButton />,
                style: {
                    borderRadius: '6px',
                    padding: '12px 16px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
                    borderLeft: '4px solid #FFD43B',
                    marginBottom: '12px',
                    fontFamily: 'inherit',
                    backgroundColor: 'white',
                    fontSize: '14px',
                    fontWeight: '500',
                    color: 'var(--primary-font-color)',
                    width: 'auto',
                    maxWidth: '400px',
                },
                progressStyle: {
                    background: 'linear-gradient(to right, #FFD43B, #F5A623)',
                },
                ...options
            }
        );
    };
    
    // Error toast variant
    const addErrorToast = (message, options = {}) => {
        toast(
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <RiErrorWarningLine 
                    size={22} 
                    style={{ marginRight: '10px', minWidth: '22px', color: '#fb4242' }}
                />
                <span>{message}</span>
            </div>,
            {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                closeButton: <CloseButton />,
                style: {
                    borderRadius: '6px',
                    padding: '12px 16px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.12)',
                    borderLeft: '4px solid #fb4242',
                    marginBottom: '12px',
                    fontFamily: 'inherit',
                    backgroundColor: 'white',
                    fontSize: '14px',
                    fontWeight: '500',
                    color: 'var(--primary-font-color)',
                    width: 'auto',
                    maxWidth: '400px',
                },
                progressStyle: {
                    background: 'linear-gradient(to right, #fb4242, #d62424)',
                },
                ...options
            }
        );
    };

    useEffect(() => {
        if (persistentMessage) {
            addToast(persistentMessage);
            setPersistentMessage('');  // Reset persistent message
        }
    }, [persistentMessage]);

    return (
        <ToastContext.Provider value={{ 
            addToast, 
            addInfoToast, 
            addWarningToast, 
            addErrorToast, 
            setPersistentMessage 
        }}>
            {children}
            <ToastContainer
                limit={3}
                newestOnTop
                pauseOnFocusLoss={false}
            />
        </ToastContext.Provider>
    );
};
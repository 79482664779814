import React, {useState, useEffect, useCallback} from 'react';
import Loader from '../Loader';
import { fetchResources } from '@linko/shared_utils';
import SlimResourceCard from '../ResourceCard/SlimResourceCard';


const LinkResourceSuggested = ({ setSelectedResource, currentResource, handleUnlink }) => {
    
    const [resources, setResources] = useState([]);
    const [isFetchingMoreResources, setIsFetchingMoreResources] = useState(false);
    const [hasMoreResources, setHasMoreResources] = useState(true);
    
    const limit = 10;

    const fetchMyResources = useCallback(async () => {
        if (!isFetchingMoreResources || !hasMoreResources) return;
        
        try {
            await fetchResources(
                limit,
                resources.length,
                [],
                null,
                [],
                resources,
                setResources,
                setHasMoreResources, 
                setIsFetchingMoreResources,
                null,
            );
        } catch (error) {
            console.error('Failed to fetch resources:', error);
        } finally {
            setIsFetchingMoreResources(false);
        }
    }, [isFetchingMoreResources, hasMoreResources, resources]);

    useEffect(() => {
        setIsFetchingMoreResources(true);
    }, []);
    
    useEffect(() => {
        fetchMyResources();
    }, [isFetchingMoreResources]);

    const handleScroll = useCallback((event) => {
        const target = event.target;
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            setIsFetchingMoreResources(true);
        }
    }, []);

    useEffect(() => {
        const tabContainer = document.querySelector('.suggested-resources');
        if (tabContainer) {
            tabContainer.addEventListener('scroll', handleScroll);
            return () => tabContainer.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    const renderResource = (resource, isCurrentResource = false) => (
        <div key={resource.id} className={isCurrentResource ? 'current-resource' : ''}>
            {isCurrentResource ? (
                <div className="current-resource-container" style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: '4px',
                    columnGap: '10px'
                }}>
                    <SlimResourceCard
                        id={null}
                        resource={resource}
                        onResourceClick={() => setSelectedResource(resource)}
                        onUnlink={handleUnlink}
                    />
                </div>
            ) : (
                <SlimResourceCard
                    id={null}
                    resource={resource.resource}
                    onResourceClick={() => setSelectedResource(resource.resource)}
                />
            )}
        </div>
    );

    return (
        <div className='suggested-resources'>
            {currentResource && renderResource(currentResource, true)}
            {resources.filter(r => r.resource.id !== currentResource?.id).map(resource => renderResource(resource))}
            {isFetchingMoreResources && <Loader/>}
        </div>
    );
};

export default LinkResourceSuggested;

import React, { useEffect, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext, AuthContext, LoadingContext, SidebarContext } from '../../Context/AppContext';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import AddToLinko from './AddToLinko';
import Search from './Search';
import NameDropdown from './NameDropdown';
import ScrollToTopLink from '../ScrollToTopLink';
import LinkoLogo from '../Logo';

function NavBar() {
  const { userInfo, notifCount, userResourceCount, userNoteCount } = useContext(UserContext);
  const { checkAuthStatus, isAuthenticated } = useContext(AuthContext);
  const { isLoading } = useContext(LoadingContext);
  const { showSidebar, setShowSidebar } = useContext(SidebarContext);
  const location = useLocation();
  const { safeNavigate } = useContext(NavigationPromptContext);
  const notificationCount = notifCount;
  const [navbarBackground, setNavbarBackground] = useState('#f7f9f8');

  // Check authentication status on mount
  useEffect(() => {
    checkAuthStatus();
  }, []);

  // Update background color when location changes
  useEffect(() => {
      setNavbarBackground(location.pathname === '/' ? 'transparent' : '#f7f9f8');
  }, [location.pathname]);

  // Handle Scroll
  useEffect(() => {
    let lastScrollTop = 0;
    const handleScroll = () => {
      const navbar = document.getElementById('navbar-container');
      const path = location.pathname;
      const noShadowPaths = ['/my_linko', '/r/', '/s/', '/profile/'];
      const shouldApplyShadow = !noShadowPaths.some(noShadowPath => path.startsWith(noShadowPath));
      if (window.scrollY > 0 && shouldApplyShadow) {
        navbar?.classList?.add('navbar-shadow');
      } else {
        navbar?.classList?.remove('navbar-shadow');
      }
      // Hide/show on scroll
      const currentScroll = window.scrollY || document.documentElement.scrollTop;
      if (navbar) {
        if (currentScroll > lastScrollTop) {
          // Scrolling down
          navbar.style.top = '-70px'; // Adjust based on navbar height
        } else {
          // Scrolling up
          navbar.style.top = '0';
        }
        lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // For Mobile Safari
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [location]);

  if (location.pathname === '/') {
    return null;
  } 

  if (isLoading) {
    return (
      <div id="navbar-container" style={{ backgroundColor: navbarBackground }}>
        <nav className="linko-navbar" role="navigation" aria-label="main navigation" style={{ backgroundColor: navbarBackground }}>
          <div className="linko-navbar-brand" id='navbar-brand'>
            <LinkoLogo />
          </div>
          {/* Placeholder for the rest of the navbar */}
          <div className="linko-navbar-end" style={{ visibility: 'hidden' }}>
            {/* Hidden placeholders to maintain layout */}
          </div>
        </nav>
      </div>
    );
  }

  return (
    <div id="navbar-container" style={{ backgroundColor: navbarBackground }}>
      <nav className="linko-navbar" role="navigation" aria-label="main navigation">
        <div className="linko-navbar-brand" id='navbar-brand' onClick={() => safeNavigate('/my_linko')}>
          <LinkoLogo onClick={() => safeNavigate('/my_linko')} />
        </div>
        {isAuthenticated && (userResourceCount + userNoteCount > 0) && <Search />}
        {isAuthenticated && userInfo ? (
          <div id='navbar-end-menu'>
            <div className="linko-navbar-end">
              <AddToLinko /> 
              <NameDropdown 
                userInfo={userInfo} 
                notificationCount={notificationCount}
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
              />
            </div>
          </div>
        ) : (!location.pathname.startsWith('/login') && !location.pathname.startsWith('/register')) && (
          <div className='landing-page-actions'>
            <ScrollToTopLink to='/login' className='login-button'>Log in</ScrollToTopLink>
          </div>
        )}
      </nav>
    </div>
  );
}

export default NavBar;

import axios from '../Middleware/axios';

export const forgetPassword = async (email) => {
    try {
        const response = await axios.post('/api/auth/forgot_password/', {
            email: email.toLowerCase(),
        });
        return response;
    } catch (error) {
        console.error('Forgot password error:', error);
        throw error;
    }
};

export const resetPassword = async (newPassword, uidb64, token) => {
    try {
        // Only secure method with token verification is allowed
        const requestData = {
            uidb64: uidb64,
            token: token,
            new_password: newPassword
        };
        
        const response = await axios.post('/api/auth/reset_password/', requestData);
        return response.data;
    } catch (error) {
        console.error('Reset password error:', error);
        throw error;
    }
};
import axios from '../Middleware/axios';
import storageUtils from '../storageUtils';

const getAuthHeaders = async () => {
    const accessToken = await storageUtils.getItem('access_token');
    if (!accessToken) {
        throw new Error('No access token found');
    }
    return { Authorization: `Bearer ${accessToken}` };
};

export const getNotifCount = async () => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get('/api/notification/new/', { headers });
        return response.data;
    } catch (error) {
        console.error('Error fetching notification count:', error);
        throw error;
    }
};

export const getNotif = async () => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get('/api/notification/', { headers });
        return response.data;
    } catch (error) {
        console.error('Error fetching notifications:', error);
        throw error;
    }
};

export const connectToNotificationSSE = async () => {

    try {
        const headers = await getAuthHeaders();
        const response = await axios.get('/api/notification/new/', { headers });
        return response.data;
    } catch (error) {
        console.error('Error initializing EventSource:', error);
        throw error;
    }
}
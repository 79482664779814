import React from 'react';
import { IoClose } from "react-icons/io5";

/**
 * 共享的Beta码输入模态框组件
 */
const BetaCodeModal = ({
  betaCode,
  setBetaCode,
  googleLoginState,
  onCancel,
  onSubmit,
  isLoading
}) => {
  return (
    <div className="modal-overlay">
      <div className="linko-modal">
        <div className="modal-header">
          <h2>Enter an Invitation Code</h2>
          <button 
            className="close-button" 
            onClick={onCancel}
          >
            <IoClose />
          </button>
        </div>
        <div className="modal-body">
          <div className="input-group">
            <label htmlFor="betaCode">INVITATION CODE</label>
            <input
              type="text"
              id="betaCode"
              value={betaCode}
              onChange={(e) => setBetaCode(e.target.value)}
              placeholder="Enter invitation code"
              className={googleLoginState.error ? 'error' : ''}
              disabled={isLoading}
            />
          </div>
          {googleLoginState.error && (
            <p className="error-notice" style={{color:'red'}}>
              {googleLoginState.error}
            </p>
          )}
        </div>
        <div className="modal-footer">
          <button 
            className="linko-button linko-button--cancel" 
            onClick={onCancel}
            disabled={isLoading}
          >
            Cancel
          </button>
          <button 
            className={`linko-button linko-button--primary ${
              isLoading ? 'loading' : ''
            }`}
            onClick={onSubmit}
            disabled={isLoading}
          >
            {isLoading ? 'Verifying...' : 'Submit'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BetaCodeModal; 
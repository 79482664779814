import React, { useContext } from 'react';
import { NavigationPromptContext } from '../Context/NavigationPromptContext';
import { FaArrowLeft } from "react-icons/fa6";

const BackButton = () => {
  const { safeNavigate, hasUnsavedChanges } = useContext(NavigationPromptContext);

  const handleClick = () => {
    try {
      safeNavigate(-1);
    } catch (error) {
      console.error('BackButton: Navigation error:', error);
    }
  };

  return (
    <div className='back-button-wrapper'>
      <button 
        onClick={handleClick} 
        className='linko-button linko-button--tertiary back-button' 
        disabled={false}
        title={hasUnsavedChanges ? 'You have unsaved changes' : 'Go back'}
      >
        <FaArrowLeft size={24}/>
      </button>
    </div>  
  );
};

export default BackButton;
import React, {useContext} from "react";
import { NavigationPromptContext } from "../../Context/NavigationPromptContext";
import CustomLink from "../CustomLink";

function HalfFooter() {
    const { safeNavigate } = useContext(NavigationPromptContext);

    return (
        <div id="half-footer">
            <a href='https://linko.featurebase.app/' target='_blank' className='footer-link'>Feedback</a>
            {/* <CustomLink to="https://linko.featurebase.app/changelog" className='footer-link'>Changelog</CustomLink> */}
            <a href='/term_of_service' target='_blank' className='footer-link'>Terms</a>
            <a href='/privacy_policy' target='_blank' className='footer-link'>Privacy</a>
        </div>
    );
}

export default HalfFooter;

import React, { useState, useEffect, useContext } from 'react';
import { searchAuthorBooks, cleanAuthorName, postNewResource, postResourceAndLearn } from '@linko/shared_utils';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { HomePageContext } from '../../Context/HomePageContext';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { FiBookOpen } from "react-icons/fi";
import { useToast } from '../../Components/ToastNotification';
import SlimResourceCard from '../../Components/ResourceCard/SlimResourceCard';

const BookOverview = ({ title, description, author, setTab, embeddable, authorInfo }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [otherBooks, setOtherBooks] = useState([]);
  const [showFullAuthorInfo, setShowFullAuthorInfo] = useState(false);
  const [truncatedDescription, setTruncatedDescription] = useState('');
  const [truncatedAuthorInfo, setTruncatedAuthorInfo] = useState('');
  const { setPersistentMessage } = useToast();
  const { safeNavigate } = useContext(NavigationPromptContext);
  const { addResourceToLibrary } = useContext(HomePageContext);
  const [isHTML, setIsHTML] = useState(false);

  const getFirstAuthor = (authorString) => {
    if (!authorString) return '';
    return authorString.split(',')[0].trim();
  };

  useEffect(() => {
    if (!author) return;
    const firstAuthor = getFirstAuthor(author);
    if (firstAuthor) {
      searchAuthorBooks(title, firstAuthor).then(results => setOtherBooks(results));
    }
  }, [title, author]);

  useEffect(() => {
    setIsHTML(/<\/?[a-z][\s\S]*>/i.test(description));
  }, [description]);

  useEffect(() => {
    if (description) {
      setTruncatedDescription(
        description.length > 300
          ? description.substring(0, 300) + '...'
          : description
      );
    }
  }, [description]);

  useEffect(() => {
    if (authorInfo?.summary) {
      setTruncatedAuthorInfo(
        authorInfo.summary.length > 300
          ? authorInfo.summary.substring(0, 300) + '...'
          : authorInfo.summary
      );
    }
  }, [authorInfo]);


  const handleBookClick = async (bookId) => {
    const response = await postNewResource(bookId);
    const newResourceId = response.data.id;
    if (response.status === 201) {
      safeNavigate(`/r/${newResourceId}`);
    }
  };

  const handleAddToLibrary = async (bookId) => {
    if (!bookId) {
      return;
    }
    const response = await postResourceAndLearn(bookId, null);
    if (response.status === 201) {
      addResourceToLibrary(response.data.resource);
      setPersistentMessage('Resource added to your library!');
      safeNavigate(`/r/${response.data.resource.resource.id}`);
    }
  };

  const renderDescription = (content) => {
    if (isHTML) {
      return <div dangerouslySetInnerHTML={{ __html: content }} />;
    } else {
      return content.split('\n').map((line, index) => (
        <p key={index} style={{ marginBottom: '1em' }}>{line}</p>
      ));
    }
  };

  const renderAuthorInfo = (content) => {
    if (!content) return null;
    
    const lines = content.split('\n');
    return lines.map((line, index) => (
      <p key={index} style={{ marginBottom: index === lines.length - 1 ? '0' : '0.5em' }}>
        {line}
        {index === lines.length - 1 && authorInfo.url && (
          <> 
            {' '}
            <a href={authorInfo.url} target="_blank" rel="noopener noreferrer" style={{color:'var(--primary-color)', textDecoration:'none'}}>
               Wikipedia
            </a>
          </>
        )}
      </p>
    ));
  };

  return (
    <div className="resource-book-overview">
      {embeddable && 
        <button 
          className='linko-button linko-button--secondary' 
          onClick={() => setTab('Preview')} 
          style={{padding:'8px', marginBottom:'10px'}}
        >
          <FiBookOpen size={14} style={{marginTop:'3px'}}/>Preview book
        </button>
      }
      {description !== null && description !== '' && 
        <div className="book-description" style={{marginBottom: '10px'}}>
          <div className='subject-rel-title'>Description</div>
          <div style={{color: 'var(--secondary-font-color)'}}>
            {showFullDescription
              ? renderDescription(description)
              : renderDescription(truncatedDescription)
            }
          </div>
          {description?.length > 300 && 
            <button onClick={() => setShowFullDescription(!showFullDescription)} className='note-expand-button' style={{padding:'5px 0'}}>
              {showFullDescription ? 'Show less' : 'Show more'}
              {showFullDescription ? <FaAngleUp style={{marginLeft: '5px', marginTop:'0px'}} /> : <FaAngleDown style={{marginLeft: '5px', marginTop:'0px'}} />} 
            </button>
          }
        </div>
      }
      {authorInfo && authorInfo?.summary !== null && authorInfo?.summary !== '' && 
        <div style={{marginBottom:'10px'}}>
          <div className='subject-rel-title'>About the author</div>
          <div style={{color: 'var(--secondary-font-color)'}}>
            {showFullAuthorInfo
              ? renderAuthorInfo(authorInfo.summary)
              : renderAuthorInfo(truncatedAuthorInfo)
            }
            {authorInfo.summary?.length > 300 && 
              <button onClick={() => setShowFullAuthorInfo(!showFullAuthorInfo)} className='note-expand-button' style={{padding:'5px 0'}}>
                  {showFullAuthorInfo ? 'Show less' : 'Show more'}
                  {showFullAuthorInfo ? <FaAngleUp style={{marginLeft: '5px', marginTop:'0px'}} /> : <FaAngleDown style={{marginLeft: '5px', marginTop:'0px'}} />} 
              </button>
            }
          </div>
        </div>
      }
      {otherBooks && otherBooks.length > 0 && (
        <div className="other-books">
          <div className='subject-rel-title'>Other books by {cleanAuthorName(author)}</div>
          <div style={{display:'flex', flexWrap:'wrap', gap:'10px'}}>
          {otherBooks.slice(0, 6).map((book, index) => (
            <div style={{width:'330px'}} key={index}>
            <SlimResourceCard
              id={null}
              resource={{
                ...book,
                type: 'bo'
              }}
              learnHistory={null}
              onResourceClick={() => handleBookClick(book.id)}
              onAddToLibrary={() => handleAddToLibrary(book.id)}
              />
            </div>
            ))}
          </div>  
        </div>
      )}
    </div>
  );
};

export default BookOverview;
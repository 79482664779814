import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../Context/AppContext';
import { useToast } from '../../Components/ToastNotification';
import { putUserData, forgetPassword } from '@linko/shared_utils';
import { IoClose } from "react-icons/io5";
import emailjs from '@emailjs/browser';

export const NameChangeModal = ({ isOpen, onClose }) => {
    const { userInfo, fetchUserInfo } = useContext(UserContext);
    const { addToast } = useToast();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    useEffect(() => {
        if (isOpen && userInfo) {
        setFirstName(userInfo.first_name || '');
        setLastName(userInfo.last_name || '');
        }
    }, [isOpen, userInfo]);

    const handleNameChange = async (e) => {
        e.preventDefault();
        if (firstName === '' || lastName === '') {
            window.alert('Name cannot be empty');
            setFirstName(userInfo.first_name);
            setLastName(userInfo.last_name);
            return;
        }
        if (firstName === userInfo.first_name && lastName === userInfo.last_name) {
            onClose();
            return;
        }
        const response = await putUserData({first_name: firstName, last_name: lastName});
        if (response.status === 200) {
            addToast('Name updated!', {appearance: 'success'});
            fetchUserInfo();
            onClose();
        } else {
            window.alert('Failed to update name');
        }
    }

  if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="linko-modal" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <h2>Name</h2>
                    <button className="close-button" onClick={onClose}><IoClose /></button>
                </div>
                <div className="modal-body">
                    <div className="input-group">
                        <label htmlFor="firstName">FIRST NAME</label>
                        <input
                        type="text"
                        id="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="lastName">LAST NAME</label>
                        <input
                        type="text"
                        id="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="linko-button linko-button--cancel" onClick={onClose}>Cancel</button>
                    <button className="linko-button linko-button--primary" onClick={handleNameChange}>Save</button>
                </div>
            </div>
        </div>
    );
};

export const ResetPasswordModal = ({ isOpen, onClose }) => {
    const { userInfo } = useContext(UserContext);
    const { addToast } = useToast();

    const handleSubmit = async () => {        
        try {
          const response = await forgetPassword(userInfo?.email);
          if (response.status === 200) {
            addToast('Reset password link sent. Please check your email.', {appearance: 'success'});
            onClose()
          }
        } catch (error) {
            window.alert('An error occurred while sending the reset password link. Please try again later.');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="linko-modal" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <h2>Get a reset link</h2>
                    <button className="close-button" onClick={onClose}><IoClose /></button>
                </div>
                <div className="modal-body">
                    <div className="input-group">
                        <label htmlFor="email">EMAIL</label>
                        <div className="email">
                            {userInfo?.email}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="linko-button linko-button--cancel" onClick={onClose}>Cancel</button>
                    <button className="linko-button linko-button--primary" onClick={handleSubmit}>Send</button>
                </div>
            </div>
        </div>
    )
}

export const WaitlistModal = ({ isOpen, onClose }) => {
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const { addToast } = useToast();

    const validateEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        setIsValidEmail(validateEmail(newEmail));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email || !isValidEmail) {
            window.alert('Please enter a valid email address');
            return;
        }

        try {
            const templateParams = {
                to_email: 'ttsilvia15@gmail.com',
                from_email: email,
                message: `New waitlist signup from: ${email}`,
            };

            const response = await emailjs.send(
                'service_6vpkdpb', 
                'template_dh8yn1o',
                templateParams,
                'TAcvdzMgKlIylaMCn'
            );

            if (response.status === 200) {
                addToast('Thank you for joining the waitlist!', {appearance: 'success'});
                setEmail('');
                onClose();
            } else {
                throw new Error('Failed to submit');
            }
        } catch (error) {
            window.alert('An error occurred. Please try again later.');
            console.error('EmailJS Error:', error);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="linko-modal" onClick={e => e.stopPropagation()}>
                <div className="modal-body" style={{minHeight: 'fit-content'}}>
                    <div className="input-group">
                        <label htmlFor="waitlistEmail">EMAIL</label>
                        <input
                            type="email"
                            id="waitlistEmail"
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="linko-button linko-button--cancel" onClick={onClose}>Cancel</button>
                    <button 
                        className={`linko-button linko-button--primary ${!isValidEmail ? 'linko-button--primary--disabled' : ''}`} 
                        onClick={handleSubmit}
                        disabled={!isValidEmail}
                    >Join waitlist</button>
                </div>
            </div>
        </div>
    );
};
import React, { useContext } from 'react';
import { NavigationPromptContext } from "../../Context/NavigationPromptContext";
import { useToast } from '../../Components/ToastNotification';
import { postResourceToMine } from '@linko/shared_utils';
import SlimResourceCard from '../../Components/ResourceCard/SlimResourceCard';
import { HomePageContext } from '../../Context/HomePageContext';

const RecommendResources = ({resourceId, recommendations}) => {

    const { safeNavigate } = useContext(NavigationPromptContext);
    const { setPersistentMessage } = useToast();
    const { addResourceToLibrary } = useContext(HomePageContext);

    const handleAddToLibrary = async (id) => {
        const response = await postResourceToMine(id);
        if (response.status === 201) {
          setPersistentMessage('Resource added to your library!');
          addResourceToLibrary(response.data.resource);
          safeNavigate(`/r/${response.data.resource.resource.id}`);
        }
    };  

    if (!recommendations || recommendations.length === 0) {
        return null;
    }

    return (
        <div className="other-books" style={{marginTop: '0px'}}>
            <div className='subject-rel-title'>For you</div>
            <div style={{display:'flex', flexWrap:'wrap', gap:'10px'}}>
            {Array.isArray(recommendations) && recommendations
                .filter(resource => resource.id !== resourceId)
                .slice(0, 6)
                .map((resource, index) => (
                    <div style={{width:'330px'}} key={index}>
                        <SlimResourceCard
                            id={resource.id}
                            resource={resource}
                            learnHistory={null}
                            onAddToLibrary={() => handleAddToLibrary(resource.id)}
                        />
                    </div>
                ))}
            </div>  
        </div>
    )
}

export default RecommendResources;
import React, { useState , useEffect, useRef, useContext} from 'react';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { HomePageContext } from '../../Context/HomePageContext';
import { IoClose } from "react-icons/io5";
import Loader from '../Loader';
import { useToast } from '../ToastNotification';
import { postResourceAndLearn } from '@linko/shared_utils';


const AddLink = ({setSelectedResource, onClose}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState('');
  const { safeNavigate } = useContext(NavigationPromptContext);
  const { addToast, setPersistentMessage } = useToast(); 
  const { addResourceToLibrary } = useContext(HomePageContext);
  const inputRef = useRef();
  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [modalRef, onClose]);

  useEffect(() => {
    inputRef.current && inputRef.current.focus(); 
  }, []);

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
    } catch (_) {
      return false;  
    }

    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!isValidUrl(link)) {
      alert('Invalid URL');
      return;
    }

    const response = await postResourceAndLearn(null, link);

    if (response.status === 201) {
      const newResource = response.data.resource;
      setIsLoading(false);
      onClose();
      addResourceToLibrary(newResource);
      if (setSelectedResource) {
        setSelectedResource(newResource.resource);
      } else {
        safeNavigate(`/r/${newResource.resource.id}`);
      }
      setPersistentMessage('Link added successfully!');
    } else {
      setIsLoading(false);
      addToast('Failed to add link, please try again.');
    }
  };


  return (
    <div className='add-modal linko-modal' ref={modalRef}>
    {isLoading ? <Loader /> : (
      <>
      <div className='modal-header'>
        <h2>Add Online Resource</h2>
        <button className="close-button" onClick={onClose}><IoClose /></button>
      </div>
      <div className='add-link-tip'>
        <p>Article, Video, Online Course, Podcast, etc.</p>
      </div>
      <form onSubmit={handleSubmit} className='add-book-form'>
        <input className='search-books' type="text" ref={inputRef} value={link} onChange={handleLinkChange} required placeholder='Paste link here'/>
        <button className={`linko-button linko-button--tertiary ${!isValidUrl(link) ? 'linko-button--tertiary--disabled' : ''}`} disabled={!isValidUrl(link)} type="submit" >Add</button>
      </form>
      </>
    )}
    </div>
  );
};

export default AddLink;

import React from 'react';

import { useWindowSize } from '@linko/shared_utils';

const SubjectPageHeader = ({
    subjectName, 
    newSubjectName,
    publicField, 
    setIsModalOpen,
    noteCount,
    resourceCount,
}) => {

    const { width } = useWindowSize();
    
    return (
        <div 
            className='subject-page-header linko-card' 
            style={{minHeight: 'fit-content'}}
            onClick={() => width <= 1089 && setIsModalOpen(true)}
        >
            <h1 
                className='subject-name' 
                style={{color: 'var(--primary-font-color)'}}
            >
            {newSubjectName || subjectName}</h1>
            <div className='subject-learn-count'>
                { noteCount > 0 && <span>{noteCount} note{noteCount > 1 ? 's':''}</span>}
                { resourceCount > 0 && <span>{resourceCount} resource{resourceCount > 1 ? 's':''}</span>}
            </div>
        </div>
    );
};

export default SubjectPageHeader;
import axios from "../Middleware/axios.js";
import storageUtils from "../storageUtils.js";

export const handleLogout = async () => {
    try {
        const refreshToken = await storageUtils.getItem("refresh_token");
        await axios.post("/api/auth/logout/", 
            { refresh_token: refreshToken },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${await storageUtils.getItem("access_token")}`
                },
            }
        );
        delete axios.defaults.headers.common["Authorization"];
        await storageUtils.clear();
        
        // Attempt to communicate with the Chrome extension to clear its storage
        if (typeof window !== 'undefined' && window.chrome && window.chrome.runtime) {
            try {
                // Get all installed extensions
                const extensionInfos = await getAllInstalledLinkoExtensions();
                
                // Send message to each Linko extension
                for (const extensionId of extensionInfos) {
                    try {
                        window.chrome.runtime.sendMessage(
                            extensionId,
                            { type: "CLEAR_EXTENSION_STORAGE" }
                        );
                    } catch (extensionError) {
                        console.log(`Failed to clear extension ${extensionId} storage:`, extensionError);
                    }
                }
            } catch (extensionError) {
                console.log("Extension communication failed or not installed", extensionError);
            }
        }
    } catch (e) {
        console.error("Logout error:", e);
        delete axios.defaults.headers.common["Authorization"];
        await storageUtils.removeItem("access_token");
        await storageUtils.removeItem("refresh_token");
        
        // Still try to clear extension storage even if logout API fails
        if (typeof window !== 'undefined' && window.chrome && window.chrome.runtime) {
            try {
                const extensionInfos = await getAllInstalledLinkoExtensions();
                for (const extensionId of extensionInfos) {
                    try {
                        window.chrome.runtime.sendMessage(
                            extensionId,
                            { type: "CLEAR_EXTENSION_STORAGE" }
                        );
                    } catch (extensionError) { }
                }
            } catch (extensionError) {}
        }
    }
}

// Helper function to identify Linko Chrome extensions
// This uses the fact that extensions can detect each other when they
// have the same extension ID and are from the same publisher
async function getAllInstalledLinkoExtensions() {
    if (typeof window === 'undefined' || !window.chrome || !window.chrome.runtime) {
        return [];
    }
    
    // Return a hardcoded list of known Linko extension IDs
    // In a production environment, you would maintain a list of your official extension IDs
    // For now we'll use a generic approach to try to communicate with any extension that might be ours
    
    // The current extension ID is always available via chrome.runtime.id
    const extensionIds = [];
    
    try {
        // Try messaging our own extension to see if it's installed
        // This works because Chrome extensions from the same publisher share an ID
        const extensionId = window.chrome.runtime.id;
        if (extensionId) {
            extensionIds.push(extensionId);
        }
    } catch (e) {
        // If we can't get the ID, we'll try a more generic approach
    }
    
    // Additional fallback for the production extension ID if known
    // You would add your production extension ID here
    // extensionIds.push("your-production-extension-id");
    
    return extensionIds;
}   
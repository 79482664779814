import React, { useState, useEffect } from 'react';
import { BiSolidLike } from "react-icons/bi";
import CustomAvatar from '../CustomAvatar';
import { profilePage } from '@linko/shared_utils';

const NoteLikeModal = ({ isOpen, data, onOk, onCancel }) => {
    const [users, setUsers] = useState(data.data);
    const [count, setCount] = useState(parseInt(data.count));
    const [type, setType] = useState(data.type);

    useEffect(() => {
        if (isOpen) {
            setUsers(data.data);
            setCount(parseInt(data.count));
            setType(data.type);
        }
    }, [isOpen, data]);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onCancel}>
            <div className="info-modal-content learned-users-modal" onClick={(e) => e.stopPropagation()}>
                <div className='user-section'>
                    <div className='user-list'>
                        {users?.map((item) => (
                            <div className='user-item' key={item.id}>
                                <div style={{display:'flex', alignItems:'center', columnGap:'10px'}}>
                                    <CustomAvatar size={25} firstName={item.first_name} lastName={item.last_name} />
                                    <a 
                                        href={profilePage(item.id)}
                                        className='user-name'
                                        style={{margin:'0', textDecoration: 'none'}}
                                        onClick={() => onCancel()} // Close modal after clicking
                                    >
                                        {item.first_name} {item.last_name}
                                    </a>
                                </div>
                                {type === 'noteLikes' ? <BiSolidLike color='#cecece' size={16} style={{marginLeft: 'auto'}}/> : null}
                            </div>
                        ))}
                        { type === 'learnedUsers' && count > 0  && 
                            <div className='private-learned-user-count'>
                                And {count} private user{count > 1 ? 's' : ''}.
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NoteLikeModal;

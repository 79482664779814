import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { sendFollowRequest } from '@linko/shared_utils';
import { AuthContext, UserContext } from '../../Context/AppContext';
import { useToast } from '../../Components/ToastNotification';
import CustomAvatar from '../../Components/CustomAvatar';

const UserModal = ({ isOpen, onClose, users, title, followings }) => {
    const { isAuthenticated } = useContext(AuthContext);
    const { userInfo } = useContext(UserContext);
    const navigate = useNavigate();
    const { addToast } = useToast();

  const goToProfile = (id) => {
    navigate(`/profile/${id}`);
  };

  const handleFollow = async (userId) => {
    try {
      await sendFollowRequest(userId);
      addToast('Follow request sent', { type: 'success' });
    } catch (error) {
      console.error('Error sending follow request:', error);
    }
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={onClose}>
      <div className="info-modal-content learned-users-modal" onClick={(e) => e.stopPropagation()}>
        <div className='modal-header'>
          <h2>{title}</h2>
        </div>
        <div className='modal-body'>
          {users?.map((user) => {
            const isFollowing = followings.some(following => following.id === user.id);
            return (
              <div className='user-section'>
                <div className='user-list'>
                  <div key={user.id} className='user-item'>
                    <CustomAvatar size={25} firstName={user.first_name} lastName={user.last_name}/>
                    <div className="user-info" onClick={() => {
                      goToProfile(user.id);
                      onClose();
                    }}>
                      <div className="user-name hover-to-green">{user.first_name} {user.last_name}</div>
                    </div>
                    {isAuthenticated && !isFollowing && user.id !== userInfo?.id &&
                      <button className='linko-button linko-button--secondary' onClick={() => handleFollow(user.id)} style={{marginLeft:'auto'}}>
                        Follow
                      </button>
                    }
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default UserModal;

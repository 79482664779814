import React, { useEffect } from 'react';
import { FaLightbulb, FaUserFriends, FaLock, FaRocket } from 'react-icons/fa';
import { CEOAvatar, DeveloperAvatar, DesignerAvatar, EngineerAvatar } from '../../Components/TeamAvatars';

const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "About Us | Linko";
    }, []);

    return (
        <div className="about-us-page">
            <section className="hero-section">
                <div className="container">
                    <h1>About Linko</h1>
                    <p className="subtitle">We're on a mission to transform how people manage and connect their knowledge.</p>
                </div>
            </section>

            <section className="our-story">
                <div className="container">
                    <h2>Our Story</h2>
                    <div className="story-content">
                        <div className="story-image">
                            {/* <img src={Logo} alt="Linko Logo" /> */}
                        </div>
                        <div className="story-text">
                            <p>Linko was born from a simple observation: despite the abundance of information at our fingertips, we struggle to effectively organize and connect what we learn.</p>
                            <p>As lifelong learners, we experienced firsthand the frustration of knowledge that remains fragmented and disconnected despite our best efforts to capture it.</p>
                            <p>We started Linko in 2023 with a clear vision: to create a system that doesn't just store information, but actively helps people build connected knowledge networks that strengthen over time.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mission-values">
                <div className="container">
                    <div className="mission">
                        <h2>Our Mission</h2>
                        <p>To empower every lifelong learner to capture, connect, and apply knowledge more effectively, transforming how we learn and share insights in the digital age.</p>
                    </div>
                    
                    <div className="values">
                        <h2>Our Values</h2>
                        <div className="values-grid">
                            <div className="value-card">
                                <div className="icon">
                                    <FaLightbulb />
                                </div>
                                <h3>Thoughtful Innovation</h3>
                                <p>We create tools that solve real problems, not just flashy features that look good in demos.</p>
                            </div>
                            
                            <div className="value-card">
                                <div className="icon">
                                    <FaUserFriends />
                                </div>
                                <h3>User-Centered</h3>
                                <p>Our users' success is our success. We listen closely and build for their actual needs.</p>
                            </div>
                            
                            <div className="value-card">
                                <div className="icon">
                                    <FaLock />
                                </div>
                                <h3>Respect for Privacy</h3>
                                <p>Knowledge is personal. We protect our users' data with the highest standards of security and privacy.</p>
                            </div>
                            
                            <div className="value-card">
                                <div className="icon">
                                    <FaRocket />
                                </div>
                                <h3>Continuous Growth</h3>
                                <p>We believe in the power of incremental improvement, both for our product and ourselves.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="team-section">
                <div className="container">
                    <h2>Our Team</h2>
                    <p className="team-intro">At Linko, we believe that knowledge should be effortlessly organized, seamlessly connected, and easily shared. Our team is a passionate group of thinkers, builders, and learners who are dedicated to creating the ultimate knowledge management tool.</p>
                    
                    <div className="team-members">
                        <div className="team-member">
                            <div className="member-photo">
                                <CEOAvatar />
                            </div>
                            <h3>Tianqi Jiang</h3>
                            <p className="role">Founder & CEO</p>
                            <p className="bio">With a background in data analytics, Tianqi is a firm believer in the power of structured learning. His passion for knowledge and efficiency led him to create Linko, a platform that transforms the way people collect, organize, and recall information.</p>
                        </div>
                        
                        <div className="team-member">
                            <div className="member-photo">
                                <DeveloperAvatar />
                            </div>
                            <h3>Silvia</h3>
                            <p className="role">Co-founder, Software Developer & Marketing Expert</p>
                            <p className="bio">Silvia is a book lover and an avid note-taker. With her expertise in software development and marketing, she ensures that Linko not only functions smoothly but also reaches the hands of those who need it most.</p>
                        </div>
                        
                        <div className="team-member">
                            <div className="member-photo">
                                <DesignerAvatar />
                            </div>
                            <h3>Gemma</h3>
                            <p className="role">Designer & Creative Visionary</p>
                            <p className="bio">Gemma is the creative force behind Linko's user experience. A designer with a knack for innovation, she thrives on exploring new ways to present information beautifully and intuitively.</p>
                        </div>
                        
                        <div className="team-member">
                            <div className="member-photo">
                                <EngineerAvatar />
                            </div>
                            <h3>D.J.</h3>
                            <p className="role">Senior Software Engineer & Knowledge Enthusiast</p>
                            <p className="bio">D.J is a seasoned software engineer with a deep love for reading and note-taking. He believes that technology should enhance the way we learn, not complicate it.</p>
                        </div>
                    </div>
                    
                    <p className="team-outro">Together, our team is dedicated to redefining knowledge management. We believe that learning should be dynamic, collaborative, and endlessly explorative. With AI-driven tagging, a shared knowledge tree, and seamless connections between notes, Linko is more than just a tool—it's a community of curious minds, growing together. 🚀</p>
                </div>
            </section>

            <section className="contact-section">
                <div className="container">
                    <h2>Get in Touch</h2>
                    <p>Have questions or want to learn more about Linko? We'd love to hear from you!</p>
                    <div className="contact-methods">
                        <a href="mailto:linko.assistant@gmail.com" className="contact-button">
                            Contact Us
                        </a>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AboutUs; 
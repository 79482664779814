import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MdOutlineLibraryAdd } from 'react-icons/md';
import { UserContext } from '../../Context/AppContext';
import { RiBook2Line } from "react-icons/ri";
import { PiGlobe } from "react-icons/pi";
import { PiNotePencilBold } from "react-icons/pi";
import AddToLinkoModal from "../AddModals/AddToLinkoModal";

const NoContentPlaceholder = ({ activeTab, limited = false }) => {
    const location = useLocation();
    const { userInfo } = useContext(UserContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const openModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };
    const closeModal = () => setIsModalOpen(false);

    // Library view placeholders
    if (location.pathname.startsWith('/my_library')) {
        if (activeTab === 'resources') {
            return (
                <div className='linko-card'>
                    <div className='no-content-placeholder'>
                        <p style={{marginBottom:'12px'}}>Your library is waiting for its first resource! Add books, articles, videos, or any other learning materials you'd like to keep track of.</p>
                        <div style={{ display: 'flex', gap: '12px', justifyContent: 'center' }}>
                            <button 
                                className='linko-button linko-button--secondary'
                                onClick={() => openModal("Book")}
                            >
                                <RiBook2Line size={18} />
                                <span>Add Book</span>
                            </button>
                            <button 
                                className='linko-button linko-button--secondary'
                                onClick={() => openModal("Link")}
                            >
                                <PiGlobe size={18} />
                                <span>Add URL</span>
                            </button>
                        </div>
                        {isModalOpen && (
                            <AddToLinkoModal content={modalContent} onClose={closeModal} />
                        )}
                    </div>
                </div>
            );
        }
        if (activeTab === 'notes') {
            return (
                <div className='linko-card'>
                    <div className='no-content-placeholder'>
                        <p style={{marginBottom:'12px'}}>Start capturing your thoughts and insights! Your notes will help you track your learning journey and make connections between ideas.</p>
                    </div>
                </div>
            );
        }
    }

    // Home page welcome message
    if (location.pathname.startsWith('/my_linko') && activeTab === 'library') {
        return (
            <div className='linko-card mt-5' >
                <div className='no-content-placeholder'>
                    <h1>Welcome {userInfo?.first_name}! 👋</h1>
                    <p style={{marginBottom:'12px'}}>We're excited to have you here! Linko is your simple, distraction-free companion for lifelong learning - no complicated features, just pure focus on what matters.</p>
                    <p style={{marginBottom:'12px'}}>Here's what you can do with Linko:</p>
                    <ul style={{lineHeight:'1.5', listStyleType:'none', padding:0, fontSize:'16px'}}>
                        <li style={{marginBottom:'12px', marginLeft:'10px'}}>✍️ Jot down your brilliant ideas and insights</li>
                        <li style={{marginBottom:'12px', marginLeft:'10px'}}>📚 Save books, articles, videos, podcasts, links, and more</li>
                        <li style={{marginBottom:'12px', marginLeft:'10px'}}>🌟 Linko's AI will keep everything neatly organized, so you can easily revisit and find connections</li>
                    </ul>
                    <p style={{marginBottom:'12px'}}>Ready to start? Add your first resource or note - it's super easy!</p>
                    <div style={{ display: 'flex', gap: '12px', justifyContent: 'center' }}>
                        <button 
                            className='linko-button linko-button--secondary'
                            onClick={() => openModal("Book")}
                        >
                            <RiBook2Line size={18} />
                            <span>Add Book</span>
                        </button>
                        <button 
                            className='linko-button linko-button--secondary'
                            onClick={() => openModal("Link")}
                        >
                            <PiGlobe size={18} />
                            <span>Add URL</span>
                        </button>
                    </div>
                    {isModalOpen && (
                        <AddToLinkoModal content={modalContent} onClose={closeModal} />
                    )}
                </div>
            </div>
        );
    }

    if (location.pathname.startsWith('/my_linko') && activeTab === 'revisit') {
        return (
            <div className='linko-card mt-5'>
                <p style={{textAlign:'center'}}>Add your first note to unlock Ignite - our smart learning companion! ✨</p>
            </div>
        );
    }
    
    if (location.pathname.startsWith('/my_linko') && activeTab === 'explore') {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <h1>Meet Fellow Learners! 🤝</h1>
                    <p>Connect with other curious minds and see what they're learning about. You never know what amazing ideas you'll discover from others!</p>
                </div>
            </div>
        );
    }
     
    if (location.pathname.startsWith('/s/') && activeTab === 'explore') {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <h1>Be the Trailblazer! 🌟</h1>
                    <p>Looks like you're one of the first explorers here! Why not share what you're learning and inspire others who'll follow in your footsteps?</p>
                </div>
            </div>
        );
    }

    if (location.pathname.startsWith('/s/') && activeTab === 'library') {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <h1>Ready to Explore? 🚀</h1>
                    <p>This is your space to gather resources and thoughts about this topic. Start by adding something that caught your interest!</p>
                </div>
            </div>
        );
    }

    if (location.pathname.startsWith('/r/') && activeTab === 'library') {
        return (
            <div className='linko-card'>
                <div className='no-content-placeholder'>
                <h1>Reflect on Your Thoughts! 💭</h1>
                    <p>Take a moment to think about what stands out to you about this resource. Jot down your reflections - they might help you gain new insights!</p>
                </div>
            </div>
        );
    }

    if (location.pathname.startsWith('/r/') && activeTab === 'explore') {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <h1>Chart New Territory! 🗺️</h1>
                    <p>You're the first one to explore this resource! Share your thoughts and help others decide if this might be useful for them too.</p>
                </div>
            </div>
        );
    }

    if (location.pathname.startsWith('/n/') && activeTab === 'notes') {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <h1>Connect Ideas! 🧩</h1>
                    <p>Keep adding notes and watch as fascinating patterns emerge in your learning journey. Every note brings new connections!</p>
                </div>
            </div>
        );
    }

    if (location.pathname.startsWith('/profile/') && !limited) {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <h1>New Friend Alert! 👋</h1>
                    <p>This learner is just getting started. Come back soon to see what cool things they're discovering!</p>
                </div>
            </div>
        );
    } 

    if (limited) {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <p>Curious to see more? Follow this learner and join them on their exciting learning journey! 🌟</p>
                </div>
            </div>
        );
    }
};

export default NoContentPlaceholder;
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import { Link } from '@tiptap/extension-link';
import { Image } from '@tiptap/extension-image';
import { uploadFileToS3, deleteFileFromS3 } from '@linko/shared_utils';
import { useModal } from '../../Context/ModalContext';
import TurndownService from 'turndown';
import { marked } from 'marked';

// icons 
import { LuHeading } from "react-icons/lu";
import { BsChatLeftQuote } from "react-icons/bs";
import { VscListUnordered } from "react-icons/vsc";
import { GoListOrdered } from "react-icons/go";
import { BiBold } from "react-icons/bi";
import { PiTextItalicBold, PiCodeBlock,  } from "react-icons/pi";
import { BiLink, BiCodeAlt } from "react-icons/bi";

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import FileUploader from './FileUploader';

// Configure Marked options
marked.setOptions({
  breaks: true,           // Add line breaks on a single newline
  gfm: true,              // GitHub Flavored Markdown
  headerIds: false,       // Don't add IDs to headers (keeps HTML cleaner)
  mangle: false,          // Don't escape characters
  sanitize: false,        // Don't sanitize output (we trust the markdown)
});

// Create a turndown object for HTML to markdown
const setTurndownOptions = () => {
  const turndownService = new TurndownService({
    headingStyle: 'atx',
    codeBlockStyle: 'fenced',
    bulletListMarker: '-',
    emDelimiter: '_',
    strongDelimiter: '**',
    linkStyle: 'inlined',
  });

  // Custom rule for blockquotes
  turndownService.addRule('blockquotes', {
    filter: 'blockquote',
    replacement: function (content, node) {
      // Process content line by line
      content = content.trim();
      if (!content) return '';
      
      // Split by lines and prefix each with >
      const lines = content.split('\n');
      const prefixedLines = lines.map(line => {
        // Don't add double '>' prefixes
        if (line.startsWith('>')) return line;
        return '> ' + line;
      });
      
      return prefixedLines.join('\n') + '\n\n';
    }
  });

  // Custom rule for lists inside blockquotes
  turndownService.addRule('listItemsInBlockquotes', {
    filter: function (node) {
      return (
        node.nodeName === 'LI' && 
        node.parentNode && 
        (node.parentNode.nodeName === 'UL' || node.parentNode.nodeName === 'OL') && 
        node.parentNode.parentNode && 
        node.parentNode.parentNode.nodeName === 'BLOCKQUOTE'
      );
    },
    replacement: function (content, node, options) {
      const parent = node.parentNode;
      const index = Array.prototype.indexOf.call(parent.children, node);
      let prefix = ' ';
      
      if (parent.nodeName === 'OL') {
        prefix = ` ${index + 1}. `;
      } else {
        prefix = ' - ';
      }
      
      return prefix + content.trim();
    }
  });

  // More specific rule for blockquote content
  turndownService.addRule('blockquoteContent', {
    filter: function (node) {
      return (
        (node.nodeName === 'P' || node.nodeName === 'DIV') && 
        node.parentNode && 
        node.parentNode.nodeName === 'BLOCKQUOTE'
      );
    },
    replacement: function (content, node) {
      // Only process direct children of blockquote
      if (node.parentNode.nodeName !== 'BLOCKQUOTE') return content;
      
      // Don't add prefix if already has one
      if (content.startsWith('>')) return content;
      
      return content;
    }
  });

  return turndownService;
};

// Helper function to extract image URLs from markdown
const extractImageKeys = (markdown) => {
  const imageRegex = /!\[([^\]]*)\]\(([^)]+)\)/g;
  const imageUrls = new Set();
  let match;
  
  while ((match = imageRegex.exec(markdown)) !== null) {
    const url = match[2];
    // Extract the key from the S3 URL
    const keyMatch = url.match(/\/uploads\/[^/]+\/[^?]+/);
    if (keyMatch) {
      imageUrls.add(keyMatch[0]);
    }
  }
  
  return imageUrls;
};

// Parse markdown to HTML using Marked
const markdownToHtml = (markdown) => {
  if (!markdown) return '';
  
  // Pre-process markdown for better rendering
  let processedMarkdown = markdown
    // Normalize line endings
    .replace(/\r\n/g, '\n').replace(/\r/g, '\n')
    
    // Remove any double blockquote markers that might cause issues
    .replace(/^>\s*>\s*/gm, '> ')
    
    // Fix indented lists by replacing tab/space indentation with proper markdown indentation
    .replace(/^(\s{4})([-*])/gm, '    $2')
    .replace(/^(\s{8})([-*])/gm, '        $2')
    .replace(/^(\s{4})(\d+\.)/gm, '    $2')
    .replace(/^(\s{8})(\d+\.)/gm, '        $2')
    
    // Ensure consistent heading levels (use ## for all headers)
    .replace(/^# /gm, '## ')
    
    // Handle blockquote content consistently
    .replace(/^>\s*##\s+(.+)$/gm, '> ## $1')
    .replace(/^>\s*([-*])\s+(.+)$/gm, '> - $2')
    .replace(/^>\s*(\d+)\.\s+(.+)$/gm, '> 1. $2')
    
    // Ensure proper list indentation for blockquote lists
    .replace(/^>\s+(\d+)\.\s/gm, '> $1. ')
    .replace(/^>\s+([-*])\s/gm, '> $1 ')
    
    // Make sure blockquotes have proper spacing
    .replace(/^>\s*$/gm, '>')
    .replace(/^>$/gm, '> ');
  
  // Configure marked options to handle GFM (GitHub Flavored Markdown) which includes tables and lists
  marked.setOptions({
    gfm: true,              // GitHub Flavored Markdown
    breaks: true,           // Add <br> on a single line break
    headerIds: false,       // Don't add IDs to headers
    mangle: false,          // Don't escape characters
    sanitize: false,        // Don't sanitize HTML input
  });
  
  // Use marked to convert markdown to HTML
  const html = marked.parse(processedMarkdown);
  
  // Post-process HTML to fix common issues
  const processedHtml = html
    // Ensure blockquotes are properly separated
    .replace(/<\/blockquote>\s*<blockquote>/g, '</blockquote>\n\n<blockquote>')
    // Fix code blocks
    .replace(/<pre><code>(.*?)<\/code><\/pre>/gs, '<pre><code>$1</code></pre>')
    // Ensure consistent heading levels
    .replace(/<h1>/g, '<h2>').replace(/<\/h1>/g, '</h2>')
    // Fix paragraph spacing
    .replace(/<p><\/p>/g, '<br />');
  
  return processedHtml;
};

// Function to convert HTML to markdown
const htmlToMarkdown = (html) => {
  if (!html) return '';
  
  try {
    // Pre-process HTML
    const processedHtml = html
      // Clean up spurious br tags
      .replace(/<br><\/li>/g, '</li>')
      // Fix nested list spacing
      .replace(/<\/ul>\s*<li>/g, '</ul><li>')
      .replace(/<\/ol>\s*<li>/g, '</ol><li>')
      // Ensure blockquotes have proper formatting
      .replace(/<blockquote><p>/g, '<blockquote>')
      .replace(/<\/p><\/blockquote>/g, '</blockquote>')
      // Normalize list spacing
      .replace(/<\/li>\s*<\/ul>/g, '</li></ul>')
      .replace(/<\/li>\s*<\/ol>/g, '</li></ol>');
    
    // Use Turndown to convert HTML to Markdown
    const turndownService = setTurndownOptions();
    const markdown = turndownService.turndown(processedHtml);
    
    // Post-process markdown
    const processedMarkdown = markdown
      // Clean up multiple blank lines
      .replace(/\n{3,}/g, '\n\n')
      // Fix blockquote spacing
      .replace(/>\s*\n\n/g, '>\n\n')
      // Ensure a blank line after blockquotes
      .replace(/>\s*\n([^>])/g, '>\n\n$1')
      // Fix any remaining double blockquote markers
      .replace(/^>\s*>\s*/gm, '> ');
    
    console.log('Converted Markdown:', processedMarkdown);
    return processedMarkdown;
  } catch (error) {
    console.error('HTML to Markdown conversion error:', error);
    return html;
  }
};

const StyleButton = ({ onClick, isActive, label, tooltip, isTouchDevice }) => {
  const onMouseDown = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <Tippy 
      content={tooltip}
      placement="top"
      arrow={true}
      theme='light-border'
      hideOnClick={true} 
      delay={[0, 0]} 
      touch={false}
      disabled={isTouchDevice}
    >
      <span 
        className="RichEditor-styleButton" 
        onMouseDown={onMouseDown}
        style={{ color: isActive ? '#267953' : '#999999' }}
      >
        {label}
      </span>
    </Tippy>
  );
};

// Main editor component
const RichEditor = ({ 
  value, 
  onChange, 
  onImagesChange,
  onFocus,
  onBlur 
}) => {
  const [trackedImages, setTrackedImages] = useState(new Set());
  const { requestLink } = useModal();
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const isInternalChange = useRef(false);
  const initialContentSet = useRef(false);

  useEffect(() => {
    const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    setIsTouchDevice(isTouch);
  }, []);

  // Create editor with initial content
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: {
          levels: [2], // Only allow h2, no h1
        },
        // Add specific configuration for editor behavior
        history: {
          depth: 100,
          newGroupDelay: 500,
        },
      }),
      Link.configure({
        openOnClick: false,
      }),
      Image.configure({
        allowBase64: true,
        inline: true,
      }),
    ],
    editorProps: {
      // Handle ProseMirror version conflicts by using built-in handlers
      handleKeyDown: (view, event) => {
        // Let TipTap handle all keyboard events by default
        // This ensures proper list behavior
        return false;
      },
      handleDOMEvents: {
        focus: (view, event) => {
          if (onFocus) onFocus(event);
          return false; // Let TipTap handle the event
        },
        blur: (view, event) => {
          if (onBlur) onBlur(event);
          return false; // Let TipTap handle the event
        },
      },
      // Add custom attributes to make editor more robust
      attributes: {
        class: 'tiptap-editor-content',
        spellcheck: 'true',
      },
    },
    content: markdownToHtml(value || ''),
    onUpdate: ({ editor }) => {
      // Convert HTML to markdown
      const html = editor.getHTML();
      const markdown = htmlToMarkdown(html);
      
      console.log('Editor update:');
      console.log('Editor HTML:', html);
      console.log('Converted markdown:', markdown);

      // Track image changes
      const currentImages = extractImageKeys(markdown);
      const removedImages = Array.from(trackedImages).filter(img => !currentImages.has(img));
      
      // Delete removed images from S3
      removedImages.forEach(async (imageKey) => {
        try {
          await deleteFileFromS3(imageKey);
        } catch (error) {
          console.error('Failed to delete image:', error);
        }
      });

      // Update tracked images
      setTrackedImages(currentImages);
      
      // Notify parent about image changes if callback exists
      if (onImagesChange) {
        onImagesChange(currentImages);
      }

      isInternalChange.current = true;
      onChange(markdown);
      
      // Reset flag after a short delay to prevent conflicts
      setTimeout(() => {
        isInternalChange.current = false;
      }, 0);
    },
    onFocus,
    onBlur,
  });

  // Initial content setup - only run once
  useEffect(() => {
    if (editor && !initialContentSet.current && value) {
      initialContentSet.current = true;
      const htmlContent = markdownToHtml(value);
      editor.commands.setContent(htmlContent);
    }
  }, [editor, value]);

  // Update tracked images when content changes
  useEffect(() => {
    if (value) {
      const newImages = extractImageKeys(value);
      setTrackedImages(newImages);
    }
  }, [value]);

  // Improved command handlers to handle cursor and selection cases properly
  const toggleHeading = useCallback(() => {
    if (!editor) return;
    // Make sure we're working with the paragraph or entire heading node
    editor.chain().focus().toggleHeading({ level: 2 }).run();
  }, [editor]);

  const toggleBlockquote = useCallback(() => {
    if (!editor) return;
    // Ensure entire paragraph is selected when applying blockquote
    editor.chain().focus().toggleBlockquote().run();
  }, [editor]);

  const toggleBold = useCallback(() => {
    if (!editor) return;
    editor.chain().focus().toggleBold().run();
  }, [editor]);

  const toggleItalic = useCallback(() => {
    if (!editor) return;
    editor.chain().focus().toggleItalic().run();
  }, [editor]);

  const toggleBulletList = useCallback(() => {
    if (!editor) return;
    editor.chain().focus().toggleBulletList().run();
  }, [editor]);

  const toggleOrderedList = useCallback(() => {
    if (!editor) return;
    editor.chain().focus().toggleOrderedList().run();
  }, [editor]);

  const toggleCodeBlock = useCallback(() => {
    if (!editor) return;
    editor.chain().focus().toggleCodeBlock().run();
  }, [editor]);

  const toggleCode = useCallback(() => {
    if (!editor) return;
    editor.chain().focus().toggleCode().run();
  }, [editor]);

  const addLink = useCallback(() => {
    if (!editor) return;

    const previousUrl = editor.getAttributes('link').href;
    
    requestLink(previousUrl || '', (url) => {
      if (url === null) {
        // User canceled
        return;
      }

      // If no URL is provided, remove the link
      if (url === '') {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();
        return;
      }
      
      // Update or add link
      editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
    });
  }, [editor, requestLink]);

  const handleImageUpload = async (file) => {
    if (!editor) return;
    
    try {
      // Upload to S3 and get the URL
      const imageUrl = await uploadFileToS3(file, (progress) => {
        // Progress handling if needed
      });
      
      // Add image to editor
      editor.chain().focus().setImage({ src: imageUrl }).run();
    } catch (error) {
      console.error('Error uploading image:', error);
      alert(error.message || 'Error uploading image. Please try again.');
    }
  };

  // Handle paste events for images
  useEffect(() => {
    if (editor) {
      const handlePaste = async (event) => {
        const items = event.clipboardData?.items;
        if (!items) return;
        
        for (const item of items) {
          if (item.type.indexOf('image') === 0) {
            event.preventDefault();
            const file = item.getAsFile();
            if (!file) continue;
            
            // Check file size (max 5MB)
            if (file.size > 5 * 1024 * 1024) {
              alert('Image size should be less than 5MB');
      return;
    }

            await handleImageUpload(file);
        return;
      }
        }
      };
      
      // Add paste event listener
      document.addEventListener('paste', handlePaste);
      
      return () => {
        document.removeEventListener('paste', handlePaste);
      };
    }
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <div className="RichEditor-root">
      <div className="RichEditor-controls">
        <StyleButton 
          onClick={toggleHeading} 
          isActive={editor.isActive('heading', { level: 2 })} 
          label={<LuHeading />} 
          tooltip="Heading" 
          isTouchDevice={isTouchDevice} 
        />
        <StyleButton 
          onClick={toggleBlockquote} 
          isActive={editor.isActive('blockquote')} 
          label={<BsChatLeftQuote />} 
          tooltip="Blockquote" 
          isTouchDevice={isTouchDevice} 
        />
        <StyleButton 
          onClick={toggleBold} 
          isActive={editor.isActive('bold')} 
          label={<BiBold />} 
          tooltip="Bold" 
          isTouchDevice={isTouchDevice} 
        />
        {/* <StyleButton 
          onClick={toggleItalic} 
          isActive={editor.isActive('italic')} 
          label={<PiTextItalicBold />} 
          tooltip="Italic" 
          isTouchDevice={isTouchDevice} 
        /> */}
        <StyleButton 
          onClick={toggleBulletList} 
          isActive={editor.isActive('bulletList')} 
          label={<VscListUnordered />} 
          tooltip="Bullet List" 
          isTouchDevice={isTouchDevice} 
        />
        <StyleButton 
          onClick={toggleOrderedList} 
          isActive={editor.isActive('orderedList')} 
          label={<GoListOrdered />} 
          tooltip="Numbered List" 
          isTouchDevice={isTouchDevice} 
        />
        {/* <StyleButton 
          onClick={toggleCode} 
          isActive={editor.isActive('code')} 
          label={<BiCodeAlt />} 
          tooltip="Inline Code" 
          isTouchDevice={isTouchDevice} 
        />
        <StyleButton 
          onClick={toggleCodeBlock} 
          isActive={editor.isActive('codeBlock')} 
          label={<PiCodeBlock />} 
          tooltip="Code Block" 
          isTouchDevice={isTouchDevice} 
        /> */}
        <StyleButton 
          onClick={addLink} 
          isActive={editor.isActive('link')} 
          label={<BiLink />} 
          tooltip="Add Link" 
          isTouchDevice={isTouchDevice} 
        />
        <FileUploader
          onImageUpload={(imageUrl) => {
            if (editor) {
              editor.chain().focus().setImage({ src: imageUrl }).run();
            }
          }}
          isTouchDevice={isTouchDevice}
        />
      </div>
      <div className="RichEditor-content" onClick={() => editor.chain().focus().run()}>
        <EditorContent 
          editor={editor} 
          className="tiptap-editor"
        />
      </div>
    </div>
  );
};

export default React.memo(RichEditor);

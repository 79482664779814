import React from 'react';
import { RiBook2Line } from "react-icons/ri";
import { GrArticle } from "react-icons/gr";
import { LuGraduationCap } from "react-icons/lu";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { PiGlobe } from "react-icons/pi";
import { TbProgressCheck } from "react-icons/tb";
import { TbProgress } from "react-icons/tb";
import { FaCircleCheck } from "react-icons/fa6"; 
import { LuHeadphones } from "react-icons/lu";

function LibraryFilter({
    filterType, 
    setFilterType, 
    filterFinished, 
    setFilterFinished,
    isLoading,
}) {

    const toggleType = (type) => () => {
        if (!isLoading) {
            setFilterType(filterType.includes(type) ? [] : [type]);
        }
    };

    const toggleFinished = (finished) => {
        return (event) => {
            if (!isLoading) {
                event.stopPropagation();
                if (filterFinished === finished) {
                    setFilterFinished(null);
                } else {
                    setFilterFinished(finished);
                }
            }
        };
    };

    return (
        <div className='resource-tab-filter'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className='filter-labels'>
                    <div onClick={toggleType('bo')}
                        className={`type-filter-button ${filterType.includes('bo') ? 'active-filter' : ''}`}
                        style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
                    >
                        <RiBook2Line size={18} /> 
                        Book
                    </div>
                    <div onClick={toggleType('vi')}
                        className={`type-filter-button ${filterType.includes('vi') ? 'active-filter' : ''}`}
                        style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
                    >
                        <MdOutlineOndemandVideo size={18} /> 
                        Video
                    </div>
                    <div onClick={toggleType('ar')}
                        className={`type-filter-button ${filterType.includes('ar') ? 'active-filter' : ''}`}
                        style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
                    >
                        <GrArticle size={18} /> 
                        Article
                    </div>
                    <div onClick={toggleType('po')}
                        className={`type-filter-button ${filterType.includes('po') ? 'active-filter' : ''}`}
                        style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
                    >
                        <LuHeadphones size={18} /> 
                        Podcast
                    </div>
                    <div onClick={toggleType('oc')}
                        className={`type-filter-button ${filterType.includes('oc') ? 'active-filter' : ''}`}
                        style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
                    >
                        <LuGraduationCap size={18} /> 
                        Online Course
                    </div>
                    <div onClick={toggleType('or')}
                        className={`type-filter-button ${filterType.includes('or') ? 'active-filter' : ''}`}
                        style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
                    >
                        <PiGlobe size={18} /> 
                        Link
                    </div>
                </div>
            </div>
            <div className={window.innerWidth > 1148 ? 'rx-divider-horizontal' : ''}></div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className='filter-labels'>
                    <div onClick={toggleFinished('not_started')}
                        className={`type-filter-button ${filterFinished === 'not_started' ? 'active-filter' : ''}`}
                        style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
                    >
                        <TbProgress size={18} /> Not Started
                    </div>
                    <div onClick={toggleFinished('in_progress')}
                        className={`type-filter-button ${filterFinished === 'in_progress' ? 'active-filter' : ''}`}
                        style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
                    >
                        <TbProgressCheck size={18} /> In Progress
                    </div>
                    <div onClick={toggleFinished('finished')}
                        className={`type-filter-button ${filterFinished === 'finished' ? 'active-filter' : ''}`}
                        style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
                    >
                        <FaCircleCheck size={16} /> Finished
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LibraryFilter;

import { useState } from "react"
import { useNavigate } from "react-router-dom"
import Button from "../Button/Button"
import Logo from '../../../../Components/Logo'


const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const navigate = useNavigate()

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <header className="header">
      <div className="container">
        <div className="header__content">
          <div className="header__logo">
            <Logo />
          </div>

          <nav className={`header__nav ${menuOpen ? "header__nav--open" : ""}`}>
            {/* <a href="#features" className="header__link">
              Features
            </a>
            <a href="#pricing" className="header__link">
              Pricing
            </a>
            <a href="#faq" className="header__link">
              FAQ
            </a> */}
            <div className="header__buttons">
              <Button outline onClick={() => navigate('/login')}>Log in</Button>
              {/* <Button primary onClick={() => navigate('/register')}>Sign up</Button> */}
            </div>
          </nav>

          <button className="header__menu-button" onClick={toggleMenu}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="4" x2="20" y1="12" y2="12"></line>
              <line x1="4" x2="20" y1="6" y2="6"></line>
              <line x1="4" x2="20" y1="18" y2="18"></line>
            </svg>
          </button>
        </div>
      </div>
    </header>
  )
}

export default Header


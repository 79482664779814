import React, { useState, useEffect } from 'react';
import { IoClose } from 'react-icons/io5';

const isValidUrl = (string) => {
  try {
    // Check if empty
    if (!string) return false;
    
    // Try creating a URL object
    new URL(string);
    
    // Additional check for common protocols
    return string.startsWith('http://') || string.startsWith('https://');
  } catch (err) {
    return false;
  }
};

const LinkModal = ({ isOpen, onConfirm, onCancel, existingUrl }) => {
  const [url, setUrl] = useState('');
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const initialUrl = existingUrl || '';
    setUrl(initialUrl);
    setIsValid(isValidUrl(initialUrl));
  }, [isOpen, existingUrl]);

  const handleUrlChange = (e) => {
    const newUrl = e.target.value;
    setUrl(newUrl);
    setIsValid(isValidUrl(newUrl));
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onCancel}>
      <div className="linko-modal" onClick={(e) => e.stopPropagation()} >
        <div className="modal-header">
          <h2>Insert Link</h2>
          <button onClick={onCancel} className="close-button"> <IoClose /></button>
        </div>
        <div className="modal-body"> 
          <div className="input-group">
            <input
              className="link-modal-input"
              type="text"
              value={url}
              onChange={handleUrlChange}
              placeholder="Enter URL"
              autoFocus
            />
          </div>
        </div>
        <div className="modal-footer">
          <button 
            className="linko-button linko-button--cancel"
            onClick={() => onConfirm('')}
          >
            Clear Link
          </button>
          <button 
            className={`linko-button linko-button--primary ${!isValid ? 'linko-button--primary--disabled' : ''}`}
            onClick={() => onConfirm(url)}
            disabled={!isValid}
          >
            Add Link
          </button>
        </div>
      </div>
    </div>
  );
};

export default LinkModal;
import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { HomePageContext } from '../../Context/HomePageContext.jsx';
import { UserContext } from '../../Context/AppContext.jsx';
import { fetchMyFeed, fetchFollowingFeed } from '@linko/shared_utils';
import TheTabs from './TheTabs.jsx';

const TabDataWrapper = ({ 
    subjectId = null, 
    resourceId = null, 
    library, 
    setLibrary, 
    ...props 
}) => {
    
    const location = useLocation();
    const homePage = location.pathname.startsWith('/my_linko');
    const { 
        homePageState,
        updateLibraryArray,
        updateExploreArray,
        setIsFetchingMoreLibraryHomePage,
        setIsFetchingMoreExploreHomePage
    } = useContext(HomePageContext);
    const { userInfo } = useContext(UserContext);
    // Explore state
    const [explore, setExplore] = useState([]);
    const [isFetchingMoreExplore, setIsFetchingMoreExplore] = useState(true);
    const [hasMoreExplore, setHasMoreExplore] = useState(true);
    
    // Library pagination state
    const [isFetchingMoreLibrary, setIsFetchingMoreLibrary] = useState(true);
    const [hasMoreLibrary, setHasMoreLibrary] = useState(true);

    const limit = 10;
    
    const handleSetLibrary = (newLibrary) => {
        if (homePage) {
            updateLibraryArray(newLibrary);
        } else {
            setLibrary(newLibrary);
        }
    };

    const handleSetExplore = (newExplore) => {
        if (homePage) {
            updateExploreArray(newExplore);
        } else {
            setExplore(newExplore);
        }
    };

    const handleSetIsFetchingMoreLibrary = (value) => {
        if (homePage) {
            setIsFetchingMoreLibraryHomePage(value);
        } else {
            setIsFetchingMoreLibrary(value);
        }
    };

    const handleSetIsFetchingMoreExplore = (value) => {
        if (homePage) {
            setIsFetchingMoreExploreHomePage(value);
        } else {
            setIsFetchingMoreExplore(value);
        }
    };

    // Initial data fetch for non-homepage
    useEffect(() => {
        if (homePage) return;

        setExplore([]);
        setLibrary([]);
        setIsFetchingMoreExplore(true);
        setIsFetchingMoreLibrary(true);
        setHasMoreExplore(true);
        setHasMoreLibrary(true);

        const fetchData = async () => {
            if (subjectId || resourceId) {
                try {
                    // Fetch library data only if empty
                    const libraryResponse = await fetchMyFeed(
                        limit, 0, subjectId, resourceId,
                        null, null, null, null
                    );
                    if (Array.isArray(libraryResponse.data.results)) {
                        setLibrary(libraryResponse.data.results);
                        setHasMoreLibrary(libraryResponse.data.results.length === limit);
                    }
                } catch (error) {
                    console.error('Error fetching library data:', error);
                } finally {
                    setIsFetchingMoreLibrary(false);
                }

                try {
                    if (!userInfo?.private_account) {
                        const exploreResponse = await fetchFollowingFeed(
                            limit, 0, subjectId, resourceId,
                            null, null, null, null
                        );
                        if (Array.isArray(exploreResponse.data.results)) {
                            setExplore(exploreResponse.data.results);
                            setHasMoreExplore(exploreResponse.data.results.length === limit);
                        }
                    }
                } catch (error) {
                    console.error('Error fetching explore data:', error);
                } finally {
                    setIsFetchingMoreExplore(false);
                }
            }
        };
        
        fetchData();

        // Cleanup function to ensure loading states are reset if component unmounts during fetch
        return () => {
            setIsFetchingMoreLibrary(false);
            setIsFetchingMoreExplore(false);
        };
    }, [subjectId, resourceId, homePage]);

    // Handle infinite scroll for library
    useEffect(() => {
        if (homePage || !isFetchingMoreLibrary || library.length === 0) return;

        const fetchMoreLibrary = async () => {
            const currentOffset = library.length;
            
            const response = await fetchMyFeed(
                limit, currentOffset, subjectId, resourceId,
                null, null, null, null
            );
            if (Array.isArray(response.data.results)) {
                setLibrary(prev => [...prev, ...response.data.results]);
                setHasMoreLibrary(response.data.results.length === limit);
                setIsFetchingMoreLibrary(false);
            }
        };
        fetchMoreLibrary();
    }, [isFetchingMoreLibrary]);

    // Handle infinite scroll for explore
    useEffect(() => {
        if (homePage || !isFetchingMoreExplore || explore.length === 0) return;

        const fetchMoreExplore = async () => {
            try {
                const currentOffset = explore.length;
                
                const response = await fetchFollowingFeed(
                    limit, currentOffset, subjectId, resourceId,
                    null, null, null
                );
                if (Array.isArray(response.data.results)) {
                    setExplore(prev => [...prev, ...response.data.results]);
                    setHasMoreExplore(response.data.results.length === limit);
                }
            } catch (error) {
                console.error('Error fetching explore data:', error);
            } finally {
                setIsFetchingMoreExplore(false);
            }
        };

        fetchMoreExplore();

        // Cleanup function to ensure loading state is reset if component unmounts during fetch
        return () => {
            setIsFetchingMoreExplore(false);
        };
    }, [isFetchingMoreExplore]);

    return (
        <TheTabs
            {...props}
            subjectId={subjectId}
            resourceId={resourceId}
            library={homePage ? homePageState.library : library}
            setLibrary={handleSetLibrary}
            explore={homePage ? homePageState.explore : explore}
            setExplore={handleSetExplore}
            isFetchingMoreLibrary={homePage ? homePageState.isFetchingMoreLibrary : isFetchingMoreLibrary}
            isFetchingMoreExplore={homePage ? homePageState.isFetchingMoreExplore : isFetchingMoreExplore}
            hasMoreLibrary={homePage ? homePageState.hasMoreLibrary : hasMoreLibrary}
            hasMoreExplore={homePage ? homePageState.hasMoreExplore : hasMoreExplore}
            setIsFetchingMoreLibrary={handleSetIsFetchingMoreLibrary}
            setIsFetchingMoreExplore={handleSetIsFetchingMoreExplore}
        />
    );
};

export default TabDataWrapper;
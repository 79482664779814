
import React from 'react';
import BounceLoader from "react-spinners/BounceLoader";

const LoaderComponent = ({size = 50}) => {
    return (
        <div className='loader-container'>
            <BounceLoader color={"var(--secondary-color)"} className='the-loader' size={size}/>
        </div>
    );
}

export default LoaderComponent;

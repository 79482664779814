import React, { useState, useRef, useContext, useEffect } from 'react';
import { UserContext } from '../../Context/AppContext';
import { HomePageContext } from '../../Context/HomePageContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast } from '../ToastNotification';
import { useModal } from '../../Context/ModalContext';
import { SideBarContext } from '../../Context/SideBarContext';
// Styles
import '../../Stylesheets/ComponentsStyles/Modals&Toast&Tooltip/AIFeedbackModal.scss';
// Components
import LinkResourceModal from './LinkResourceModal';
import LoaderComponent from '../Loader';
// Icons
import { FaRegTrashAlt, FaRegShareSquare } from "react-icons/fa";
import { MdMoreHoriz } from "react-icons/md";
import { PiNotePencilBold } from "react-icons/pi";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { TbCards } from "react-icons/tb";
import { deleteNote, deleteFileFromS3, getNoteAIFeedback } from '@linko/shared_utils';
import { PiDownloadSimpleBold } from "react-icons/pi";
import { generateNoteImage } from '../../utils/noteImageGenerator';
import { AiOutlineRobot, AiOutlineBulb, AiOutlineBranches, AiOutlineExperiment } from "react-icons/ai";

// Function to extract image URLs from markdown content
const extractImageUrls = (markdownContent) => {
  if (!markdownContent) return [];
  
  const imageUrls = new Set();
  const imageRegex = /!\[([^\]]*)\]\(([^)]+)\)/g;
  let match;
  
  while ((match = imageRegex.exec(markdownContent)) !== null) {
    const url = match[2];
    // Extract the key from the S3 URL
    const keyMatch = url.match(/\/uploads\/[^/]+\/[^?]+/);
    if (keyMatch) {
      imageUrls.add(keyMatch[0]);
    }
  }
  
  return Array.from(imageUrls);
};

const NoteCardActions = ({
    note, 
    noteId,
    onEditClick, 
    onDelete, 
    updateSingleNote,
    noteResource,
    setNoteResource,
    myNotePage,
}) => {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAIFeedbackModalOpen, setIsAIFeedbackModalOpen] = useState(false);
    const [aiFeedback, setAIFeedback] = useState("");
    const [isLoadingFeedback, setIsLoadingFeedback] = useState(false);
    const dropdownRef = useRef(null);
    const { addToast, setPersistentMessage } = useToast();
    const { requestConfirm } = useModal();
    const [linkCopied, setLinkCopied] = useState(false);
    const { fetchUserSubjectData, fetchUserInfo } = useContext(UserContext);
    const { removeItemFromHomePage } = useContext(HomePageContext);
    const { refreshSidebarResources } = useContext(SideBarContext);
    const navigate = useNavigate();
    const location = useLocation();
    const isCurrentNotePage = location.pathname === `/n/${noteId}`;
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = (event) => {
        event.stopPropagation();
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleDeleteNote = async () => {
        requestConfirm(
            'Are you sure you want to delete this note?',
            async () => {
                try {
                    // Extract image URLs from note content
                    const imageUrls = extractImageUrls(note?.note);
                    
                    // Delete each image from S3
                    if (imageUrls.length > 0) {
                        for (const imageUrl of imageUrls) {
                            try {
                                await deleteFileFromS3(imageUrl);
                            } catch (error) {
                                console.error('Error deleting image:', imageUrl, error);
                            }
                        }
                    }

                    // Delete the note
                    await deleteNote(noteId);
                    
                    if (myNotePage && !updateSingleNote) {
                        navigate(-1);
                        setPersistentMessage('Note deleted.', {appearance: 'success'});
                    } else {
                        onDelete(note.id);
                        addToast('Note deleted.', {appearance: 'success'});
                    }
                    removeItemFromHomePage(noteId, 'note');
                    await fetchUserSubjectData();
                    await fetchUserInfo();
                    if (note.resource.length > 0) {
                        await refreshSidebarResources();
                    }
                } catch (error) {
                    console.error('Error in handleDeleteNote:', error);
                    addToast('Failed to delete note.', {appearance: 'error'});
                }
            },
            null,
            'Delete'
        );
    }

    const copyNoteLink = () => {
        setLinkCopied(true);
        if (navigator.clipboard) {
            navigator.clipboard.writeText(`https://www.linko.study/n/${noteId}`)
            .then(() => {
                addToast('Note link copied!', {appearance: 'success'});
                setTimeout(() => setLinkCopied(false), 2000);
            })
            .catch(err => {
              console.error('Error writing to clipboard', err);
              addToast('Failed to copy profile link', {appearance: 'error'});
              setTimeout(() => setLinkCopied(false), 2000);
            });
        } else {
            console.error('Clipboard API not supported');
            addToast('Failed to copy profile link', {appearance: 'error'});
            setTimeout(() => setLinkCopied(false), 2000);
        }
    }

    const closeModal = () => setIsModalOpen(false);
    const closeAIFeedbackModal = () => setIsAIFeedbackModalOpen(false);

    const downloadNote = async () => {
        try {
            const imageDataUrl = await generateNoteImage(note, noteId);
            
            // Create a temporary link element
            const link = document.createElement('a');
            link.href = imageDataUrl;
            link.download = `Linko_note_${noteId}.png`;
            
            // Trigger download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
            addToast('Note downloaded successfully', {appearance: 'success'});
        } catch (error) {
            console.error('Error downloading note:', error);
            addToast('Failed to download note', {appearance: 'error'});
        }
    };

    const getAIFeedback = async () => {
        try {
            // 先显示模态窗口和加载动画
            setIsLoadingFeedback(true);
            setIsAIFeedbackModalOpen(true);
            
            // 然后在后台请求数据
            const response = await getNoteAIFeedback(noteId);
            setAIFeedback(response.feedback);
            setIsLoadingFeedback(false);
        } catch (error) {
            console.error('Error getting AI feedback:', error);
            addToast('Failed to get AI feedback', {appearance: 'error'});
            setIsLoadingFeedback(false);
            // 出错时关闭模态窗口
            setIsAIFeedbackModalOpen(false);
        }
    };

    // 格式化反馈内容的函数
    const formatFeedbackContent = (content) => {
        if (!content) return [];
        
        // 查找标记为**xxx**的部分，并提取项目
        const itemRegex = /\d+\.\s+\*\*([^*]+)\*\*\s*:\s*([\s\S]*?)(?=\d+\.\s+\*\*|$)/g;
        const items = [];
        let match;
        
        while ((match = itemRegex.exec(content)) !== null) {
            const type = match[1].trim();
            const text = match[2].trim();
            
            // 根据类型分配样式类和图标
            let className = 'insight';
            let icon = <AiOutlineBulb size={18} />;
            
            // 检测反馈类型 - 支持多语言（中英文）并为每种类型分配唯一的图标和类名
            if (type.includes('核心观点') || type.includes('Core Insight')) {
                className = 'insight';
                icon = <AiOutlineBulb size={18} />;
            } else if (type.includes('思维拓展') || type.includes('Thought Extension')) {
                className = 'extension';
                icon = <AiOutlineBranches size={18} />;
            } else if (type.includes('批判性') || type.includes('Critical')) {
                className = 'critical';
                icon = <AiOutlineExperiment size={18} />;
            } else if (type.includes('关联知识') || type.includes('Knowledge Connection')) {
                className = 'connection';
                icon = <TbCards size={18} />;
            } else if (type.includes('实践') || type.includes('Practical')) {
                className = 'practical';
                icon = <PiNotePencilBold size={18} />;
            }
            
            items.push({
                type,
                text,
                className,
                icon
            });
        }
        
        return items;
    };

    return ( 
        <div>
            <div style={{height:'fit-content'}}>
                <MdMoreHoriz size={16} className="MoreTabWLink" onClick={toggleDropdown}/>
                {isDropdownOpen && (
                    <div ref={dropdownRef} className='card-action-dropdown' onClick={(e) => e.stopPropagation()}>
                        <div className='card-action-group' onClick={(e) => {
                            e.stopPropagation();
                            onEditClick();
                            setIsDropdownOpen(false);
                        }}>
                            <PiNotePencilBold className='card-action-icon' />
                            <p>Edit</p>
                        </div>
                        {!isCurrentNotePage && (
                            <div className='card-action-group' onClick={(e) => {
                                e.stopPropagation();
                                navigate(`/n/${noteId}`);
                                setIsDropdownOpen(false);
                            }}>
                                <TbCards className='card-action-icon' />
                                <p>Related notes</p>
                            </div>
                        )}
                        <div className='card-action-group' onClick={(e) => {
                            e.stopPropagation();
                            // 防止重复点击
                            if (!isLoadingFeedback) {
                                getAIFeedback();
                            }
                            setIsDropdownOpen(false);
                        }} style={{opacity: isLoadingFeedback ? 0.7 : 1, cursor: isLoadingFeedback ? 'not-allowed' : 'pointer'}}>
                            <AiOutlineRobot className='card-action-icon'/>
                            <p>{isLoadingFeedback ? 'Generating...' : 'AI Insights'}</p>
                        </div>
                        <div className='card-action-group' onClick={(e) => {
                            e.stopPropagation();
                            setIsModalOpen(true);
                            setIsDropdownOpen(false);
                        }}>
                            <MdOutlineLibraryAdd className='card-action-icon' />
                            <p>Link resource</p>
                        </div>
                        <div className='card-action-group' onClick={(e) => {
                            e.stopPropagation();
                            copyNoteLink();
                            setIsDropdownOpen(false);
                        }}>
                            <FaRegShareSquare className='card-action-icon share' color={linkCopied && 'var(--primary-color)'}/>
                            {linkCopied ? <p style={{color:'var(--primary-color)'}}>Link copied</p> : <p>Share</p>}
                        </div>
                        <div className='card-action-group' onClick={(e) => {
                            e.stopPropagation();
                            downloadNote();
                            setIsDropdownOpen(false);
                        }}>
                            <PiDownloadSimpleBold className='card-action-icon'/>
                            <p>Save as image</p>
                        </div>
                        <div className='card-action-group delete-group' onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteNote(note.id);
                            setIsDropdownOpen(false);
                        }}>
                            <FaRegTrashAlt className='card-action-icon delete-icon'/>
                            <p>Delete</p>
                        </div>
                    </div>
                )}
            </div>
            {isModalOpen && (
                <LinkResourceModal 
                    onClose={closeModal} 
                    updateSingleNote={updateSingleNote}
                    noteResource={noteResource}
                    setNoteResource={setNoteResource} 
                    noteId={noteId}/>
            )}
            {isAIFeedbackModalOpen && (
                <div className="modal-overlay" onClick={closeAIFeedbackModal}>
                    <div className="info-modal-content ai-feedback-modal" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <AiOutlineRobot size={22} />
                            <h2>AI Insights</h2>
                        </div>
                        <div className={`modal-body ${isLoadingFeedback ? 'loading' : ''}`}>
                            {isLoadingFeedback ? (
                                <>
                                    <LoaderComponent size={40} />
                                    <p>Analyzing note content...</p>
                                </>
                            ) : (
                                <div className="ai-feedback-content">
                                    {aiFeedback && aiFeedback.split('\n\n')[0] && (
                                        <p className="feedback-summary">{aiFeedback.split('\n\n')[0]}</p>
                                    )}
                                    
                                    {formatFeedbackContent(aiFeedback).map((item, index) => (
                                        <div key={index} className={`feedback-item ${item.className}`}>
                                            <div className="feedback-title">
                                                {item.icon} {item.type}
                                            </div>
                                            <div className="feedback-content">
                                                {item.text}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NoteCardActions;

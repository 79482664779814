import { useState } from "react"

const Accordion = ({ items }) => {
  const [openIndex, setOpenIndex] = useState(null)

  const toggleItem = (index) => {
    setOpenIndex(openIndex === index ? null : index)
  }

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div key={index} className={`accordion__item ${openIndex === index ? "accordion__item--open" : ""}`}>
          <button className="accordion__trigger" onClick={() => toggleItem(index)} aria-expanded={openIndex === index}>
            {item.question}
            <svg
              className="accordion__icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </button>
          <div className="accordion__content">
            <div className="accordion__content-inner">{item.answer}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Accordion


import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext, LoadingContext } from "../../Context/AppContext";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { handleLogin } from '@linko/shared_utils';
import { GoogleLogin } from '@react-oauth/google';
import analytics, { EVENT_TYPES } from "../../services/analytics.js";
import { handleGoogleAuthSuccess, handleBetaCodeSubmission } from "../../utils/googleAuthHelpers";
import BetaCodeModal from "../../Components/BetaCodeModal";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const { setIsAuthenticated } = useContext(AuthContext);
    const { setIsLoading } = useContext(LoadingContext);
    const navigate = useNavigate();
    const [loginStatus, setLoginStatus] = useState("");
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showBetaCodeModal, setShowBetaCodeModal] = useState(false);
    const [betaCode, setBetaCode] = useState('');
    const [betaCodeError, setBetaCodeError] = useState('');
    const [pendingGoogleCredential, setPendingGoogleCredential] = useState(null);
    const [googleLoginState, setGoogleLoginState] = useState({
        isLoading: false,
        error: null,
        requiresBetaCode: false
    });

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Activation link error handling
    useEffect(() => {
        const linkError = localStorage.getItem("linkError");
        if (linkError) {
            setIsError(true);
            setErrorMessage(linkError);
            setTimeout(() => {
                localStorage.removeItem("linkError");
            }, 5000);
        }
    }, []);

    // Activation success notice
    useEffect(() => {
        const successNotice = localStorage.getItem("successNotice");
        if (successNotice) {
            setIsError(false);
            setLoginStatus(successNotice);
            setTimeout(() => {
                localStorage.removeItem("successNotice");
            }, 5000);
        }
    }, []);

    useEffect(() => {
        const sessionExpiredMessage = localStorage.getItem("session_expired");
        if (sessionExpiredMessage) {
            setIsError(true);
            setLoginStatus(sessionExpiredMessage);
            localStorage.removeItem("session_expired");
        }
    }, []);

    const handleGoogleSuccess = async (credentialResponse) => {
        await handleGoogleAuthSuccess(
            credentialResponse,
            setIsError,
            setLoginStatus,
            setPendingGoogleCredential,
            setShowBetaCodeModal,
            setGoogleLoginState,
            setIsAuthenticated,
            setIsLoading,
            navigate,
            'login'
        );
    };

    const onLoginPress = async () => {
        try {
            if (email === null || email === undefined || password === null || password === undefined) {
                throw new Error("Email or password is missing");
            }
            
            await handleLogin(email, password, setIsError, setLoginStatus);
            
            // If login is successful (no error thrown), track in Amplitude
            if (!isError && loginStatus === "Login successful") {
                analytics.trackEvent(EVENT_TYPES.LOGIN, {
                    method: 'email',
                    status: 'success',
                    timestamp: new Date().toISOString()
                });
            }
        } catch (error) {
            // Track login error in Amplitude
            analytics.trackEvent(EVENT_TYPES.ERROR, {
                action: 'login',
                method: 'email',
                status: 'error',
                errorMessage: error.message || 'Login failed',
                timestamp: new Date().toISOString()
            });
            
            if (error.message === "Broken pipe") {
                console.error("Connection lost");
            } else {
                console.error(error);
            }
        } 
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await onLoginPress();
    };

    useEffect(() => {
        if (loginStatus === "Login successful") {
            setIsLoading(true);
            setIsAuthenticated(true);
            navigate("/my_linko");
        }
    }, [loginStatus]);

    const handleBetaCodeSubmit = async () => {
        await handleBetaCodeSubmission(
            pendingGoogleCredential,
            betaCode,
            setIsError,
            setLoginStatus,
            setGoogleLoginState,
            setBetaCode,
            setShowBetaCodeModal,
            setIsAuthenticated,
            navigate,
            'login'
        );
    };

    return (
        <div className="login">
            <div className="login-form-container">
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className="login-form-content">
                        <h3 className="welcome-back">Welcome Back!</h3>
                        <p style={{marginBottom:'15px', color:'var(--primary-color)'}}>{loginStatus}</p>
                        <div className="login-form-group">
                            <label className="login-form-label">EMAIL</label>
                            <input
                                className="login-form-control"
                                // placeholder="Enter email"
                                name="email"
                                type="email"
                                value={email}
                                required
                                autoComplete="email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="login-form-group">
                            <label className="login-form-label">PASSWORD</label>
                            <div className="password-input">
                                <input
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    className="login-form-control"
                                    // placeholder="Enter password"
                                    value={password}
                                    autoComplete="current-password"
                                    required
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    style={{ paddingRight: "50px" }}
                                />
                                <div
                                    onClick={togglePasswordVisibility}
                                    className="password-visibility-icon"
                                >
                                    {showPassword ? (
                                        <HiOutlineEyeOff />
                                    ) : (
                                        <HiOutlineEye />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="login-form-footer">
                            <p className='login-status error-notice'>{errorMessage}</p>
                            <div className="login-form-footer-right">
                                <div className="login-form-link">
                                    <p>
                                        Don't have an account?{" "}
                                        <Link to="/register">Register</Link>
                                    </p>
                                    <Link to="/forget_password">
                                        Forgot password?
                                    </Link>
                                </div>
                                <button className="linko-button linko-button--primary" type="submit">
                                    Log in
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="google-login-container">
                        <GoogleLogin
                            text="signin_with"
                            onSuccess={handleGoogleSuccess}
                            onError={() => {
                                setIsError(true);
                                setLoginStatus('Google login failed');
                            }}
                        />
                    </div>
                </form>

            </div>
            {showBetaCodeModal && (
                <BetaCodeModal
                    betaCode={betaCode}
                    setBetaCode={setBetaCode}
                    googleLoginState={googleLoginState}
                    isLoading={googleLoginState.isLoading}
                    onCancel={() => {
                        setShowBetaCodeModal(false);
                        setPendingGoogleCredential(null);
                        setBetaCode('');
                        setGoogleLoginState({ isLoading: false, error: null });
                    }}
                    onSubmit={() => {
                        setGoogleLoginState({ isLoading: true, error: null });
                        handleBetaCodeSubmit();
                    }}
                />
            )}
        </div>
    );
}

export default Login;

import React, {useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../Components/ToastNotification';
import { AuthContext, UserContext } from '../../Context/AppContext';
import { GoPersonAdd } from "react-icons/go";
import { sendFollowRequest, handleFollowAction, fetchUserFollow } from '@linko/shared_utils';
import { checkFollowStatus } from '../../utils/FollowStatusCheck';
import CustomAvatar from '../../Components/CustomAvatar';
import { useModal } from '../../Context/ModalContext';

const PublicProfileHeader = ({
    userId,
    userFirstName,
    userLastName,
    privateAccount,
    followings,
    followers,
    requesting,
}) => {
    
    const {isAuthenticated} = useContext(AuthContext);
    const {userInfo} = useContext(UserContext);
    const {requestConfirm} = useModal();
    const [friendStatus, setFriendStatus] = useState(null);
    const [followStatus, setFollowStatus] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonAction, setButtonAction] = useState('follow');
    const [confirmMessage, setConfirmMessage] = useState('');
    const [comparingDone, setComparingDone] = useState(false);
    const navigate = useNavigate();
    const {addToast} = useToast();

    useEffect(() => {
        if (isAuthenticated && userInfo && userId !== null && userFirstName !== null && followers !== null && followings !== null && requesting !== null) {
            try {
                const status = checkFollowStatus(userId, userFirstName, followers, followings, requesting);
                setFriendStatus(status.friendStatus);
                setFollowStatus(status.followStatus);
                setButtonDisabled(status.buttonDisabled);
                setButtonAction(status.buttonAction);
                setConfirmMessage(status.confirmMessage);
            } catch (error) {
            console.error(error);
            } finally {
                setComparingDone(true);
            }
        } else {
            setFriendStatus('Login to follow');
            setFollowStatus('Request follow');
            setButtonDisabled(false);
            setButtonAction('navigate');
            setConfirmMessage(null);
            setComparingDone(true);
        }
    }, [userId, userFirstName, followers, followings, requesting, userInfo, isAuthenticated]);

    const handleButtonClick = async () => {
        if (!isAuthenticated || buttonAction === 'navigate') {
            navigate('/login');
            return;
        }

        if (userInfo?.private_account) {
            addToast('You cannot follow users while in private mode. Please switch to public mode in settings.', {appearance: 'error'});
            return;
        }

        if (buttonAction === 'unfollow') {
            requestConfirm(
                confirmMessage, 
                async () => {
                try {
                    const response = await handleFollowAction(userId, 'unfollow');
                    if (response.status === 200) {
                        addToast('Successfully unfollowed user', {appearance: 'success'});
                        fetchUserFollow();
                    } else {
                        window.alert('Failed to unfollow user, please try again.');
                    }
                } catch (error) {
                    console.error(error);
                        window.alert('Failed to unfollow user, please try again.');
                    }
                },
                null,
                'Unfollow'
            );
        } else if (buttonAction === 'follow') {
            try {
                const response = await sendFollowRequest(userId);
                if (response.status === 200) {
                    addToast('Follow request sent', {appearance: 'success'});
                    fetchUserFollow();
                } else {
                    window.alert('Failed to send follow request, please try again.');
                }
            } catch (error) {
                console.error(error);
                window.alert('Failed to send follow request, please try again.');
            }
        }
    };

    return (
        <div className='profile-left-header'>
            {userFirstName && comparingDone &&
                <>
                    <div className='header-group'>
                        <CustomAvatar size={45} firstName={userFirstName} lastName={userLastName} />
                        <div className='user-name-group'>
                            <h1 className='subject-name'>{userFirstName} {userLastName}</h1>
                            {friendStatus && <p>{friendStatus}</p>}
                        </div>
                        {!privateAccount && (
                            <button 
                                className={`add-friend-icon linko-button linko-button--secondary ${buttonDisabled || userInfo?.private_account ? 'linko-button--secondary-outline' : ''}`} 
                                title={userInfo?.private_account ? 'Cannot follow users in private mode' : buttonAction === 'follow' ? 'Request follow' : buttonAction === 'unfollow' ? 'Unfollow' : 'Pending'} 
                                onClick={handleButtonClick}
                                disabled={buttonDisabled || userInfo?.private_account}
                                style={userInfo?.private_account ? { cursor: 'not-allowed' } : {}}
                            >
                                <GoPersonAdd />
                                <span>{followStatus}</span>
                            </button>
                        )}
                    </div>
                    {userInfo?.private_account && !buttonDisabled && !privateAccount && (
                        <div style={{
                            backgroundColor: '#fff3cd',
                            color: '#856404',
                            padding: '10px',
                            borderRadius: '4px',
                            marginTop: '10px',
                            fontSize: '14px',
                            maxWidth: '620px',
                        }}>
                            You are currently in private mode. To follow other users, please switch to public mode in your settings.
                        </div>
                    )}
                </>
            }
        </div>
    );
};

export default PublicProfileHeader;
import React from 'react';

const DeleteConfirmModal = ({ isOpen, message, confirmAction = 'Confirm', onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onCancel}>
      <div className="info-modal-content" onClick={(e) => e.stopPropagation()}>
        <p>{message}</p>
        <div className="modal-buttons">
            <button onClick={onCancel} className='linko-button linko-button--cancel'>Cancel</button>
            <button onClick={onConfirm} className='linko-button linko-button--danger'>{confirmAction}</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmModal;

import Logo from '../../../../static/Logo/Linko_Logo_LG_WB.png'

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__grid">
          <div className="footer__brand">
            <div className="footer__logo">
              <img src={Logo} alt="Linko Logo" className="footer__logo-icon" />
              <span>Linko</span>
            </div>
            <p>Your AI-powered knowledge ecosystem for effortless organization, retention, and discovery.</p>
          </div>

          <div className="footer__links">
            <h3>Product</h3>
            <ul>
              {["Features", "Pricing", "Testimonials", "FAQ"].map((item, i) => (
                <li key={i}>
                  <a href={`#${item.toLowerCase()}`}>{item}</a>
                </li>
              ))}
            </ul>
          </div>

          <div className="footer__links">
            <h3>Resources</h3>
            <ul>
              {[
                { name: "Contact", link: "mailto:linko.assistant@gmail.com" }
              ].map((item, i) => (
                <li key={i}>
                  <a href={item.link}>{item.name}</a>
                </li>
              ))}
            </ul>
          </div>

          <div className="footer__links">
            <h3>Legal</h3>
            <ul>
              {[
                { name: "Terms of Service", link: "/term_of_service" },
                { name: "Privacy Policy", link: "/privacy_policy" },
                { name: "Cookie Policy", link: "/cookie_policy" },
              ].map((item, i) => (
                <li key={i}>
                  <a href={item.link}>{item.name}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="footer__bottom">
          <p>© {currentYear} Linko. All rights reserved. Build your personal knowledge ecosystem.</p>
          <div className="footer__badges">
            <span className="badge badge--small">Beta</span>
            <span className="badge badge--small">AI-Powered</span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer


import React, {useState, useEffect, useContext} from 'react';
import { AuthContext } from '../../Context/AppContext';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import NoteCard from '../../Components/NoteCard/NoteCard';
import BackButton from '../../Components/BackButton';
import TabNotes from '../../Components/ContentTabs/TabNotes';
import { getSingleNote, fetchNotes } from '@linko/shared_utils';
import { PiNotePencilBold } from "react-icons/pi";


const NotePage = () => {
    const [note, setNote] = useState(null);
    const [privateNote, setPrivateNote] = useState(false);
    const [noteMessage, setNoteMessage] = useState({ title: '', content: '' });
    const { isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const noteId = params.noteId;

    const [relatedNotes, setRelatedNotes] = useState([]);
    const [isFetchingMoreNotes, setIsFetchingMoreNotes] = useState(false);
    const [hasMoreNotes, setHasMoreNotes] = useState(true);


    const fetchRelatedNotes = async () => {
        if (!hasMoreNotes || !isAuthenticated) {
            setIsFetchingMoreNotes(false);
            return;
        }
        try {
          await fetchNotes(10, relatedNotes.length, null, noteId, relatedNotes, setRelatedNotes, setHasMoreNotes, setIsFetchingMoreNotes, [], null);
        } catch (error) {
          console.error('Error fetching related notes:', error);
          setIsFetchingMoreNotes(false);
        }
    };
    
    const handleScrollEvent = () => {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const scrollHeight = document.documentElement.scrollHeight;
        const clientHeight = window.innerHeight;

        if (scrollTop + clientHeight >= scrollHeight - 100) {
            setIsFetchingMoreNotes(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScrollEvent);
        return () => {
            window.removeEventListener('scroll', handleScrollEvent);
        };
    }, []);
    
    useEffect(() => {
        if (isFetchingMoreNotes) {
            fetchRelatedNotes();
        }
    }, [isFetchingMoreNotes]);

    useEffect(() => {
        setNote(null);
        setRelatedNotes([]);
        setHasMoreNotes(true);
        setIsFetchingMoreNotes(true);
        const fetchNote = async () => {
            const response = await getSingleNote(noteId);
            if (response.status === 404) {
                setPrivateNote(true);
                setNoteMessage({ title: 'Note does not exist', content: 'This note does not exist or has been deleted.' });
            } else if (response.status === 203) {
                setPrivateNote(true);
                setNoteMessage({ title: 'Private Note', content: 'This note is private and can only be viewed by the owner.' });
            } else if (response.status === 200) {
                setNote(response.data.note);
            }
        };
        fetchNote();
    }, [noteId, useParams, location]);

    if (privateNote) {
        return (
            <div className='note-page-container'>
                <BackButton />
                <div className='note-card-wrapper' style={{marginTop: '10px'}}>
                    <div className='note-card' style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', rowGap:'10px'}}>
                        <h1 className='note-title'>{noteMessage.title}</h1>
                        <p className='note-content-text' style={{textAlign:'center', height:'fit-content'}}>{noteMessage.content}</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='note-page-container'>
            <div className='note-page-header'>
                <BackButton />
                {note && <h1 className='subject-name note-page-title'><span style={{fontWeight:'600'}} onClick={() => navigate(`/profile/${note.user.id}`)}>{note.user.first_name} {note.user.last_name}</span>'s note</h1>}
            </div>
            {note && <NoteCard note={note} shareView={true} currentNote={true}/>}
            {isAuthenticated && (
                <>
                <div className='note-page-tabs'>
                    <button className='tab-button active' >
                        <span className="button-span"><PiNotePencilBold size={22}/> My Related Notes</span>
                    </button>
                </div>
                <TabNotes 
                    subjectId={null} 
                    notes={relatedNotes}
                    setNotes={setRelatedNotes}
                    isFetchingMoreNotes={isFetchingMoreNotes}
                    hasMoreNotes={hasMoreNotes}
                />
                </>
            )}
        </div>
    );
};

export default NotePage;

const Button = ({ children, primary, outline, light, fullWidth, onClick }) => {
  const classes = [
    "button",
    primary ? "button--primary" : "",
    outline ? "button--outline" : "",
    light ? "button--light" : "",
    fullWidth ? "button--full-width" : "",
  ]
    .filter(Boolean)
    .join(" ")

  return (
    <button className={classes} onClick={onClick}>
      {children}
    </button>
  )
}

export default Button


import React, { createContext, useState, useEffect, useContext } from 'react';
import { UserContext, AuthContext } from './AppContext';
import { fetchRevisitData, fetchSingleResource, getSingleNote, fetchMyFeed, fetchFollowingFeed } from '@linko/shared_utils';
import { SideBarContext } from './SideBarContext';

export const HomePageContext = createContext();

export const HomePageProvider = ({ children }) => {
    
    const { userNoteCount, fetchUserSubjectData, fetchUserInfo, userInfo } = useContext(UserContext);
    const { isAuthenticated } = useContext(AuthContext);
    const { refreshSidebarResources } = useContext(SideBarContext);

    const [revisitSubject, setRevisitSubject] = useState('');
    const [revisitNote, setRevisitNote] = useState([]);
    const [isShufflingSubject, setIsShufflingSubject] = useState(false);

    const [initialRevisitFetchDone, setInitialRevisitFetchDone] = useState(false);

    useEffect(() => {
        if (!isAuthenticated) {
            setInitialRevisitFetchDone(false);
            setHomePageState({
                activeTab: 'library',
                scrollPositions: {
                    library: 0,
                    revisit: 0,
                    explore: 0
                },
                library: [],
                explore: [],
                hasMoreLibrary: true,
                hasMoreExplore: true,
                isFetchingMoreLibrary: false,
                isFetchingMoreExplore: false,
                initialLoadDone: false,
                firstLoadComplete: false
            });
            setRevisitSubject('');
            setRevisitNote([]);
            setIsShufflingSubject(false);
        }
        // Trigger shuffling only on initial login or when adding first note
        else if (isAuthenticated && userNoteCount !== null && !initialRevisitFetchDone) {
            setIsShufflingSubject(true);
            setInitialRevisitFetchDone(true);
        }
    }, [isAuthenticated, userNoteCount, initialRevisitFetchDone]);

    useEffect(() => {
        if (isAuthenticated && userNoteCount !== null && isShufflingSubject) {
            if (userNoteCount >= 1) {
                fetchRevisitData(setRevisitSubject, setRevisitNote, setIsShufflingSubject);
            } else {
                setRevisitSubject({
                    id: null,
                    name: 'Linko Ignite',
                    is_linked: true,
                    qa: {
                        question: '👋 What is Ignite?',
                        answer: '✨ Ignite is a feature that helps you recall your thoughts by AI generated questions and answers. The more notes you add, the more powerful it becomes.'
                    }
                });
                setIsShufflingSubject(false);
            }
        }
    }, [isShufflingSubject, userNoteCount, isAuthenticated]);

    const [homePageState, setHomePageState] = useState({
        activeTab: 'library',
        scrollPositions: {
            library: 0,
            revisit: 0,
            explore: 0
        },
        library: [],
        explore: [],
        hasMoreLibrary: true,
        hasMoreExplore: true,
        isFetchingMoreLibrary: false,
        isFetchingMoreExplore: false,
        initialLoadDone: false,
        firstLoadComplete: false
    });

    useEffect(() => {
        if (!homePageState.firstLoadComplete && userNoteCount !== null) {
            setHomePageState(prev => ({
                ...prev,
                activeTab: 'library',
                firstLoadComplete: true
            }));
        }
    }, [userNoteCount, homePageState.firstLoadComplete]);

    const limit = 10;
    
    useEffect(() => {
        if (isAuthenticated && !homePageState.initialLoadDone) {
            setHomePageState(prev => ({
                ...prev,
                isFetchingMoreLibrary: true,
                isFetchingMoreExplore: true,
                initialLoadDone: true
            }));
        }
    }, [isAuthenticated]);

    useEffect(() => {
        const fetchLibrary = async () => {
            if (!isAuthenticated || !homePageState.isFetchingMoreLibrary) return;
            if (homePageState.isFetchingMoreLibrary) {
                // Use the current library array length as the offset
                const currentOffset = homePageState.library.length;
                const response = await fetchMyFeed(
                    limit, currentOffset, null, null, null, null, null, null);
                if (Array.isArray(response.data.results)) {
                    setHomePageState(prev => ({
                        ...prev,
                        library: [...prev.library, ...response.data.results],
                        hasMoreLibrary: response.data.results.length === limit,
                        isFetchingMoreLibrary: false
                    }));
                }
            }
        };

        fetchLibrary();

    }, [homePageState.isFetchingMoreLibrary, isAuthenticated]);

    useEffect(() => {
        const fetchExplore = async () => {
            if (!isAuthenticated || !homePageState.isFetchingMoreExplore) return;
            try {
                // Use the current explore array length as the offset
                const currentOffset = homePageState.explore.length;
                const response = await fetchFollowingFeed(
                    limit, currentOffset, null, null, null, null, null);
                if (Array.isArray(response.data.results)) {
                    setHomePageState(prev => ({
                        ...prev,
                        explore: [...prev.explore, ...response.data.results],
                        hasMoreExplore: response.data.results.length === limit,
                        isFetchingMoreExplore: false
                    }));
                }
            } catch (error) {
                console.error('Error fetching explore feed:', error);
                setHomePageState(prev => ({
                    ...prev,
                    isFetchingMoreExplore: false
                }));
            }
        };
        if (userInfo && !userInfo.private_account) {
            fetchExplore();
        }
    }, [homePageState.isFetchingMoreExplore, isAuthenticated, userInfo]);

    // Add a simple refresh function
    const refreshLibrary = () => {
        if (!isAuthenticated) return;

        setHomePageState(prev => {
            if (prev.isFetchingMoreLibrary) return prev;
            return {
                ...prev,
                library: [],
                hasMoreLibrary: true,
                isFetchingMoreLibrary: true
            };
        });
    };

    const refreshExplore = () => {
        if (!isAuthenticated) return;
        
        setHomePageState(prev => {
            if (prev.isFetchingMoreExplore) return prev;
            return {
                ...prev,
                explore: [],
                hasMoreExplore: true,
                isFetchingMoreExplore: true
            };
        });
    };

    const updateSingleResourceInLibrary = async (resourceId) => {
        if (!isAuthenticated) return;
        if (!Array.isArray(homePageState.library)) {
            console.warn('library is not an array:', homePageState.library);
            return;
        }
        const existingResourceIndex = homePageState.library.findIndex(
            item => item.resource?.id === resourceId
        );
        if (existingResourceIndex !== -1) {
            const updatedResource = await fetchSingleResource(resourceId);
            setHomePageState(prevState => ({
                ...prevState,
                library: Array.isArray(prevState.library)
                    ? prevState.library.map((item) => 
                        item.resource?.id === resourceId ? updatedResource.resource : item
                    )
                    : [updatedResource]
            }));
        }
    };

    const updateSingleNoteInLibrary = async (noteId) => {
        if (!isAuthenticated) return;
        const existingNoteIndex = homePageState.library.findIndex(
            item => item.id === noteId
        );
        if (existingNoteIndex !== -1) {
            try {
                const response = await getSingleNote(noteId);
                const updatedNote = response.data.note;
                setHomePageState(prevState => ({
                    ...prevState,
                    library: Array.isArray(prevState.library)
                        ? prevState.library.map((item) => 
                            item.id === noteId ? updatedNote : item
                        )
                        : [updatedNote]
                }));
            } catch (error) {
                console.error('Error updating note in library:', error);
            }
        }
    };

    const addNoteToLibrary = async (note) => {
        if (!isAuthenticated) return;
        setHomePageState(prevState => ({
            ...prevState,
            library: Array.isArray(prevState.library) 
                ? [note, ...prevState.library]
                : [note]
        }));
        await fetchUserSubjectData();
        await fetchUserInfo();
        if (note.resource.length > 0) {
            await refreshSidebarResources();
        }
    };

    const addResourceToLibrary = async (resource) => {
        if (!isAuthenticated) return;
        setHomePageState(prevState => ({
            ...prevState,
            library: Array.isArray(prevState.library) 
                ? [resource, ...prevState.library]
                : [resource]
        }));
        await fetchUserSubjectData();
        await fetchUserInfo();
        await refreshSidebarResources();
    };

    const removeItemFromHomePage = (itemId, type) => {
        if (type === 'note') {
            setHomePageState(prevState => ({
                ...prevState,
                library: prevState.library.filter(item => !item.note || item.id !== itemId)
            }));
            setRevisitNote(prevState => prevState.filter(item => item.id !== itemId));
        } else if (type === 'resource') {
            setHomePageState(prevState => ({
                ...prevState,
                library: prevState.library.filter(item => item.note || item.resource.id !== itemId)
            }));
        }
    };

    const updateLibraryArray = (newLibrary) => {
        setHomePageState(prevState => ({
            ...prevState,
            library: typeof newLibrary === 'function' 
                ? newLibrary(prevState.library) 
                : newLibrary
        }));
    };

    const updateExploreArray = (newExplore) => {
        setHomePageState(prevState => ({
            ...prevState,
            explore: typeof newExplore === 'function' 
                ? newExplore(prevState.explore) 
                : newExplore
        }));
    };

    const setIsFetchingMoreLibraryHomePage = (value) => {
        setHomePageState(prevState => ({
            ...prevState,
            isFetchingMoreLibrary: value
        }));
    };

    const setIsFetchingMoreExploreHomePage = (value) => {
        setHomePageState(prevState => ({
            ...prevState,
            isFetchingMoreExplore: value
        }));
    };
    
    return (
        <HomePageContext.Provider value={{ 
            homePageState, 
            setHomePageState, 
            refreshLibrary, 
            refreshExplore,
            
            updateLibraryArray,
            updateExploreArray,
            setIsFetchingMoreLibraryHomePage,
            setIsFetchingMoreExploreHomePage,

            revisitSubject, 
            revisitNote, 
            setRevisitNote, 
            isShufflingSubject, 
            setIsShufflingSubject, 

            addNoteToLibrary,
            addResourceToLibrary,
            updateSingleNoteInLibrary,
            updateSingleResourceInLibrary,
            removeItemFromHomePage
        }}>
            {children}
        </HomePageContext.Provider>
    );
};

import React, { createContext, useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useModal } from './ModalContext.jsx';

export const NavigationPromptContext = createContext();

export const NavigationPromptProvider = ({ children }) => {
	const [unsavedEditors, setUnsavedEditors] = useState(new Set());
	const navigate = useNavigate();
	const location = useLocation();
	const { requestConfirm } = useModal();
	const isNavigatingRef = useRef(false);

	// Handle browser tab/window closing
	const handleBeforeUnload = useCallback((event) => {
		if (unsavedEditors.size > 0) {
			event.preventDefault();
			event.returnValue = '';
		}
	}, [unsavedEditors]);

	// Handle browser back/forward buttons
	const handlePopState = useCallback(async (event) => {
		if (isNavigatingRef.current) {
			return;
		}

		if (unsavedEditors.size > 0) {
			event.preventDefault();
			const confirmed = await new Promise(resolve => {
				requestConfirm(
					'You have unsaved changes. Are you sure you want to leave?',
					() => resolve(true),
					() => resolve(false)
				);
			});

			if (confirmed) {
				isNavigatingRef.current = true;
				setUnsavedEditors(new Set());
				navigate(-1);
			}
		}
	}, [unsavedEditors, navigate, requestConfirm]);

	useEffect(() => {
		window.addEventListener('beforeunload', handleBeforeUnload);
		window.addEventListener('popstate', handlePopState);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
			window.removeEventListener('popstate', handlePopState);
		};
	}, [handleBeforeUnload, handlePopState]);

	// Reset isNavigatingRef after location changes
	useEffect(() => {
		isNavigatingRef.current = false;
	}, [location]);

	const safeNavigate = useCallback((to, options = {}) => {

		if (isNavigatingRef.current) {
			return;
		}

		if (to === location.pathname) {
			return;
		}

		const performNavigation = () => {
			isNavigatingRef.current = true;
			setUnsavedEditors(new Set());
			navigate(to, options);
		};

		if (unsavedEditors.size > 0) {
			requestConfirm(
				'You have unsaved changes. Are you sure you want to leave?',
				() => {
					performNavigation();
				}
			);
		} else {
			performNavigation();
		}
	}, [unsavedEditors, location.pathname, navigate, requestConfirm]);

	const registerEditorChanges = useCallback((editorId, hasChanges) => {
		setUnsavedEditors(prev => {
			const newSet = new Set(prev);
			if (hasChanges) {
				newSet.add(editorId);
			} else {
				newSet.delete(editorId);
			}
			return newSet;
		});
	}, []);

	return (
		<NavigationPromptContext.Provider value={{ 
			hasUnsavedChanges: unsavedEditors.size > 0,
			registerEditorChanges,
			safeNavigate 
		}}>
			{children}
		</NavigationPromptContext.Provider>
	);
};
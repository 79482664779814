import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../Context/AppContext';
import { useLocation } from 'react-router-dom';
import { HomePageContext } from '../../Context/HomePageContext';
// Components
import TabLibrary from './TabLibrary';
import TabExplore from './TabExplore';
import TabRevisit from './TabRevisit';
// Functions
import { handleScrollEvent, setupScrollListeners } from './handleScroll';
import { setupTabs } from './tabManagement';

const TheTabs = ({
        subjectId,
        publicField,
        resource,
        resourceId,
        resourceType,
        library,
        setLibrary,
        explore,
        setExplore,
        isFetchingMoreLibrary,
        isFetchingMoreExplore,
        hasMoreLibrary,
        hasMoreExplore,
        setIsFetchingMoreLibrary,
        setIsFetchingMoreExplore,
        noteHint,
        showTextArea,
        setShowTextArea,
    }) => {

    const location = useLocation();
    const { newFeedCount, setNewFeedCount, fetchNotifCount, userNoteCount, userInfo } = useContext(UserContext);
    const homePage = location.pathname.startsWith('/my_linko');
    const resourcePage = location.pathname.startsWith('/r/');
    const { homePageState, setHomePageState, refreshExplore } = useContext(HomePageContext);

    // Tab state
    const [activeTab, setActiveTab] = useState(() => {
        if (homePage && homePageState.activeTab) {
            return homePageState.activeTab;
        }
        return 'library';
    });

    const [tabNames, setTabNames] = useState({ first: null, second: null, third: null });

    // Setup effects
    useEffect(() => {
        setupTabs(location, setTabNames, activeTab);
    }, [location.pathname, activeTab]);

    useEffect(() => {
        if (userInfo && !userInfo.private_account) {
            fetchNotifCount();
        }
    }, [userInfo]);

    useEffect(() => {
        if (homePage && activeTab === 'explore' && newFeedCount > 0) {
            refreshExplore();
            setTimeout(() => {
                setNewFeedCount(0);
            }, 1000);
        }
    }, [activeTab, homePage, newFeedCount, refreshExplore]);

    // Update tab if home page state changes
    useEffect(() => {
        if (homePage && homePageState.activeTab && homePageState.activeTab !== activeTab) {
            setActiveTab(homePageState.activeTab);
        }
    }, [homePageState.activeTab, homePage]);

    // Scroll position management
    useEffect(() => {
        if (homePage) {
            requestAnimationFrame(() => {
                const scrollPosition = homePageState.scrollPositions[activeTab] || 0;
                window.scrollTo(0, scrollPosition);
            });
        } else {
            window.scrollTo(0, 0);
        }
    }, [activeTab, homePage]);

    useEffect(() => {
        const cleanup = setupScrollListeners(
            handleScrollEvent,
            activeTab,
            null, 
            null, 
            setIsFetchingMoreExplore,
            setIsFetchingMoreLibrary,
            false, 
            false, 
            hasMoreExplore,
            hasMoreLibrary
        );
    
        return () => {
            cleanup();
        };
    }, [
        activeTab,
        hasMoreLibrary,
        hasMoreExplore,
        setIsFetchingMoreLibrary,
        setIsFetchingMoreExplore
    ]);

    useEffect(() => {
        let lastScrollTop = 0;
        
        const handleNavbarScroll = (currentScroll) => {
            const navbar = document.querySelector('.tabs-wrapper');
            if (navbar) {
                if (currentScroll > lastScrollTop) {
                    navbar.style.top = '0px'; 
                } else {
                    navbar.style.top = '70px';
                }
                lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
            }
        };

        const handleScroll = () => {
            const currentScroll = window.scrollY || document.documentElement.scrollTop;

            handleNavbarScroll(currentScroll);
            
            // Handle scroll position saving for homepage
            if (homePage) {
                setHomePageState(prev => ({
                    ...prev,
                    scrollPositions: {
                        ...prev.scrollPositions,
                        [activeTab]: currentScroll
                    }
                }));
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [homePage, activeTab]);

    // Save state to context for homepage
    useEffect(() => {
        if (homePage) {
            setHomePageState(prev => ({
                ...prev,
                activeTab,
                scrollPositions: {
                    ...prev.scrollPositions,
                    [activeTab]: window.scrollY
                }
            }));
        }
    }, [activeTab, homePage]);

    return (
        <div className='the-tabs'>
                {(homePage || (!userInfo?.private_account && (resourcePage || publicField))) && (
                    <div className="tabs-wrapper">
                        <div className='tabs'>
                            <button
                                className={`tab-button ${activeTab === 'library' ? 'active' : ''}`}
                                onClick={() => setActiveTab('library')}
                            >
                                {tabNames.first}
                            </button>
                            {(publicField || resourcePage || homePage) && (
                                <>
                                    {homePage && userNoteCount > 0 && (
                                        <button
                                            className={`tab-button ${activeTab === 'revisit' ? 'active' : ''}`}
                                            onClick={() => setActiveTab('revisit')}
                                        >
                                            <div style={{ position: 'relative' }}>
                                                {tabNames.second}
                                            </div>
                                        </button>
                                    )}
                                    {userInfo && !userInfo.private_account && userInfo.has_following && (
                                        (homePage || !(resourcePage || publicField) || (explore && explore.length > 0)) && (
                                            <button
                                                className={`tab-button ${activeTab === 'explore' ? 'active' : ''}`}
                                                onClick={() => setActiveTab('explore')}
                                            >
                                                <div style={{ position: 'relative' }}>
                                                    {homePage ? tabNames.third : tabNames.second}
                                                    {homePage && newFeedCount > 0 && (
                                                        <div className='new-feed-count' />
                                                    )}
                                                </div>
                                            </button>
                                        )
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )}
                
            <div className="tab-content">
                {activeTab === 'library' ? (
                    <div id='tab-library' 
                        className={`tab ${activeTab === 'library' ? 'active' : 'hidden'}`}
                        style={{ display: activeTab === 'library' ? 'block' : 'none' }}>
                        <TabLibrary
                            library={library}
                            setLibrary={setLibrary}
                            isFetchingMoreLibrary={isFetchingMoreLibrary}
                            hasMoreLibrary={hasMoreLibrary}
                            noteHint={noteHint}
                            showTextArea={showTextArea}
                            setShowTextArea={setShowTextArea}
                            resource={resource}
                            subjectId={subjectId}
                        />
                    </div>
                ) : activeTab === 'revisit' ? (
                    <div id='tab-revisit' className={`tab ${activeTab === 'revisit' ? 'active' : 'hidden'}`} >
                        <TabRevisit />
                    </div>
                ) : activeTab === 'explore' ? (
                    <div id='tab-explore' 
                        className={`tab ${activeTab === 'explore' ? 'active' : 'hidden'}`} 
                        style={{ display: activeTab === 'explore' ? 'block' : 'none' }}>
                        <TabExplore
                            explore={explore}
                            setExplore={setExplore}
                            isFetchingMoreExplore={isFetchingMoreExplore}
                            hasMoreExplore={hasMoreExplore}
                            type={resourceType ? resourceType : 'resource'}
                            resourceId={resourceId}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default TheTabs;

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { activateAccount, resetPassword } from '@linko/shared_utils';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [requirements, setRequirements] = useState({
        length: false,
        lettersAndNumbers: false,
    });
    const navigate = useNavigate();
    const { uidb64, token } = useParams();
    const [resetStatus, setResetStatus] = useState(false);
    const [email, setEmail] = useState('');
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        activateAccount(uidb64, token)
        .then(res => {
            if (res.data.detail === "Email verified") {
                setEmail(res.data.email);
                setResetStatus(true);
            } else { 
                localStorage.setItem('linkError', 'Password reset link is invalid or has expired.');
                navigate('/login');
            }
        })
        .catch((error) => {
            localStorage.setItem('linkError', 'Password reset link is invalid or has expired.');
            navigate('/login');
        });
    }, [uidb64, token]);

    const checkPasswordRequirements = () => {
        setRequirements({
            length: password.length >= 9,
            lettersAndNumbers: /\d/.test(password) && /[a-zA-Z]/.test(password),
        });
    };

    useEffect(() => {
        password &&
            checkPasswordRequirements();
    }, [password]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        checkPasswordRequirements();
        if (!requirements.length || !requirements.lettersAndNumbers) {
            event.preventDefault();
            let alertMessage = 'Cannot submit form because:';
            if (!requirements.length) {
                alertMessage += '\n- Password must contain at least 9 characters.';
            }
            if (!requirements.lettersAndNumbers) {
                alertMessage += '\n- Password must contain both letters and numbers.';
            }
            window.alert(alertMessage);
        }
        else {
            try {
                // Using secure token-based password reset which requires uidb64 and token
                await resetPassword(password, uidb64, token); 
                localStorage.setItem('successNotice', 'Password reset success.');
                navigate('/login');
            } catch (error) {
                setIsError(true);
                console.error('Reset password error:', error);
            }
        }
    };


    if (resetStatus) {
    return (
        <div className='login'>
            <div className='login-form-container'>
                <form onSubmit={handleSubmit} className='login-form'>
                    <div className='login-form-content'>
                        <h3 className="welcome-back">Reset Password</h3>
                        <div className="login-form-group">
                            <input
                                name='password'
                                type="password"
                                className="login-form-control"
                                placeholder="Enter new password"
                                value={password}
                                required
                                onChange={e => setPassword(e.target.value)} />
                            <div className='password-requirement'>
                                <p className={requirements.length ? 'requirement-met' : ''}>
                                    * must contain at least 9 characters.
                                </p>
                                <p className={requirements.lettersAndNumbers ? 'requirement-met' : ''}>
                                    * must contain both letters and numbers.
                                </p>
                            </div>
                        </div>
                        <div className="login-form-footer" style={{justifyContent:'flex-end'}}>
                            <button type="submit" className="linko-button linko-button--primary" onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
    }
};

export default ResetPassword;
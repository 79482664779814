import React, { useContext } from 'react';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { UserContext } from '../../Context/AppContext';
// Icons
import { FaLock } from "react-icons/fa";
import { FiLock, FiThumbsUp } from "react-icons/fi";

import { BiSolidLike } from "react-icons/bi";
import NoteCardActions from './NoteCardActions';

const NoteHeader = ({
    note,
    noteId,
    onEditClick,
    onDelete,
    updateSingleNote,
    noteResource,
    setNoteResource,
    myNotePage,
    noteLikes,
    formattedTime,
    seeNoteLikes,
    shareView,
    currentNote,
}) => {

    const { safeNavigate } = useContext(NavigationPromptContext);
    const { userInfo } = useContext(UserContext);

    const friendProfile = (user) => {
        safeNavigate(`/profile/${user.id}`);
    };
    


    // Extracted time display with author for share view
    const TimeDisplay = () => {
        return (
            <p className='note-formatted-time'>
                {formattedTime}
            </p>
        );
    };
    
    // Likes indicator component
    const LikesIndicator = () => (
        !userInfo?.private_account && !note.private && !shareView && noteLikes?.length > 0 && (
            <div onClick={seeNoteLikes} className='note-likes-indicator'>
                <FiThumbsUp size={13} />
                <p>{noteLikes.length}</p>
            </div>
        )
    );
    
    // Privacy lock indicator
    const PrivacyLock = () => (
        !userInfo?.private_account && note.private && <FiLock size={14} className='note-lock-icon' />
    );
    
    return (
        <div className='note-card-header'>
            <div className='note-last-edited'>
                {!shareView ? (
                    <>
                        <TimeDisplay />
                        <PrivacyLock />
                        <LikesIndicator />
                    </>
                ) : (
                    <TimeDisplay />
                )}
            </div>
            {!shareView && 
                <NoteCardActions 
                    note={note} 
                    noteId={noteId}
                    onEditClick={onEditClick} 
                    onDelete={onDelete}
                    updateSingleNote={updateSingleNote}
                    noteResource={noteResource}
                    setNoteResource={setNoteResource}
                    myNotePage={myNotePage}
                />
            }
        </div>
    );
};

export default NoteHeader;
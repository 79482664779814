import React, { useState, useEffect, useContext } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { register, BETA_CODE, verifyEmail, checkVerificationCode, handleLogin } from '@linko/shared_utils';
import { AuthContext, LoadingContext } from "../../Context/AppContext";
import { useToast } from '../../Components/ToastNotification';
import storageUtils from '@linko/shared_utils/shared_utils/storageUtils';
import axios from '@linko/shared_utils/shared_utils/Middleware/axios';
import analytics, { EVENT_TYPES } from "../../services/analytics.js";
import { GoogleLogin } from '@react-oauth/google';
import { handleGoogleAuthSuccess, handleBetaCodeSubmission } from "../../utils/googleAuthHelpers";
import BetaCodeModal from "../../Components/BetaCodeModal";

const Register = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordInputFocused, setIsPasswordInputFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [requirements, setRequirements] = useState({
      length: false,
      lettersAndNumbers: false,
    });
  const [betaCode, setBetaCode] = useState('');
  const validRegister = requirements.length && requirements.lettersAndNumbers;
  const navigate = useNavigate();
  const [registerStatus, setRegisterStatus] = useState('');
  const [isError, setIsError] = useState(false);
  const { setIsAuthenticated } = useContext(AuthContext);
  const { setIsLoading } = useContext(LoadingContext);
  const { setPersistentMessage } = useToast(); 

  
  // New states for email verification
  const [verificationStep, setVerificationStep] = useState('email'); // 'email', 'verification', 'details'
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const [pendingGoogleCredential, setPendingGoogleCredential] = useState(null);
  const [showBetaCodeModal, setShowBetaCodeModal] = useState(false);
  const [googleLoginState, setGoogleLoginState] = useState({
    isLoading: false,
    error: null,
    requiresBetaCode: false
  });
  
  const checkPasswordRequirements = () => {
      setRequirements({
        length: password.length >= 9,
        lettersAndNumbers: /\d/.test(password) && /[a-zA-Z]/.test(password),
      });
    };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  
  useEffect(() => {
      password &&
      checkPasswordRequirements();
    }, [password]);

  // Handle the verify email button click
  const handleVerifyEmail = async (e) => {
    e.preventDefault();
    if (!email || !validateEmail(email)) {
      setIsError(true);
      setRegisterStatus('Please enter a valid email address.');
      return;
    }
    
    setIsVerifying(true);
    setIsError(false);
    setRegisterStatus('');
    
    try {
      const response = await verifyEmail(email);
      setVerificationStep('verification');
      setRegisterStatus('Verification code sent! Please check your email and enter the 6-digit code below.');
      setIsError(false);
      
      // Track email verification request in Amplitude
      analytics.trackEvent('Email Verification Requested', {
        status: 'sent',
        email: email
      });
    } catch (error) {
      setIsError(true);
      if (error.response?.data?.email?.[0] === 'User with this email already exists') {
        setRegisterStatus('An account with this email already exists.');
      } else {
        setRegisterStatus('Failed to send verification code. Please try again.');
      }
      
      // Track email verification error in Amplitude
      analytics.trackEvent(EVENT_TYPES.ERROR, {
        action: 'email_verification',
        status: 'error',
        errorMessage: error.response?.data?.email?.[0] || 'Failed to send verification code',
        email: email
      });
    } finally {
      setIsVerifying(false);
    }
  };
  
  // Handle verification code submission
  const handleVerifyCode = async (e) => {
    e.preventDefault();
    if (!verificationCode || verificationCode.length !== 6) {
      setIsError(true);
      setRegisterStatus('Please enter a valid 6-digit verification code.');
      return;
    }
    
    setIsVerifying(true);
    setIsError(false);
    setRegisterStatus('');
    
    try {
      const response = await checkVerificationCode(email, verificationCode);
      setVerificationStep('details');
      setRegisterStatus('Email verified successfully! Please complete your registration.');
      setIsError(false);
      
      // Track successful email verification in Amplitude
      analytics.trackEvent('Email Verification Completed', {
        status: 'success',
        email: email
      });
    } catch (error) {
      setIsError(true);
      if (error.response?.data?.detail) {
        setRegisterStatus(error.response.data.detail);
      } else {
        setRegisterStatus('Invalid verification code. Please try again.');
      }
      
      // Track verification code error in Amplitude
      analytics.trackEvent(EVENT_TYPES.ERROR, {
        action: 'verification_code',
        status: 'error',
        errorMessage: error.response?.data?.detail || 'Invalid verification code',
        email: email
      });
    } finally {
      setIsVerifying(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (betaCode !== BETA_CODE) {
      setIsError(true);
      setRegisterStatus('Invalid invitation code.');
      
      // Track invalid beta code in Amplitude
      analytics.trackEvent(EVENT_TYPES.ERROR, {
        action: 'registration',
        status: 'error',
        errorMessage: 'Invalid invitation code',
        email: email
      });
    } else if (betaCode.trim() === '') {
      setIsError(true);
      setRegisterStatus('Please enter a valid invitation code.');
      
      // Track missing beta code in Amplitude
      analytics.trackEvent(EVENT_TYPES.ERROR, {
        action: 'registration',
        status: 'error',
        errorMessage: 'Missing invitation code',
        email: email
      });
    } else {
      checkPasswordRequirements();
      if (!requirements.length || !requirements.lettersAndNumbers) {
        event.preventDefault();
        let alertMessage = 'Cannot submit form because:';
        if (!requirements.length) {
          alertMessage += '\n- Password must contain at least 9 characters.';
        }
        if (!requirements.lettersAndNumbers) {
          alertMessage += '\n- Password must contain both letters and numbers.';
        }
        window.alert(alertMessage);
        
        // Track password requirement error in Amplitude
        analytics.trackEvent(EVENT_TYPES.ERROR, {
          action: 'registration',
          status: 'error',
          errorMessage: 'Password requirements not met',
          email: email,
          requirements: {
            length: requirements.length,
            lettersAndNumbers: requirements.lettersAndNumbers
          }
        });
      } else {
        try {
          setIsSubmitting(true);
          const response = await register(firstName, lastName, email, password);
          if (response.status === 201) {
            analytics.trackEvent(EVENT_TYPES.SIGNUP, {
              method: 'email',
              status: 'success',
              timestamp: new Date().toISOString()
            });
            console.log('register success, login now');
            const loginResponse = await handleLogin(email, password, setIsError, setRegisterStatus);
            console.log('login response:', loginResponse);
            if (loginResponse.status === 200 || loginResponse.access) {
              setIsLoading(true);
              setIsAuthenticated(true);
              navigate("/my_linko");
              setPersistentMessage('Welcome to Linko!');
            } else {
              window.alert('Login failed, please try again.');
            }
          } else {
            window.alert('Register failed, please try again.');
          }
        } catch (error) {
          if (error.response?.data?.email?.[0] === "user with this email already exists.") {
            if (error.response?.data?.is_google_user) {
              setIsError(false);
              setRegisterStatus('This email is already registered with Google. Please use Google Sign-In.');
              localStorage.setItem('successNotice', 'This email is already registered with Google. Please use Google Sign-In.');
              navigate('/login');
            } else {
              setIsError(false);
              setRegisterStatus('Account already exists.');
              localStorage.setItem('successNotice', 'Account already exists, please log in.');
              navigate('/login');
            }
          } else if (error.response?.data?.email?.[0] === "Email verification required") {
            setIsError(true);
            setRegisterStatus('Email verification is required. Please verify your email first.');
            setVerificationStep('email');
          } else {
            setIsError(true);
            console.error('Register failed:', error);
            setRegisterStatus('Registration failed. Please try again.');
          }
          console.error('Register failed:', error);
        } finally {
          setIsLoading(false);
          setIsSubmitting(false);
        }
      }
    }
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    await handleGoogleAuthSuccess(
      credentialResponse,
      setIsError,
      setRegisterStatus,
      setPendingGoogleCredential,
      setShowBetaCodeModal,
      setGoogleLoginState,
      setIsAuthenticated,
      setIsLoading,
      navigate,
      'signup'
    );
  };
  
  const handleBetaCodeSubmit = async () => {
    await handleBetaCodeSubmission(
      pendingGoogleCredential,
      betaCode,
      setIsError,
      setRegisterStatus,
      setGoogleLoginState,
      setBetaCode,
      setShowBetaCodeModal,
      setIsAuthenticated,
      navigate,
      'signup'
    );
  };

  // Render different form sections based on verification step
  const renderEmailStep = () => (
    <>
      <div className="login-form-group">
        <label className='login-form-label'>EMAIL</label>
        <input 
          className="login-form-control" 
          // placeholder="Enter email" 
          name='email'  
          type='text' 
          value={email}
          required
          autoComplete='email' 
          onChange={e => setEmail(e.target.value)}/>
      </div>
      <div className="login-form-footer">
        <p className={`login-status ${isError ? 'error-notice' : 'success-notice'}`}>{registerStatus}</p>
        <div className="login-form-footer-right">
          <div className="login-form-link">
            <p>Already have an account?  <Link to="/login">Log in</Link></p>
          </div>
          <button 
            type="button" 
            className={`linko-button linko-button--primary ${isVerifying || !email || !validateEmail(email) ? "linko-button--primary--disabled" : ""}`} 
            onClick={handleVerifyEmail}
            disabled={isVerifying || !email || !validateEmail(email)}
            style={{backgroundColor: email && validateEmail(email) ? "var(--secondary-color)" : "var(--grey-background)"}}
          >
            {isVerifying ? "Sending..." : "Verify Email"}
          </button>
        </div>
      </div>
    </>
  );
  
  const renderVerificationStep = () => (
    <>
      <div className="login-form-group">
        <label className='login-form-label'>Email</label>
        <input 
          className="login-form-control" 
          value={email}
          readOnly
          disabled
        />
      </div>
      <div className="login-form-group">
        <label className='login-form-label'>Verification Code</label>
        <input 
          className="login-form-control" 
          placeholder="Enter 6-digit code" 
          name='verification_code'  
          type='text' 
          value={verificationCode}
          required
          maxLength={6}
          onChange={e => setVerificationCode(e.target.value.replace(/[^0-9]/g, ''))}/>
      </div>
      <div className="login-form-footer">
        <p className={`login-status ${isError ? 'error-notice' : 'success-notice'}`}>{registerStatus}</p>
        <div className="login-form-footer-right">
          <button 
            type="button" 
            className="linko-button linko-button--tertiary" 
            onClick={() => {
              setVerificationStep('email');
              setVerificationCode('');
            }}>
            Back
          </button>
          <button 
            type="button" 
            className="linko-button linko-button--primary" 
            onClick={handleVerifyCode}
            disabled={isVerifying || verificationCode.length !== 6}
            style={{backgroundColor: verificationCode.length === 6 ? "var(--secondary-color)" : "var(--grey-background)"}}>
            {isVerifying ? "Verifying..." : "Continue"}
          </button>
        </div>
      </div>
    </>
  );
  
  const renderDetailsStep = () => (
    <>
      <div className='user-name-enter'>
        <div className="login-form-group">
          <label className='login-form-label'>First Name</label>
          <input 
            className="login-form-control" 
            placeholder="Enter first name" 
            name='first_name'  
            type='text' 
            value={firstName}
            required 
            onChange={e => setFirstName(e.target.value)}/>
        </div>
        <div className="login-form-group">
          <label className='login-form-label'>Last Name</label>
          <input 
            className="login-form-control" 
            placeholder="Enter last name" 
            name='last_name'  
            type='text' 
            value={lastName}
            onChange={e => setLastName(e.target.value)}/>
        </div>
      </div>
      <div className="login-form-group">
        <label className='login-form-label'>Email</label>
        <input 
          className="login-form-control" 
          value={email}
          readOnly
          disabled
        />
      </div>
      <div className="login-form-group">
        <label className='login-form-label'>Password</label>
        <div className='password-input'>
          <input 
              name='password' 
              type={showPassword ? "text" : "password"}     
              className="login-form-control password-input"
              placeholder="Enter password"
              value={password}
              autoComplete='current-password'
              required
              onFocus={() => setIsPasswordInputFocused(true)}
              onChange={e => setPassword(e.target.value)}
              style={{paddingRight: '50px'}} // Make room for the icon
          />
          <div 
              onClick={togglePasswordVisibility}
              className='password-visibility-icon'
          >
              {showPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />}
          </div>
        </div>
        <div className='password-requirement'>
          <p className={
              !isPasswordInputFocused ? '' :
              requirements.length ? 'requirement-met' : 'requirement-not-met'
          }>
          * must contain at least 9 characters. 
          </p>    
          <p className={
              !isPasswordInputFocused ? '' :
              requirements.lettersAndNumbers ? 'requirement-met' : 'requirement-not-met'
          }>
          * must contain both letters and numbers.
          </p>
        </div>
      </div>
      <div className="login-form-group">
        <label className='login-form-label'>Invitation Code</label>
        <input 
          className="login-form-control" 
          placeholder="Enter invitation code" 
          name='beta_code'  
          type='text' 
          value={betaCode}
          onChange={e => setBetaCode(e.target.value)}/>
      </div>
      <div className="login-form-footer">
        <p className={`login-status ${isError ? 'error-notice' : 'success-notice'}`}>{registerStatus}</p>
        <div className="login-form-footer-right">
          <button 
            type="button" 
            className="linko-button linko-button--tertiary" 
            onClick={() => {
              setVerificationStep('verification');
            }}>
            Back
          </button>
          <button 
            type="submit" 
            className="linko-button linko-button--primary" 
            disabled={isSubmitting || !validRegister}
          >
            {isSubmitting ? "Registering..." : "Register"}
          </button>
        </div>
      </div>
    </>
  );

  return (
    <div className='login'>
      <div className='login-form-container'>
        <form onSubmit={verificationStep === 'details' ? handleSubmit : (e) => e.preventDefault()} className='login-form'>
          <div className='login-form-content'>
            <h2 className='welcome-back'>Welcome to Linko!</h2>
            {verificationStep === 'email' && renderEmailStep()}
            {verificationStep === 'verification' && renderVerificationStep()}
            {verificationStep === 'details' && renderDetailsStep()}
          </div>
          {verificationStep === 'email' && (
            <div className="google-login-container">
              <GoogleLogin
                text="signup_with"
                onSuccess={handleGoogleSuccess}
                onError={() => {
                  setIsError(true);
                  setRegisterStatus('Google signup failed');
                }}
              />
            </div>
          )}
        </form>
      </div>
      
      {showBetaCodeModal && (
        <BetaCodeModal
          betaCode={betaCode}
          setBetaCode={setBetaCode}
          googleLoginState={googleLoginState}
          isLoading={googleLoginState.isLoading}
          onCancel={() => {
            setShowBetaCodeModal(false);
            setPendingGoogleCredential(null);
            setBetaCode('');
            setGoogleLoginState({ isLoading: false, error: null });
          }}
          onSubmit={() => {
            setGoogleLoginState({ isLoading: true, error: null });
            handleBetaCodeSubmit();
          }}
        />
      )}
    </div>
  );
};

export default Register;

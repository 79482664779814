import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { forgetPassword } from '@linko/shared_utils';

function ForgetPassword () {
  const [email, setEmail] = useState('');
  const [requestStatus, setRequestStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await forgetPassword(email);
      if (response.status === 200) {
        setRequestStatus('Link sent. Please check your email.');
      } else {
        setRequestStatus('Please enter a valid email.');
      } 
    } catch (error) {
      setRequestStatus('Please enter a valid email.');
    }
  };

  return (
    <div className="login">
      <div className="login-form-container">
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="login-form-content">
            <h3 className="welcome-back">Reset Password</h3>
            <div className="login-form-group">
              <label className="login-form-label">EMAIL</label>
              <input 
                name='email' 
                type="email"     
                className="login-form-control"
                value={email}
                required
                onChange={e => setEmail(e.target.value)}/>
                <div className='password-requirement' style={{paddingLeft: '5px'}}>
                    <p>
                      A link will be sent to your email to reset your password.
                    </p>
                </div>    
            </div>
            <div className="login-form-footer">
              <p className='login-status success-notice'>{requestStatus}</p>
              <div className="login-form-footer-right">
                <div className="login-form-link">
                  <p>Back to <Link to="/login">Login</Link></p>
                </div>
                <button type="submit" className="linko-button linko-button--primary">
                  Submit
                </button>
              </div>
            </div>
          </div>
       </form>
      </div>
     </div>
  );
}

export default ForgetPassword;
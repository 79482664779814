import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AddToLinkoModal from "../AddModals/AddToLinkoModal";
// Icons
import { RiBook2Line } from "react-icons/ri";
import { PiGlobe } from "react-icons/pi";

const AddToLinko = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const openModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };
    const closeModal = () => setIsModalOpen(false);

    return (
        <span className="add-to-linko">
            <button 
                className='linko-button linko-button--secondary'
                onClick={() => openModal("Book")}
                style={{ marginRight: '15px' }}
            >
                <RiBook2Line size={18}/>
                <span className='button-text hide-small'>Add Book</span>
            </button>
            <button 
                className='linko-button linko-button--secondary'
                onClick={() => openModal("Link")}
            >
                <PiGlobe size={18}/>
                <span className='button-text hide-small'>Add URL</span>
            </button>
            {isModalOpen && (
                <AddToLinkoModal content={modalContent} onClose={closeModal} />
            )}
        </span>
    );
};

export default AddToLinko;

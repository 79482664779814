import React, { useState, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; 
import { TbProgressCheck, TbCircleCheck, TbProgress, TbNotes } from "react-icons/tb";
import { putMyResource } from '@linko/shared_utils';
import { HomePageContext } from '../../Context/HomePageContext';

const ResourceProgress = ({
    id, 
    learnHistory, 
    onUpdate
}) => {
    const { updateSingleResourceInLibrary } = useContext(HomePageContext);
    if (!learnHistory || !learnHistory.resource) {
        return null;
    }
    const {
        note_count,
        progress,
        total_length,
    } = learnHistory;
    
    const [setProgress, setSetProgress] = useState(progress);
    const [setTotalLength, setSetTotalLength] = useState(total_length);
    const progressPortion = Math.floor((setProgress / setTotalLength) * 100);
    const location = useLocation();
    const shareView = location.pathname.startsWith('/profile/');
    const [isDragging, setIsDragging] = useState(false);
    const progressBarRef = useRef(null);
    const [showProgressBar, setShowProgressBar] = useState(false);

    const handleSetProgress = async (setProgress, setTotalLength) => {
        event.preventDefault();
        if (setProgress > setTotalLength || setProgress < 0 || setTotalLength < 0 || setTotalLength === 0 || isNaN(setProgress) || isNaN(setTotalLength)) {
            window.alert("Please enter a valid progress value.");
            return;
        }
        try {
            await putMyResource(id, setProgress, setTotalLength, null, null);
            if (onUpdate) {
                const updatedResourceDetails = {
                    ...learnHistory, 
                    progress: setProgress, 
                    total_length: setTotalLength
                };
                onUpdate(updatedResourceDetails); 
            }
            updateSingleResourceInLibrary(id);
            setShowProgressBar(false);
        } catch (error) {
            setSetProgress(progress);
            setSetTotalLength(total_length);
        }
    }
    
    const calculateProgressFromMousePosition = (clientX) => {
        const rect = progressBarRef.current.getBoundingClientRect();
        const relativeX = Math.max(0, Math.min(clientX - rect.left, rect.width));
        const percentage = (relativeX / rect.width) * 100;
        const newProgress = Math.round((percentage / 100) * setTotalLength);
        return Math.min(newProgress, setTotalLength);
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        const handleDrag = (e) => {
            const newProgress = calculateProgressFromMousePosition(e.clientX);
            setSetProgress(newProgress);
        };

        const handleDragEnd = async (e) => {
            document.removeEventListener('mousemove', handleDrag);
            document.removeEventListener('mouseup', handleDragEnd);
            setIsDragging(false);
            
            const newProgress = calculateProgressFromMousePosition(e.clientX);
            await handleSetProgress(newProgress, setTotalLength);
        };

        setIsDragging(true);
        document.addEventListener('mousemove', handleDrag);
        document.addEventListener('mouseup', handleDragEnd);
    };

    const handleProgressBarClick = async (e) => {
        if (isDragging) return;
        
        const newProgress = calculateProgressFromMousePosition(e.clientX);
        setSetProgress(newProgress);
        await handleSetProgress(newProgress, setTotalLength);
    };


    if (shareView) {
        return ;
    }

    const progressBarContent = (
        <div className="progress-bar" ref={progressBarRef} onClick={handleProgressBarClick} style={{ cursor: 'pointer' }}>
            <div
                className="progress-bar-fill"
                style={{ width: `${progressPortion}%` }}
            >
                <div 
                    className="progress-drag-dot"
                    onMouseDown={handleMouseDown}
                    style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
                />
            </div>
        </div>
    );

    return (
        <div className="resource-progress">
            <div style={{ display:'flex', alignItems:'center', columnGap:'3px'}} >
                <TbNotes size={14} color='var(--tertiary-font-color)' />
                <p className="note-count">
                    {note_count} note
                    {note_count !== 0 && note_count !== 1
                        ? "s"
                        : ""}
                </p>
            </div>

                <Tippy 
                    content={progressBarContent}
                    placement="bottom"
                    arrow={true}
                    theme='light-border'
                    interactive={true}
                    visible={showProgressBar}
                    onClickOutside={() => setShowProgressBar(false)}
                >
                    <div 
                        className={`progress-portion-wrapper`}
                        onClick={() => setShowProgressBar(!showProgressBar)}
                        data-testid="resourceProgress"
                    >
                        <div className="progress-portion">
                            {progressPortion === 0 ?
                                <TbProgress size={14} color='var(--tertiary-font-color)' />
                            : progressPortion === 100 ?
                                <TbCircleCheck size={14} color='var(--tertiary-font-color)' />
                            :
                                <TbProgressCheck size={14} color='var(--tertiary-font-color)' />
                            }
                            {progressPortion}%
                        </div>
                    </div>
                </Tippy>
        </div>
    );
};

export default ResourceProgress;

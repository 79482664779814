import React, { useState, useEffect, useContext } from 'react';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { MdOutlineLibraryAdd } from 'react-icons/md';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import ResourceProgress from './ResourceProgress';
import ResourceThumbnailPlaceholder from '../AddModals/ResourceThumbnailPlaceholder';
import ResourceTypeIcon from './ResourceTypeIcon';
import { cleanAuthorName, convertResourceType } from '@linko/shared_utils';
import { FaUnlink } from 'react-icons/fa';
const SlimResourceCard = ({ 
    id, 
    resource,
    learnHistory,
    onResourceClick, 
    onAddToLibrary, 
    onUnlink = null
}) => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [imageError, setImageError] = useState(false);
  const { safeNavigate } = useContext(NavigationPromptContext);

  useEffect(() => {
    const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    setIsTouchDevice(isTouch);
  }, []);

  // Calculate progress percentage
  const getProgressPercentage = () => {
    if (!learnHistory) return 0;
    const progress = learnHistory.progress || 0;
    const totalLength = learnHistory.total_length || 100;
    return Math.floor((progress / totalLength) * 100);
  };

  const progressPercentage = getProgressPercentage();

  const handleResourceClick = () => {
    if (onResourceClick) {
      onResourceClick();
    } else {
      safeNavigate(`/r/${id}`);
    }
  };

  return (
    <div className='slim-resource-card' onClick={handleResourceClick}>
      <div className='slim-resource-card-content'>
        {resource?.image_link && !imageError ? 
          <div className='slim-resource-thumbnail'>
            <img 
              src={resource.image_link} 
              alt={resource.title} 
              onError={() => setImageError(true)}
            />
          </div>
        : <ResourceThumbnailPlaceholder type={resource.type} width={"60px"} height={"85px"} />
        }
        
        <div className='slim-resource-details'>
          <div className='slim-resource-header'>
            <p className='slim-resource-card-title'>
              <span className="inline-type-icon">
                <ResourceTypeIcon type={resource.type} size={16} color="var(--tertiary-font-color)"/>
              </span>
              {resource.title}
            </p>
            <p className='slim-resource-card-author'>
              {`${resource.author ? cleanAuthorName(resource.author) : 'Unknown Author'}`}
            </p>
          </div>
          
          <div className='slim-resource-footer'>
            <div className='slim-resource-info-row'>
              
              {/* {learnHistory && (
                <div className='slim-resource-stats'>
                  <span className='notes-count'>{learnHistory.note_count || 0} notes</span>
                  <span className= 'progress-indicator'>{progressPercentage}%</span>
                </div>
              )} */}
              {learnHistory && (
                <div onClick={(e) => e.stopPropagation()}>
                  <ResourceProgress 
                    id={resource.id}
                    resource={resource}
                    learnHistory={learnHistory}
                    onUpdate={() => {}}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      
      {onAddToLibrary && (
        <Tippy 
          content={'Add to my library'}
          placement="left"
          arrow={true}
          theme='light-border'
          touch={false}
          disabled={isTouchDevice}
        >
          <div 
            className='add-resource-icon' 
            onClick={(e) => {
              e.stopPropagation(); 
              onAddToLibrary();
            }}  
          >
            <MdOutlineLibraryAdd size={22} />
          </div>
        </Tippy>
      )}
      {onUnlink !== null && (
        <Tippy 
          content={'Unlink'}
          placement="left"
        >
          <div 
            className='unlink-resource-icon' 
            onClick={(e) => {
              e.stopPropagation(); 
              onUnlink();
            }}  
          >
            <FaUnlink size={16}/>
            <span>Unlink</span>
          </div>
        </Tippy>
      )}
    </div>
  );
};

export default SlimResourceCard;

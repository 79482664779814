import { handleGoogleLogin } from '@linko/shared_utils';
import analytics, { EVENT_TYPES } from "../services/analytics.js";

/**
 * 处理Google登录/注册成功的通用函数
 * @param {Object} credentialResponse - Google认证响应
 * @param {Function} setIsError - 设置错误状态的函数
 * @param {Function} setStatusMessage - 设置状态消息的函数
 * @param {Function} setPendingGoogleCredential - 设置待处理Google凭证的函数
 * @param {Function} setShowBetaCodeModal - 设置是否显示Beta码模态框的函数
 * @param {Function} setGoogleLoginState - 设置Google登录状态的函数
 * @param {Function} setIsAuthenticated - 设置认证状态的函数
 * @param {Function} setIsLoading - 设置加载状态的函数
 * @param {Function} navigate - 导航函数
 * @param {string} authType - 认证类型，'login'或'signup'
 */
export const handleGoogleAuthSuccess = async (
  credentialResponse,
  setIsError,
  setStatusMessage,
  setPendingGoogleCredential,
  setShowBetaCodeModal,
  setGoogleLoginState,
  setIsAuthenticated,
  setIsLoading,
  navigate,
  authType = 'login'
) => {
  setGoogleLoginState({ isLoading: true, error: null });
  try {
    const result = await handleGoogleLogin(
      {
        ...credentialResponse,
        betaCode: null // 初始没有beta码
      },
      'web',
      setIsError,
      setStatusMessage,
      (credential) => {
        setGoogleLoginState({
          isLoading: false,
          requiresBetaCode: true
        });
        setPendingGoogleCredential(credential);
        setShowBetaCodeModal(true);
      }
    );
    
    if (result?.requires_beta_code) {
      setGoogleLoginState({
        isLoading: false,
        error: 'Beta code required',
        requiresBetaCode: true
      });
      setPendingGoogleCredential(credentialResponse);
      setShowBetaCodeModal(true);
    } else if (result?.access && result?.refresh) {
      // 跟踪成功的Google登录/注册
      analytics.trackEvent(authType === 'login' ? EVENT_TYPES.LOGIN : EVENT_TYPES.SIGNUP, {
        method: 'google',
        status: 'success',
        timestamp: new Date().toISOString()
      });
      
      await localStorage.setItem('access_token', result.access);
      await localStorage.setItem('refresh_token', result.refresh);
      setIsLoading && setIsLoading(true);
      setIsAuthenticated(true);
      navigate('/my_linko');
    }
  } catch (error) {
    // 跟踪失败的Google登录/注册
    analytics.trackEvent(EVENT_TYPES.ERROR, {
      action: authType,
      method: 'google',
      status: 'error',
      errorMessage: error.response?.data?.message || `Google ${authType} failed`,
      timestamp: new Date().toISOString()
    });
    
    setGoogleLoginState({
      isLoading: false,
      error: error.response?.data?.message || `Google ${authType} failed`
    });
    setIsError(true);
    setStatusMessage(error.response?.data?.message || `Google ${authType} failed`);
  }
};

/**
 * 处理Beta码提交的通用函数
 * @param {Object} pendingGoogleCredential - 待处理的Google凭证
 * @param {string} betaCode - Beta码
 * @param {Function} setIsError - 设置错误状态的函数
 * @param {Function} setStatusMessage - 设置状态消息的函数
 * @param {Function} setGoogleLoginState - 设置Google登录状态的函数
 * @param {Function} setBetaCode - 设置Beta码的函数
 * @param {Function} setShowBetaCodeModal - 设置是否显示Beta码模态框的函数
 * @param {Function} setIsAuthenticated - 设置认证状态的函数
 * @param {Function} navigate - 导航函数
 * @param {string} authType - 认证类型，'login'或'signup'
 */
export const handleBetaCodeSubmission = async (
  pendingGoogleCredential,
  betaCode,
  setIsError,
  setStatusMessage,
  setGoogleLoginState,
  setBetaCode,
  setShowBetaCodeModal,
  setIsAuthenticated,
  navigate,
  authType = 'login'
) => {
  setGoogleLoginState({ isLoading: true, error: null });
  try {
    const result = await handleGoogleLogin(
      {
        ...pendingGoogleCredential,
        betaCode
      },
      'web',
      setIsError,
      setStatusMessage,
      null // 重新提交时不需要beta码回调
    );
    
    if (result?.access && result?.refresh) {
      // 跟踪使用beta码成功的Google登录/注册
      analytics.trackEvent(authType === 'login' ? EVENT_TYPES.LOGIN : EVENT_TYPES.SIGNUP, {
        method: `google_with_beta_code`,
        status: 'success',
        timestamp: new Date().toISOString()
      });
      
      await localStorage.setItem('access_token', result.access);
      await localStorage.setItem('refresh_token', result.refresh);
      setIsAuthenticated(true);
      setShowBetaCodeModal(false);
      navigate('/my_linko');
    }
  } catch (error) {
    // 跟踪beta码验证错误
    analytics.trackEvent(EVENT_TYPES.ERROR, {
      action: 'beta_code_verification',
      method: 'google',
      status: 'error',
      errorMessage: error.response?.data?.message || 'Beta code verification failed',
      timestamp: new Date().toISOString()
    });
    
    setGoogleLoginState({
      isLoading: false,
      error: error.response?.data?.message || 'Beta code verification failed'
    });
  } finally {
    setBetaCode('');
  }
}; 
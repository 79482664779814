import axios from '../Middleware/axios';

export const register = async (firstName, lastName, email, password) => {
    try {
        const response = await axios.post('/api/auth/register/', {
            first_name: firstName,
            last_name: lastName,
            email: email.toLowerCase(),
            password: password,
        });
        return response;
    } catch (error) {
        console.error('Registration error:', error);
        throw error;
    }
}

export const activateAccount = async (uidb64, token) => {
    try {
        const response = await axios.get(`/api/auth/activate/${uidb64}/${token}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        return response;
    } catch (error) {
        console.error('Account activation error:', error);
        throw error;
    }
}

// Send verification code to email
export const verifyEmail = async (email) => {
    try {
        const response = await axios.post('/api/auth/verify_email/', {
            email: email.toLowerCase(),
        });
        return response;
    } catch (error) {
        console.error('Email verification error:', error);
        throw error;
    }
}

// Verify the 6-digit code
export const checkVerificationCode = async (email, code) => {
    try {
        const response = await axios.post('/api/auth/check_verification_code/', {
            email: email.toLowerCase(),
            code: code,
        });
        return response;
    } catch (error) {
        console.error('Verification code check error:', error);
        throw error;
    }
}
import React from 'react';
import Logo from '../static/Logo/Linko_Logo_LG_WB.png';

const LinkoLogo = ({ onClick }) => {
  return (
    <div id='logo' onClick={onClick ? onClick : () => {}}>
      <img src={Logo} alt="Linko Logo" />
      <h1>Linko</h1>
    </div>
  );
};

export default LinkoLogo; 